import { memo, useEffect, useRef, useState } from 'react';

import '../../style/dialog-form.css';
import { Neutral, Positive } from '../Core/Button';
import Dialog from '../Dialog';
import { DropdownField, TextField } from '../Core';
import produce from 'immer';

import styles from './styles.module.scss';
import { LinkIcon, TrashIcon } from '../Icons';
import Label from '../Dialog/Label';
import { FONT_WEIGHT_OPTIONS } from '../../utils/themes';
import { ALLOWED_FONT_TYPES } from '../Core/FontPicker';

function CustomFontUploadModal({
  show,
  hideModal,
  fontFiles = [],
  setData = () => {},
  addFiles = () => {}
}: any) {
  const fileInput = useRef<any>(null);
  const [fontFamily, setFontFamily] = useState('');
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    setFileData(
      // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
      fontFiles.map((file, i) => ({
        file,
        weight: (Math.min(i + 1, 9) * 100).toString(),
        style: 'normal'
      }))
    );
    const name = fontFiles[0]?.name ?? '';
    setFontFamily(name.split('.')[0]);
  }, [fontFiles]);

  const handleChange = (key: any, index: any) => (event: any) => {
    const newData = produce(fileData, (draft) => {
      // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
      draft[index][key] = event.target.value;
    });
    setFileData(newData);
  };

  return (
    <Dialog
      isOpen={show}
      title='Upload Font Family'
      onClose={hideModal}
      size='sm'
    >
      <div className={styles.fontUploadSection}>
        Verify your font is properly licensed and you have permission to use it
        in your form before uploading.
      </div>
      <div className={styles.fontUploadSection}>
        <Label className={styles.label}>Font Family</Label>
        <TextField
          placeholder='Arial'
          value={fontFamily}
          onComplete={(newFamily: any) => setFontFamily(newFamily)}
          className={styles.fontFamilyInput}
        />
      </div>
      {fileData.map((data, i) => (
        <div key={(data as any).file.name} className={styles.fontUploadSection}>
          <div className={styles.header}>
            <Label className={styles.label}>Upload {i + 1}</Label>
            <div
              className={styles.delete}
              onClick={() => {
                const newData = produce(fileData, (draft) => {
                  draft.splice(i, 1);
                });
                setFileData(newData);
              }}
            >
              <TrashIcon width={16} height={16} />
            </div>
          </div>
          <div className={styles.fileNameSection}>
            <div className={styles.icon}>
              <LinkIcon width={22} height={22} color='black' />
            </div>
            {(data as any).file.name}
          </div>
          <div className={styles.fontStylesSection}>
            <div className={styles.styleSection}>
              <div className={styles.styleLabel}>Weight</div>
              <DropdownField
                selected={(data as any).weight}
                onChange={handleChange('weight', i)}
                options={FONT_WEIGHT_OPTIONS.map((option) => ({
                  value: option.toString(),
                  display: option.toString()
                }))}
              />
            </div>
            <div className={styles.styleSection}>
              <div className={styles.styleLabel}>Style</div>
              <DropdownField
                selected={(data as any).style}
                onChange={handleChange('style', i)}
                options={[
                  { value: 'normal', display: 'normal' },
                  { value: 'italic', display: 'italic' }
                ]}
              />
            </div>
          </div>
        </div>
      ))}
      <div
        onClick={() => fileInput.current.click()}
        className={styles.fontUploadButton}
      >
        Add More Fonts
      </div>
      <input
        ref={fileInput}
        type='file'
        onChange={addFiles}
        required={false}
        accept={`${ALLOWED_FONT_TYPES.join(',')},.zip`}
        style={{
          position: 'absolute',
          height: 0,
          width: 0,
          bottom: 0,
          opacity: 0
        }}
        multiple
      />
      <div className='dialog-form-action text-center'>
        <Neutral onClick={hideModal}>Cancel</Neutral>
        <Positive
          onClick={() => {
            setData(fontFamily, fileData);
            hideModal();
          }}
        >
          {`Upload ${fileData.length}`}
        </Positive>
      </div>
    </Dialog>
  );
}

export default memo(CustomFontUploadModal);
