export default function ChevronDownIcon({
  width = 24,
  height = 24,
  fill = 'none',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      {...props}
    >
      <path
        d='M4 9.99984L16 22.1099L28 9.99985'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
