import { InlineTooltip, YesNoSwitch } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';

const UserTrackingSection = ({ title, formData, handleValueChange }: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Remember User
            <InlineTooltip
              text="If a user leaves your form and comes back, they are part of the same submission. If you're embedding multiple forms, make sure this setting is consistent."
              inline
            />
          </p>
          <YesNoSwitch
            id='remember-user'
            checked={formData.track_users}
            onCheckedChange={(value) => handleValueChange('track_users')(value)}
          />
        </label>
      </div>
      {formData.track_users && (
        <>
          <div className='field-group'>
            <label>
              <p className='form-label'>
                Allow Edits
                <InlineTooltip
                  text='Allow users to edit their submitted form after they complete it.'
                  inline
                />
              </p>
              <YesNoSwitch
                id='allow-edits'
                checked={formData.allow_edits ?? true}
                onCheckedChange={(value) =>
                  handleValueChange('allow_edits')(value)
                }
              />
            </label>
          </div>
          {(formData.allow_edits ?? true) && (
            <>
              <div className='field-group'>
                <label>
                  <p className='form-label'>
                    Remember User Entries
                    <InlineTooltip
                      text='If a user leaves your form and comes back, their previously filled-in data will be populated.'
                      inline
                    />
                  </p>
                  <YesNoSwitch
                    id='remember-form-data'
                    checked={formData.save_user_data ?? false}
                    onCheckedChange={(value) =>
                      handleValueChange('save_user_data')(value)
                    }
                  />
                </label>
              </div>
              <div className='field-group'>
                <label>
                  <p className='form-label'>
                    Remember User Location
                    <InlineTooltip
                      text='If a user leaves your form and comes back, they automatically start from the step they left off of.'
                      inline
                    />
                  </p>
                  <YesNoSwitch
                    id='save-user-location'
                    checked={formData.save_user_location ?? false}
                    onCheckedChange={(value) =>
                      handleValueChange('save_user_location')(value)
                    }
                  />
                </label>
              </div>
            </>
          )}
        </>
      )}
    </SettingsContainer>
  );
};

export default UserTrackingSection;
