import '../../style/dialog-form.css';

import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownField, PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { useAppSelector } from '../../hooks';

function SegmentSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.SEGMENT,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization);

  // Local/draft integration settings
  const [apiKey, setApiKey] = useState(integration?.data.api_key ?? '');
  const [trackFields, setTrackFields] = useState(
    integration?.data.metadata.track_fields ?? false
  );
  const [identifyUser, setIdentifyUser] = useState(
    integration?.data.metadata.identify_user ?? false
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      api_key: apiKey,
      track_fields: trackFields,
      identify_user: identifyUser
    };
    return {
      isUpdate: integration?.data,
      metadata,
      apiKey
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SEGMENT]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Write Key*' />
          <TextField
            className='dialog-form-input'
            value={apiKey || ''}
            onChange={setApiKey}
            error={isPartial && (!apiKey || apiKey === '')}
          />
        </div>
        <div>
          <PropertyLabel label='Identify User in Segment' />
          <DropdownField
            className='dialog-form-input'
            selected={identifyUser.toString()}
            onChange={(event: any) =>
              setIdentifyUser(event.target.value === 'true')
            }
            options={[
              { value: 'false', display: 'No' },
              { value: 'true', display: 'Yes' }
            ]}
          />
        </div>
        {!org?.enterprise_features.hipaa && (
          <div>
            <PropertyLabel label='Track field entries' />
            <DropdownField
              selected={trackFields.toString()}
              onChange={(event: any) =>
                setTrackFields(event.target.value === 'true')
              }
              options={[
                { value: 'false', display: 'No' },
                { value: 'true', display: 'Yes' }
              ]}
            />
          </div>
        )}
      </form>
    </IntegrationsSidebar>
  );
}

export default SegmentSettingsSidebar;
