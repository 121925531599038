import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, OnOffSwitch, TextField } from '../../components/Core';
import { Button as ButtonComponent } from '../../components/Core/Button/button';
import { DeleteRuleConfirmModal } from '../../components/Modals';
import { LogicRule } from '../FormLogicPage/LogicRuleList';
import { EditIcon } from '../../components/Icons';
import { RuleBuilder } from './components/RuleBuilder';
import { ConfirmationModal } from './components/ConfirmationModal';
import { transformToCode } from './components/RuleBuilder/transform';
import RuleCodeEditor, { CODE_DOCS_URL } from './components/RuleCodeEditor';
import RuleFieldsPanel, {
  LogicTriggerEvent
} from './components/RuleFieldsPanel';
import styles from './styles.module.scss';
import useFieldKey from '../../utils/useFieldKey';
import { RuleDSLManager } from './components/RuleBuilder/context/RuleDSL';
import { getAllFieldsInDSL } from './components/RuleBuilder/transform/utils';
import { isEmptyDSL, getPrivateActions } from './components/RuleBuilder/utils';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../components/Core/Tabs';
import { cn } from '../../utils/cn';
import { useAppSelector } from '../../hooks';

export default function LogicRuleDetail({
  onRuleUpdate,
  onRuleDelete,
  onBack,
  onCodeEditorSwitch = null,
  rules,
  steps
}: any) {
  const { formId, ruleId } = useParams<{ formId?: string; ruleId: string }>();
  const getFieldKey = useFieldKey(!!formId);

  const codeEditorMaximized = useAppSelector(
    (s) => s.formBuilder.codeEditorMaximized
  );

  const [activeTab, setActiveTab] = useState('rule_builder');
  const [mode, setMode] = useState<'edit' | 'view'>('view');
  const [showRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showOnlyNoCodeModal, setShowOnlyNoCodeModal] =
    useState<boolean>(false);
  const [privateActions, setPrivateActions] = useState<string>('');
  const [rule, setRule] = useState<LogicRule | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    rules[ruleId] && setRule(rules[ruleId]);
  }, [ruleId]);

  // Initially set the active tab to the mode of the rule
  useEffect(() => {
    if (initialized) return; // Nothing to do

    if (rules[ruleId] && !initialized) {
      setInitialized(true);
      setActiveTab(rules[ruleId].mode || 'rule_builder');
    }
  }, [rules, ruleId, initialized]);

  // renaming the rule
  const [isRenaming, setIsRenaming] = useState(false);
  const nameEditRef = useRef<any>();
  useEffect(() => {
    if (isRenaming) setTimeout(() => nameEditRef.current.focus(), 100);
  }, [isRenaming]);

  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);

  const updateRule = (rule: LogicRule) => {
    // if changing the trigger event, then must also update the runtime order index as well
    if (rule.trigger_event !== rules[rule.id].trigger_event) {
      // The index is used for determining the run order for the rules that run off the same event
      rule.index =
        Object.values(rules)
          .filter((r: any) => r.trigger_event === rule.trigger_event)
          .reduce((acc: number, r: any) => Math.max(acc, r.index), 0) + 1;
    }
    setRule(rule);
    onRuleUpdate(rule);
  };

  const handleRuleRename = (newName: string) => {
    if (rule) {
      setIsRenaming(false);
      newName = newName.trim();

      if (!newName) return;
      const allKeys = Object.values(rules).map((rule: any) => rule.name);
      if (allKeys.includes(newName)) return;

      updateRule({ ...rule, name: newName });
    }
  };

  const handleCodeChange = ({
    text,
    valid
  }: {
    text: string;
    valid?: boolean;
  }) => {
    if (rule) {
      updateRule({
        ...rule,
        code: text,
        valid: valid ?? rule.valid
      });
    }
  };

  const handleRuleBuilderComplete = (payload: any) => {
    if (rule) {
      updateRule({
        ...rule,
        dsl: payload.dsl ?? rule.dsl,
        valid: payload.valid ?? rule.valid,
        code: payload.code ?? rule.code
      });
    }
  };

  const handleChangeMode = (mode: string) => {
    if (!rule) return; // Can't do anything

    if (mode === 'rule_builder') {
      const manager = new RuleDSLManager(rule.dsl as IRuleDSL);
      const fields = getAllFieldsInDSL(rule.dsl as IRuleDSL, true);

      // This is to ensure that each referenced field has a valid key referenced
      fields.forEach((field) => {
        manager.updateOperand(field.id, {
          ...field,
          meta: {
            ...field.meta,
            field_key: getFieldKey(field.value)
          }
        });
      });

      const dsl = manager.toJSON() as IRuleDSL;

      transformToCode(dsl as IRuleDSL).then((code) => {
        updateRule({
          ...rule,
          dsl,
          code,
          mode: 'rule_builder'
        });
      });
    } else if (mode === 'code_editor') {
      updateRule({
        ...rule,
        mode: 'code_editor'
      });
    }
  };

  const handleSetActiveTab = (tab: string) => {
    if (tab === 'rule_builder' && rule?.mode === 'code_editor') {
      setShowRevertModal(true);
    } else if (tab === 'code_editor' && mode === 'edit') {
      setShowCancelModal(true);
    } else if (tab === 'code_editor' && rule?.mode === 'rule_builder') {
      const _privateActions = getPrivateActions(rule?.dsl);
      if (_privateActions !== '') {
        setPrivateActions(_privateActions);
        setShowOnlyNoCodeModal(true);
      } else setActiveTab(tab);
    } else {
      setActiveTab(tab);
    }
  };

  if (!rule) return null;
  return (
    <>
      <ConfirmationModal
        show={showRevertModal}
        setShow={setShowRevertModal}
        title='Warning'
        message={
          isEmptyDSL(rule?.dsl)
            ? 'Using the Rule Builder will cause the code currently defined to be lost. Are you sure you want to continue?'
            : 'Using Rule Builder will revert all changes made in Advanced Logic. Are you sure you want to continue?'
        }
        onConfirm={() => {
          handleChangeMode('rule_builder');
          setActiveTab('rule_builder');
        }}
        onCancel={() => setShowRevertModal(false)}
      />
      <ConfirmationModal
        show={showCancelModal}
        setShow={setShowCancelModal}
        title='Warning'
        message='Any changes that have been made to the rule builder will be lost. Are you sure you want to continue without saving?'
        onConfirm={() => {
          setMode('view');
          if (onCodeEditorSwitch) onCodeEditorSwitch();
          setActiveTab('code_editor');
        }}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmationModal
        show={showOnlyNoCodeModal}
        setShow={setShowOnlyNoCodeModal}
        title='Warning'
        message={`The following actions are not supported in the code editor: ${privateActions}. Please remove them before switching.`}
        onCancel={() => setShowOnlyNoCodeModal(false)}
      />
      <div className={cn(styles.ruleContainer, 'flex-1')}>
        <div className={styles.headerContainer}>
          <div className={styles.ruleNameLeft}>
            <Button.Back className={styles.backButton} onClick={onBack} />
            <h1 className={styles.ruleHeader}>Logic Rule:</h1>
            {isRenaming ? (
              <TextField
                value={rule.name}
                onComplete={handleRuleRename}
                maxLength={128}
                ref={nameEditRef}
                className={styles.input}
              />
            ) : (
              <>
                <div
                  className={styles.ruleHeader}
                  onDoubleClick={() => setIsRenaming(true)}
                >
                  {rule.name}
                </div>
                <EditIcon
                  className={styles.ruleEditIcon}
                  width={16}
                  height={16}
                  color='#000000'
                  onClick={() => setIsRenaming(true)}
                />
              </>
            )}
          </div>
          <div className={styles.ruleActions}>
            <div className={styles.ruleStatus}>
              <span>Status</span>{' '}
              <OnOffSwitch
                checked={rule?.enabled}
                onCheckedChange={(val: boolean) =>
                  updateRule({ ...rule, enabled: val })
                }
                className={styles.ruleStatusSwitch}
              />
            </div>
            <ButtonComponent
              variant='destructive'
              onClick={() => setShowDeleteRuleModal(true)}
            >
              Delete Rule
            </ButtonComponent>
          </div>
        </div>
        <div className={styles.ruleContent}>
          {!codeEditorMaximized && (
            <RuleFieldsPanel
              steps={steps}
              rule={rule}
              setRule={updateRule}
              validation
              columns={2}
            />
          )}
          <Tabs
            value={activeTab}
            onValueChange={(tab: string | null) =>
              tab && handleSetActiveTab(tab as string)
            }
            className='flex flex-col flex-1'
          >
            <TabsList unstyled className='legacyRulesTabsList mt-[16px]'>
              <TabsTrigger
                unstyled
                className='legacyRulesTabsTrigger'
                value='rule_builder'
              >
                Rule Builder
              </TabsTrigger>
              <TabsTrigger
                unstyled
                className='legacyRulesTabsTrigger'
                value='code_editor'
              >
                Advanced Logic
              </TabsTrigger>
            </TabsList>
            <TabsContent
              unstyled
              className='legacyRulesTabsContent pb-[16px]'
              value='rule_builder'
            >
              <RuleBuilder
                rule={rule}
                mode={mode}
                onComplete={handleRuleBuilderComplete}
                setMode={setMode}
              />
            </TabsContent>
            <TabsContent
              unstyled
              className='legacyRulesTabsContent'
              value='code_editor'
            >
              <RuleCodeEditor
                onChange={(code) => handleCodeChange(code)}
                onChangeMode={(mode: any) => handleChangeMode(mode)}
                code={{
                  text: rule.code,
                  valid: rule.valid
                }}
                triggerEvent={rule.trigger_event as LogicTriggerEvent}
                readOnly={rule?.mode === 'rule_builder'}
                readOnlyText={
                  <>
                    Code Editor unlocks{' '}
                    <a href={CODE_DOCS_URL} target='_blank' rel='noreferrer'>
                      advanced functionality
                    </a>{' '}
                    for your rule. Click <b>Edit Code</b> to start.
                  </>
                }
              />
            </TabsContent>
          </Tabs>
        </div>
        <DeleteRuleConfirmModal
          show={showDeleteRuleModal}
          setShow={setShowDeleteRuleModal}
          rule={rule}
          deleteRule={(rule: LogicRule) => onRuleDelete(rule, true)}
        />
      </div>
    </>
  );
}
