import { ComponentProps, memo } from 'react';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../../../../redux/utils';
import { CheckIcon, PublishRocket } from '../../../Icons';
import classNames from 'classnames';
import { Button } from '../button';
import { Spinner } from '../../Spinner';
import { Tooltip } from '../../Tooltip/Tooltip';

interface PublishButtonProps extends ComponentProps<typeof Button> {
  status: string;
  draftStatus?: string;
  onPublish?: any;
  partial?: boolean;
  saveFlag?: boolean;
}
function PublishButton({
  status,
  disabled: notAllowed = false,
  onPublish = () => {},
  partial = false,
  saveFlag = false,
  draftStatus = DRAFT_STATUS.OPERATIONAL
}: PublishButtonProps) {
  let content;
  const needsRefresh = [
    DRAFT_STATUS.CONFLICT,
    DRAFT_STATUS.CONFLICT_IGNORE,
    DRAFT_STATUS.ERROR
  ].includes(draftStatus);
  if (needsRefresh) content = 'Refresh';
  else {
    if (status === PUBLISH_STATUS.FULFILLED) {
      content = (
        <>
          <CheckIcon
            className='w-4 h-4 mt-0.5'
            color={'var(--prim-100)'}
            stroke='1'
          />
          {saveFlag ? 'Saved' : 'Published'}
        </>
      );
    } else {
      let message = 'Publish';
      if (saveFlag) message = 'Save';
      else if (status === PUBLISH_STATUS.AUTO_SAVING) message = 'Saving';
      const spinner = <Spinner role='status' />;
      const saving =
        status === PUBLISH_STATUS.LOADING ||
        status === PUBLISH_STATUS.AUTO_SAVING;
      if (saveFlag) {
        content = saving ? spinner : message;
      } else {
        content = (
          <>
            {saving ? (
              spinner
            ) : (
              <PublishRocket className='fill-current w-7 h-7' />
            )}
            {message}
          </>
        );
      }
    }
  }

  const disconnected = DRAFT_STATUS.NOT_CONNECTED === draftStatus;
  const isDisabled =
    ([
      PUBLISH_STATUS.FULFILLED,
      PUBLISH_STATUS.AUTO_SAVING,
      PUBLISH_STATUS.LOADING
    ].includes(status) &&
      !needsRefresh) ||
    notAllowed ||
    disconnected;

  return (
    <Tooltip
      disabled={!notAllowed}
      content='Your account cannot publish forms.'
    >
      <Button
        className={classNames(
          'disabled:bg-primary px-3 w-[100px]',
          (isDisabled || disconnected) &&
            'bg-white disabled:bg-white !text-primary',
          (notAllowed || disconnected) && 'text-primary/50',
          partial && 'rounded-r-none'
        )}
        onClick={onPublish}
        disabled={isDisabled}
      >
        {content}
      </Button>
    </Tooltip>
  );
}

export default memo(PublishButton);
