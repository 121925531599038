import {
  CheckboxField,
  CollapsibleSection,
  NumberInput,
  PropertyLabel
} from '../../Core';
import { memo } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';

import { HideIfsSection } from './components';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { TYPE_PROGRESS_BAR } from '../../../utils/elements';
import LogicSection from '../../FormFieldEditors/DefaultEditor/LogicSection';
import Row from '../../Core/Layout/Row';
import Col from '../../Core/Layout/Col';

function ProgressBarPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const asset = getAsset(theme, TYPE_PROGRESS_BAR, element.source_asset);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function handleInheritedPropChange(propUpdate: any) {
    handlePropChange({ properties: propUpdate });
  }

  function labelData(...propList: string[]) {
    const newProps = objectPick(element.properties, propList);

    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([elementOperation({ propReset: propList })]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_PROGRESS_BAR,
                  asset,
                  newProps
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Progress' collapsible>
        <Row style={{ height: '44px' }}>
          <Col sm='6'>
            <CheckboxField
              checked={!!element.properties.progress}
              text={
                <PropertyLabel
                  label='Custom Progress'
                  {...labelData('progress')}
                />
              }
              onChange={(isChecked) =>
                handleInheritedPropChange({ progress: isChecked ? 50 : '' })
              }
            />
          </Col>
          {!!element.properties.progress && (
            <Col>
              <NumberInput
                required
                min={0}
                max={100}
                value={element.properties.progress}
                onComplete={({ value }: any) =>
                  handleInheritedPropChange({ progress: value })
                }
                units={['%']}
                triggerCleanUp
              />
            </Col>
          )}
        </Row>
        <Row style={{ height: '44px' }}>
          <Col sm='6'>
            <CheckboxField
              checked={!!element.properties.num_segments}
              text={
                <PropertyLabel
                  label='Show Segments'
                  {...labelData('num_segments')}
                />
              }
              onChange={(isChecked) =>
                handleInheritedPropChange({ num_segments: isChecked ? 4 : 0 })
              }
            />
          </Col>
          {!!element.properties.num_segments && (
            <Col>
              <NumberInput
                required
                min={1}
                max={100}
                value={element.properties.num_segments}
                onComplete={({ value }: any) =>
                  handleInheritedPropChange({ num_segments: value })
                }
                triggerCleanUp
              />
            </Col>
          )}
        </Row>
      </CollapsibleSection>

      {mode === 'builder' && (
        <>
          <LogicSection elementId={element.id} />
          <HideIfsSection
            element={element}
            hideIfs={element.hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}
    </>
  );
}

export default memo(ProgressBarPropertiesPanel);
