import useDraftForm from '../../../utils/useDraftForm';
import { DropdownField, InlineTooltip, PropertyLabel } from '../../Core';
import { StepSelectorWithModal } from '../../Modals';

const AuthorizedSteps = ({ integrationConfig, setFields }: any) => {
  const { steps } = useDraftForm();
  const stepList = Object.values(steps)
    .map((step: any) => ({ display: step.key, value: step.id }))
    .sort((a: any, b: any) => (a.display > b.display ? 1 : -1));

  const augmentedStepList = [{ display: 'None', value: '' }, ...stepList];

  return (
    <>
      <PropertyLabel label='Authentication required for...' />
      <StepSelectorWithModal
        steps={steps}
        stepList={stepList}
        selected={integrationConfig.auth_gate_steps}
        placeholder='Select...'
        setFields={setFields}
        style={{ marginBottom: '10px' }}
      />
      <div style={{ display: 'flex' }}>
        <PropertyLabel label='Login Redirect' />
        <InlineTooltip text='Users will be directed to this step after logging in.' />
      </div>
      <DropdownField
        title='Login Redirect'
        style={{ marginBottom: '10px' }}
        selected={integrationConfig.login_step}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setFields({ login_step: e.target.value })
        }
        options={augmentedStepList}
      />
      <div style={{ display: 'flex' }}>
        <PropertyLabel label='Logout Redirect' />
        <InlineTooltip text='Users will be directed to this step after logging out.' />
      </div>
      <DropdownField
        title='Logout Redirect'
        style={{ marginBottom: '10px' }}
        selected={integrationConfig.logout_step}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setFields({ logout_step: e.target.value })
        }
        options={augmentedStepList}
      />
    </>
  );
};

export default AuthorizedSteps;
