import styles from './styles.module.scss';

type RadioInputProps = {
  selected?: string;
  onChange: (value: string) => void;
  options: { value: string; display: any }[];
};

const RadioInput = ({
  selected,
  options,
  onChange = () => {}
}: RadioInputProps) => {
  return (
    <div className={styles.radioInput}>
      {options.map((option) => (
        <label key={option.value} className='flex items-center gap-4 h-8'>
          <input
            className={styles.radioCheck}
            type='radio'
            name={option.value}
            checked={selected === option.value}
            onChange={() => onChange(option.value)}
          />
          <span className={styles.radioInputLabel}>{option.display}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioInput;
