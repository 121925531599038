import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const TEMENOS_CREATE_LOAN: Template = {
  id: 'temenos_create_loan',
  display: 'Create Loan',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    customerId: { type: 'textfield', label: 'Customer ID' },
    productId: { type: 'textfield', label: 'Product ID' }
  },
  defaultState: {
    name: 'Temenos Create Loan',
    template: 'temenos_create_loan',
    url: 'https://api.temenos.com/api/holdings/loans/businessLoans',
    method: 'POST',
    headers: {
      Authorization: '',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ body: { customerIds: [], productId: '' } }),
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    const body = JSON.parse(state.body);
    return {
      name: state.name,
      customerId: body.body.customerIds[0] ?? '',
      productId: body.body.productId
    };
  },
  saveConfig: (state, configValues) => {
    const { name, customerId, productId } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      body: JSON.stringify({ body: { customerIds: [customerId], productId } })
    };

    return {
      ...state,
      ...newValues
    };
  }
};
