import { memo, useCallback, useState } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';

import Dialog from '../Dialog';
import { Positive } from '../Core/Button';
import useModalSubmissionLockout from '../../utils/useModalSubmissionLockout';
import useGetJwt from '../../utils/useGetJwt';
import classNames from 'classnames';
import useFeatheryRedux from '../../redux';
import { parseAndThrowError } from '../../redux/utils';
import { CheckboxField } from '../Core';
import { useParams } from 'react-router-dom';

function DownloadCSVModal({ entity, envName, show, close }: any) {
  const { formId } = useParams<{ formId: string }>();

  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [onlyCompleted, setOnlyCompleted] = useState<string>('');
  const getJwt = useGetJwt();

  const {
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const submit = useCallback(() => {
    const token = getJwt();
    const newEndDate = new Date(endDate.valueOf());
    // All data within the end date should be included
    newEndDate.setDate(newEndDate.getDate() + 1);
    const method = formId ? 'getPanelCSVData' : 'getAICSVData';
    const kwarg = formId ? 'panelId' : 'extractionId';
    return FeatheryAPI[method](token, {
      [kwarg]: entity.id,
      primary: envName === 'Production',
      startDate: startDate ? startDate.toISOString() : '',
      endDate: endDate ? newEndDate.toISOString() : '',
      onlyCompleted
    })
      .then(async (res) => {
        if (res.status === 200)
          addInfoToast("Your download will be emailed to you once it's ready.");
        else {
          const result = await res.json();
          parseAndThrowError(result, 'Your download could not be started');
        }
      })
      .catch((e) => {
        addErrorToast({ text: e.toString() });
      })
      .finally(() => close());
  }, [entity.id, envName, startDate, endDate, onlyCompleted]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={show} title='Filter Results' size='sm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <div className={styles.downloadCSVFilter}>
          Start Date (Optional){' '}
          <DatePicker
            selected={startDate}
            onSelect={(val: any) => setStartDate(val ?? '')}
            onChange={(val: any) => setStartDate(val ?? '')}
            className={styles.dateInput}
            maxDate={new Date()}
            isClearable
          />
        </div>
        <div className={styles.downloadCSVFilter}>
          End Date (Optional){' '}
          <DatePicker
            selected={endDate}
            onSelect={(val: any) => setEndDate(val ?? '')}
            onChange={(val: any) => setEndDate(val ?? '')}
            className={styles.dateInput}
            maxDate={new Date()}
            isClearable
          />
        </div>
        {formId && (
          <div className={classNames(styles.downloadCSVFilter, styles.end)}>
            <CheckboxField
              checked={onlyCompleted === 'true'}
              text='Completed submissions only'
              onChange={(isChecked) =>
                setOnlyCompleted(isChecked ? 'true' : '')
              }
            />
          </div>
        )}
        <div className='dialog-form-action text-right'>
          <Positive lockoutOverride={lockOutFlag}>Download CSV</Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(DownloadCSVModal);
