import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from '../types';
import IntegrationsSidebar from '../IntegrationsSidebar';
import StripeKeySettings from './StripeKeySettings';
import StripeFieldSettings from './StripeFieldSettings';
import StripePaymentFieldSettings from './StripePaymentFieldSettings';
import StripePaymentSettings from './StripePaymentSettings';
import useIntegrations from '../useIntegrations';
import { useParams } from 'react-router-dom';

function StripeSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.STRIPE,
    panelId: formId,
    includeInactive: true
  });

  const [isValid, setIsValid] = useState(false);
  const [showFieldErrors, setShowFieldErrors] = useState(false);

  // IMPORTANT - Separating out the stripe config state for performance reasons!
  // Details: In StripeKeySettings, the text fields have onChange handlers that do state update
  // calls on each keystroke.  The results in re-renders of everything tied into that state.  If the
  // fields in StripeFieldSettings are tied into that same state update/rerender, the performance
  // was terrible.  This is because the rerender in StripeFieldSettings with the FieldSelectorWithModals
  // on each field is expensive.  Using two sets of state and only combining onSubmit fixed this nicely.
  const DEFAULT_CONFIG = { metadata: {}, secret_metadata: {} };
  const [stripeFieldConfig, setStripeFieldConfig] = useState(
    integration?.data ?? DEFAULT_CONFIG
  );
  const [stripePaymentFieldsConfig, setStripePaymentFieldsConfig] = useState(
    integration?.data ?? DEFAULT_CONFIG
  );
  const [stripeKeyConfig, setStripeKeyConfig] = useState(
    integration?.data ?? DEFAULT_CONFIG
  );

  function onSubmitCustom(newIsActive: boolean) {
    // combine the configs
    const newStripeConfig = {
      metadata: {
        ...stripeFieldConfig.metadata,
        checkout_type:
          stripePaymentFieldsConfig.metadata.checkout_type || 'custom',
        customer_field_mappings: {
          ...stripeFieldConfig.metadata.customer_field_mappings
        },
        payment_field_mappings: {
          ...stripePaymentFieldsConfig.metadata.payment_field_mappings
        },
        test_client_key: stripeKeyConfig.metadata.test_client_key,
        prod_client_key: stripeKeyConfig.metadata.prod_client_key,
        // Never submit any product_price_cache data here
        live: { product_price_cache: {} },
        test: { product_price_cache: {} }
      },
      secret_metadata: {
        test_secret_key: stripeKeyConfig.secret_metadata.test_secret_key,
        prod_secret_key: stripeKeyConfig.secret_metadata.prod_secret_key
      }
    };

    if (newIsActive) {
      const invalid =
        newStripeConfig.metadata.customer_id_type === 'custom' &&
        !newStripeConfig.metadata.id_metadata_key;
      setShowFieldErrors(true);
      setIsValid(!invalid);
      if (invalid) return;
    }

    return {
      isUpdate: integration?.data,
      metadata: newStripeConfig.metadata,
      secretMetadata: newStripeConfig.secret_metadata
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.STRIPE]}
      isPartial={!isValid}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <StripeKeySettings
            stripeConfig={stripeKeyConfig}
            setStripeConfig={setStripeKeyConfig}
          />
          <StripePaymentSettings
            stripeConfig={stripePaymentFieldsConfig}
            setStripeConfig={setStripePaymentFieldsConfig}
          />
          <StripeFieldSettings
            stripeConfig={stripeFieldConfig}
            setStripeConfig={setStripeFieldConfig}
            showFieldErrors={showFieldErrors}
          />
          <StripePaymentFieldSettings
            stripeConfig={stripePaymentFieldsConfig}
            setStripeConfig={setStripePaymentFieldsConfig}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default StripeSettingsSidebar;
