import { memo, useMemo, useState } from 'react';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-tooltip';
import { AIExtractionEntryValue } from '../types';
import { useExtractionUpdater } from '../context';
import { cn } from '../../../utils/cn';
import EntryValueDisplay from './EntryValueDisplay';
import useField from '../../../utils/useField';
import classNames from 'classnames';
import styles from './styles.module.scss';

const TOOLTIP_DELAY = 750; // Milliseconds

type Props = React.PropsWithChildren<{
  entryValue: AIExtractionEntryValue;
  isHidden?: boolean;
}>;

const AIFieldPopover = ({ entryValue, isHidden, children }: Props) => {
  const setHoveredValueId = useExtractionUpdater((s) => s.setHoveredValueId);
  const [show, setShow] = useState(false);
  const getField = useField();

  const fieldName = useMemo(() => {
    return getField(entryValue.fieldId)?.key ?? 'Unknown Field';
  }, [entryValue.fieldId]);

  const relatedEntries = entryValue.relatedEntries
    ? Object.values(entryValue.relatedEntries)
    : [];

  const handleOpenChange = (open: boolean) => {
    if (open && !isHidden) {
      setShow(true);
    } else {
      setShow(false);
      setHoveredValueId(null);
    }
  };

  return (
    <Root
      open={show}
      onOpenChange={handleOpenChange}
      delayDuration={TOOLTIP_DELAY}
    >
      <Trigger asChild>
        <div className={styles.highlight}>{children}</div>
      </Trigger>
      <Portal>
        <Content
          sideOffset={5}
          className={cn(
            'z-[999999999] overflow-hidden',
            'animate-in fade-in-0 zoom-in-95',
            'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
            'data-[side=bottom]:slide-in-from-top-2',
            'data-[side=left]:slide-in-from-right-2',
            'data-[side=right]:slide-in-from-left-2',
            'data-[side=top]:slide-in-from-bottom-2'
          )}
        >
          <div
            className={cn('text-sm', styles.aiFieldPopover)}
            style={{ zIndex: 1000 }}
          >
            <div className={styles.header}>
              <div className={styles.title}>{fieldName}</div>
            </div>
            <div className={styles.body}>
              <div className={styles.section}>
                <div className={styles.label}>Value</div>
                <div className={styles.content}>
                  <EntryValueDisplay entry={entryValue} />
                </div>
              </div>
              {relatedEntries.length >= 1 && (
                <div className={styles.section}>
                  <div className={styles.label}>Paired Values</div>
                  <div className={classNames(styles.content, styles.list)}>
                    {relatedEntries.map((entry) => (
                      <div key={entry.id} className={styles.value}>
                        <div className={styles.label}>
                          {getField(entry.fieldId)?.key ?? 'Unknown Field'}
                        </div>
                        <div>
                          <EntryValueDisplay entry={entry} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Content>
      </Portal>
    </Root>
  );
};

export default memo(AIFieldPopover);
