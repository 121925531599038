import { PropsWithChildren } from 'react';
import { usePDFState, usePDFUpdater } from '../context';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = PropsWithChildren<any>;

const MAX_ZOOM = 1.6;
const MIN_ZOOM = 0.5;

const Zoomable = ({ children }: Props) => {
  const zoom = usePDFState((s) => s.zoom);
  const setZoom = usePDFUpdater((s) => s.setZoom);

  const handleResetZoom = () => {
    if (zoom !== 1) {
      setZoom(1);
    }
  };

  const handleUpdateZoom = (delta: number) => {
    if (zoom + delta <= MAX_ZOOM && zoom + delta >= MIN_ZOOM) {
      setZoom(zoom + delta);
    }
  };

  return (
    <div className={styles.zoomable}>
      <div
        className={classNames('background-controls', styles.zoomableContent)}
      >
        {children}
      </div>
      <div className={styles.zoomableControls}>
        <div
          onClick={handleResetZoom}
          className={classNames(styles.zoomableDisplay, {
            [styles.highlight]: zoom !== 1,
            [styles.clickable]: zoom !== 1
          })}
        >
          <span>{Math.round(zoom * 100)}%</span>
        </div>
        <div className={styles.zoomableButtons}>
          <button onClick={() => handleUpdateZoom(0.1)}>+</button>
          <button onClick={() => handleUpdateZoom(-0.1)}>-</button>
        </div>
      </div>
    </div>
  );
};

export default Zoomable;
