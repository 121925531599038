import { useMemo } from 'react';
import { AIExtractionEntryValue, AIExtractionRun } from '../../types';
import { splitEntryValues } from '../../utils';
import styles from '../styles.module.scss';
import DataLayerPage from './DataLayerPage';

type Props = {
  run: AIExtractionRun;
  pageCount: number;
};

const DataLayer = ({ run, pageCount }: Props) => {
  const pageArray = useMemo(() => new Array(pageCount).fill(0), [pageCount]);

  const entriesByPage = useMemo(() => {
    const singularEntries: AIExtractionEntryValue[] =
      run.questionGroups.flatMap((questionGroup) => {
        return questionGroup.entries.reduce((acc, entry) => {
          return [
            ...acc,
            ...splitEntryValues(
              entry,
              questionGroup.entries.filter(({ id }) => id !== entry.id)
            ).map((entryValue) => ({
              ...entryValue,
              questionGroupId: questionGroup.id
            }))
          ];
        }, [] as AIExtractionEntryValue[]);
      });

    // Filter for entries that are not an email
    const documentEntries = singularEntries.filter(
      (entry) => !entry.source || entry.source !== 'email'
    );

    return documentEntries.reduce((acc, entry) => {
      if (!acc[entry.pageNumber]) {
        acc[entry.pageNumber] = [entry];
      } else {
        acc[entry.pageNumber].push(entry);
      }

      return acc;
    }, {} as { [key: number]: AIExtractionEntryValue[] });
  }, [run]);

  return (
    <div className={styles.dataLayer}>
      {pageArray.map((_, index) => (
        <DataLayerPage
          key={`page_${index + 1}`}
          page={index + 1}
          entries={entriesByPage[index + 1] || []}
        />
      ))}
    </div>
  );
};

export default DataLayer;
