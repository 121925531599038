import { useMemo } from 'react';
import {
  AIEmailContent,
  AIExtractionEntryValue,
  AIExtractionRun
} from '../../types';
import { splitEntryValues } from '../../utils';
import { highlightWords } from './utils';
import styles from '../styles.module.scss';

// Ensure that the email HTML is not interactive
const processHTML = (html: string) => {
  const parser = new DOMParser();
  const el = parser.parseFromString(html, 'text/html');
  const body = el.body;

  const interactiveElements = body.querySelectorAll(
    'button, input, select, textarea, a'
  );

  interactiveElements.forEach((element) => {
    (element as any).style.cursor = 'default';

    if (element.tagName.toLowerCase() === 'a') {
      (element as any).href = '#';
      (element as any).style.pointerEvents = 'none'; // Optional: visually indicate it's disabled
      (element as any).style.color = 'gray'; // Optional: change color to indicate disabled state
    } else {
      (element as any).disabled = true;
    }
  });

  return body.innerHTML;
};

type Props = {
  run?: AIExtractionRun;
  email: AIEmailContent;
};

const Viewer = ({ run, email }: Props) => {
  const fullEmail = useMemo(() => {
    return `${email.subject}${email.parts.reduce((acc: string, part) => {
      if (part.mimeType === 'text/html') {
        return `${acc}\n\n${processHTML(part.body.trim())}`;
      }

      return `${acc}\n\n${part.body}`;
    }, '')}`;
  }, [email]);

  const singularEntries: AIExtractionEntryValue[] = useMemo(() => {
    if (!run) {
      return [];
    }

    const entryValues = run.questionGroups.flatMap((questionGroup) => {
      return questionGroup.entries.reduce((acc, entry) => {
        return [
          ...acc,
          ...splitEntryValues(
            entry,
            questionGroup.entries.filter(({ id }) => id !== entry.id)
          ).map((entryValue) => ({
            ...entryValue,
            questionGroupId: questionGroup.id
          }))
        ];
      }, [] as AIExtractionEntryValue[]);
    });

    return entryValues.filter((entry) => entry.source === 'email');
  }, [run]);

  if (!run) {
    return null;
  }

  return (
    <div className={styles.emailDocument}>
      <div className={styles.emailPage}>
        {highlightWords(fullEmail, singularEntries)}
      </div>
    </div>
  );
};

export default Viewer;
