import '../../style/dialog-form.css';

import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownMultiField, PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';

const trackEventOptions = [
  { label: 'Step is Loaded', value: 'FeatheryStepLoad' },
  { label: 'Step is Submitted', value: 'FeatheryStepSubmit' },
  { label: 'Form is Completed', value: 'FeatheryFormComplete' }
];
const DEFAULT_TRACK_EVENTS = ['FeatheryStepLoad', 'FeatheryStepSubmit'];

function RudderStackSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.RUDDERSTACK,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [trackEvents, setTrackEvents] = useState(
    integration?.data.metadata.track_events ?? DEFAULT_TRACK_EVENTS
  );
  const [writeKey, setWriteKey] = useState(
    integration?.data.metadata.write_key ?? ''
  );
  const [testWriteKey, setTestWriteKey] = useState(
    integration?.data.metadata.test_write_key ?? ''
  );

  const [dataPlaneUrl, setDataPlaneUrl] = useState(
    integration?.data.metadata.data_plane_url ?? ''
  );
  const [testDataPlaneUrl, setTestDataPlaneUrl] = useState(
    integration?.data.metadata.test_data_plane_url ?? ''
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !writeKey || !dataPlaneUrl;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      track_events: trackEvents,
      write_key: writeKey,
      test_write_key: testWriteKey,
      data_plane_url: dataPlaneUrl,
      test_data_plane_url: testDataPlaneUrl
    };
    return { isUpdate: integration?.data, metadata };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.RUDDERSTACK]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Events to Track' />
          <DropdownMultiField
            options={trackEventOptions}
            selected={trackEvents}
            onChange={(items: { value: string }[]) =>
              setTrackEvents(items.map((item) => item.value))
            }
          />
        </div>
        <div>
          <PropertyLabel label='Write Key' />
          <TextField
            className='dialog-form-input'
            value={writeKey || ''}
            onChange={setWriteKey}
            error={isPartial && (!writeKey || writeKey === '')}
          />
        </div>
        <div>
          <PropertyLabel label='Data Plane Url' />
          <TextField
            className='dialog-form-input'
            value={dataPlaneUrl || ''}
            onChange={setDataPlaneUrl}
            error={isPartial && (!dataPlaneUrl || dataPlaneUrl === '')}
          />
        </div>
        <div>
          <PropertyLabel label='Test Write Key (Optional)' />
          <TextField
            className='dialog-form-input'
            value={testWriteKey || ''}
            onChange={setTestWriteKey}
          />
        </div>
        <div>
          <PropertyLabel label='Test Data Plane Url (Optional)' />
          <TextField
            className='dialog-form-input'
            value={testDataPlaneUrl || ''}
            onChange={setTestDataPlaneUrl}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default RudderStackSettingsSidebar;
