import type { ComponentProps } from 'react';
import { RedoIcon } from '../../Icons';
import { Button } from './button';

function Redo(props: Omit<ComponentProps<typeof Button>, 'children'>) {
  return (
    <Button
      variant='outline'
      className='hover:bg-primary hover:text-white hover:border-primary'
      size='icon'
      {...props}
    >
      <RedoIcon className='stroke-current h-5 w-5' />
    </Button>
  );
}

export default Redo;
