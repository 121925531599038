import { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { stopReactEventPropagation } from '../util';
import styles from './styles.module.scss';
import useCleanUp from '../../../utils/useCleanUp';
import { SIZE_UNITS } from '../../../utils/constants';
import { FillSizeIcon, FitSizeIcon } from '../../Icons';
import CaretIcon from '../../Icons/system/CaretIcon';

function NumberInput({
  min = 0,
  max,
  showPxMaxLabel = false,
  showCaret = false,
  verticalIcon = false,
  classNames = {},
  placeholder = '',
  dimension,
  required = false,
  disabled = false,
  value: initValue,
  unit: initUnit,
  onComplete: customOnComplete = () => {},
  onChange: customOnChange = null,
  units = [],
  error: customError = false,
  // In the detail panel, propagate unsaved changes if focus changes
  triggerCleanUp = false
}: any) {
  const [rawValue, setRawValue] = useState(initValue ?? '');
  const [rawUnit, setRawUnit] = useState(
    units.length === 1 ? units[0] : initUnit
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => setRawValue(initValue ?? ''), [initValue]);

  useEffect(() => {
    const unit = units.length === 1 ? units[0] : initUnit;
    setRawUnit(unit);
  }, [initUnit, units]);

  const effectiveMax = useMemo(
    () => (rawUnit === '%' && max === undefined ? 100 : max),
    [max, rawUnit]
  );

  // On mousedown anywhere, close the dropdown
  useEffect(() => {
    const closeDropdown = () => setShowDropdown(false);
    document.addEventListener('mousedown', closeDropdown);
    return () => document.removeEventListener('mousedown', closeDropdown);
  }, []);

  // Units could be a list of strings or a list of { value, display }
  // Standardize the shape for use in the component
  const unitObjects = useMemo(
    () =>
      // @ts-expect-error TS(7006) FIXME: Parameter 'u' implicitly has an 'any' type.
      units.map((u) => {
        return typeof u === 'object' ? u : { value: u, display: u };
      }),
    [units]
  );

  const { mounted, clearCleanUp, setCleanUp } = useCleanUp({ triggerCleanUp });

  function parseInput(event: any) {
    const parsedValue = Number.parseInt(event.target.value);
    return isNaN(parsedValue) ? '' : parsedValue;
  }

  function getError(value: any) {
    if (min !== undefined && value !== '' && value < min)
      return `Value must be more than ${min}`;
    if (effectiveMax !== undefined && value !== '' && value > effectiveMax)
      return `Value must be less than ${effectiveMax}`;
    if (required && value === '') return 'This field is required';
  }

  function onUnitMouseDown(event: any) {
    stopReactEventPropagation(event);
    if (unitObjects.length > 1) {
      setShowDropdown(!showDropdown);
    }
  }

  function onComplete(value = rawValue) {
    const err = getError(rawValue);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (mounted.current) setError(err);
    if (!err)
      customOnComplete({ value, unit: rawUnit, mounted: mounted.current });
    clearCleanUp();
  }

  function onValueChange(event: any) {
    const value = parseInput(event);
    if (value && effectiveMax && value > effectiveMax) return;
    setRawValue(value);
    const err = getError(value);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    setError(err);
    if (!err) setCleanUp(() => onComplete(value));
    if (customOnChange) customOnChange(value, mounted.current);
  }

  function onUnitChange(newUnit: any) {
    return (event: any) => {
      event.stopPropagation();
      setShowDropdown(false);
      setRawUnit(newUnit);
      if (!error) {
        customOnComplete({
          value: rawValue,
          unit: newUnit,
          mounted: mounted.current
        });
        if (triggerCleanUp) clearCleanUp();
      }
    };
  }

  const isUnitFit = rawUnit === SIZE_UNITS.FIT;
  const isUnitFill = rawUnit === SIZE_UNITS.FILL;
  const hasNumberValue = !isUnitFit && !isUnitFill;
  const SizeIcon = isUnitFit ? FitSizeIcon : FillSizeIcon;

  const unitDisplay = unitObjects.length >= 1 && (
    <div
      className={cn(
        styles.unit,
        unitObjects.length > 1 && styles.interactive,
        classNames.unit,
        !hasNumberValue && styles.noNumberUnit
      )}
      onMouseDown={onUnitMouseDown}
    >
      {unitObjects.length === 1
        ? rawUnit?.display || rawUnit
        : // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
          unitObjects.find(({ value }) => value === rawUnit)?.display}
    </div>
  );

  return (
    <div
      className={cn(
        styles.numberInput,
        (customError || error) && styles.errored,
        !hasNumberValue && styles.interactive,
        !hasNumberValue && styles.noNumberInput,
        classNames.root
      )}
      onMouseDown={!hasNumberValue ? onUnitMouseDown : undefined}
    >
      {!hasNumberValue ? (
        <>
          <SizeIcon
            className={cn(
              styles.inputSvg,
              classNames.icon,
              verticalIcon && styles.verticalFill
            )}
          />
          {unitDisplay}
          {showCaret && <CaretIcon className={classNames.caret} />}
        </>
      ) : (
        <>
          <div className={classNames.label}>
            {showPxMaxLabel && rawUnit === SIZE_UNITS.PX ? 'Max' : ''}
          </div>
          {hasNumberValue && (
            <input
              id={dimension && `numberInput-${dimension}`}
              className={cn('no-arrows', classNames.input)}
              type='number'
              placeholder={placeholder}
              value={rawValue}
              min={min}
              max={effectiveMax}
              required={required}
              disabled={disabled}
              onChange={onValueChange}
              onBlur={() => onComplete()}
              onKeyPress={(event) => event.key === 'Enter' && onComplete()}
            />
          )}
          {unitDisplay}
        </>
      )}
      {showDropdown && (
        <div className={cn(styles.dropdown, classNames.dropdown)}>
          {/* @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message */}
          {unitObjects.map(({ value, display }) => (
            <div
              key={value}
              className={cn(styles.dropdownItem, classNames.dropdownItem)}
              onMouseDown={onUnitChange(value)}
              style={{ textTransform: 'lowercase' }}
            >
              {display}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(NumberInput);
