import { DropdownField } from '../../../../../../../components/Core';
import { useRuleBuilderUpdater } from '../../../context';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import { useAppSelector } from '../../../../../../../hooks';
import classNames from 'classnames';
import ruleStyles from '../../../../../../../components/NavigationRules/styles.module.scss';
import styles from '../styles.module.scss';

type NavigateToStepActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: NavigateToStepActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const [step] = action.parameters;

  const stepOptions = Object.keys(workingSteps).map((id: string) => ({
    display: workingSteps[id].key,
    value: id
  }));

  const handleStepChange = (newValue: string) => {
    if (step) {
      updateOperand(step.id, {
        type: 'value',
        value: newValue,
        meta: {
          step_key: workingSteps[newValue].key
        }
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;
      operand.meta.key = {
        step_key: workingSteps[newValue].key
      };

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  return (
    <DropdownField
      className={classNames(ruleStyles.ruleTextField, ruleStyles.valueInput)}
      selected={step?.value ?? ''}
      title={step?.value ?? ''}
      options={stepOptions}
      onChange={(event: any) => handleStepChange(event.target.value)}
    />
  );
};

const ViewMode = ({ action }: NavigateToStepActionProps) => {
  const [step] = action.parameters;
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const getDisplay = (operand: IRuleOperand) => {
    return workingSteps[operand.value]?.key ?? 'Unknown Step';
  };

  return (
    <>
      <Operand operand={step} getDisplay={getDisplay} bold />
    </>
  );
};

export const NavigateToStepAction = ({
  action,
  mode = 'view'
}: NavigateToStepActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
