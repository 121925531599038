import { useEffect, useState, useCallback } from 'react';
import { TextField } from '../../../Core';
import { Button } from '../../../Core/Button/button';
import { InfoIcon, TrashIcon } from '../../../Icons';
import styles from './styles.module.scss';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

type KeyValuePair = [string, string];

export interface KeyComponentProps {
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

const BasicKeyComponent = ({
  value,
  onComplete = () => {},
  disabled = false
}: KeyComponentProps) => (
  <TextField
    className={styles.textField}
    placeholder='Key'
    value={value}
    onComplete={onComplete}
    disabled={disabled}
  />
);

export interface ValueComponentProps
  extends React.ComponentPropsWithoutRef<typeof TextField> {
  keyValue?: string;
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
}

export const BasicValueComponent = ({
  value,
  onComplete = () => {},
  disabled = false,
  error = false,
  ...props
}: ValueComponentProps) => (
  <TextField
    className={styles.textField}
    placeholder='Value'
    value={value}
    onComplete={onComplete}
    disabled={disabled}
    error={error}
    errorMessage={''}
    {...props}
  />
);

interface KeyValueInputProps {
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
  addButtonText?: string;
  readOnlyValues?: Record<string, string>;
  readOnlyInfo?: string;
  editableKeys?: boolean;
  allowEmptyKeys?: boolean;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  KeyComponent?: React.ComponentType<KeyComponentProps>;
  ValueComponent?: React.ComponentType<ValueComponentProps>;
}

export const KeyValueInput = ({
  value,
  onChange,
  addButtonText = 'Add',
  readOnlyValues = {},
  readOnlyInfo,
  allowEmptyKeys = false,
  editableKeys = true,
  hideAddButton = false,
  hideDeleteButton = false,
  KeyComponent = BasicKeyComponent,
  ValueComponent = BasicValueComponent
}: KeyValueInputProps) => {
  const [pairs, setPairs] = useState<KeyValuePair[]>(() => {
    const entries = Object.entries(value);
    return entries.length ? entries : [['', '']];
  });

  useEffect(() => {
    // special logic to allow state updates AND allow empty pairs while editing
    const entries = Object.entries(value);
    const entriesJson = JSON.stringify(entries);
    const pairsJson = JSON.stringify(pairs.filter(([key]) => key));

    if (entriesJson !== pairsJson) {
      setPairs(entries.length ? entries : [['', '']]);
    }
  }, [value]);

  const notifyChange = useCallback(
    (newPairs: KeyValuePair[]) => {
      // Only notify parent of valid pairs
      const validPairs = newPairs.filter(([key]) => key);
      const newValue = Object.fromEntries(validPairs);

      if (JSON.stringify(newValue) !== JSON.stringify(value)) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  const handleAdd = useCallback(() => {
    // Don't notify parent when adding empty pair
    setPairs((currentPairs) => [...currentPairs, ['', '']]);
  }, []);

  const handleChange = useCallback(
    (index: number, field: 'key' | 'value', newValue: string) => {
      setPairs((currentPairs) => {
        const newPairs = currentPairs.map((pair, i) =>
          i === index
            ? ([
                field === 'key' ? newValue : pair[0],
                field === 'value' ? newValue : pair[1]
              ] as KeyValuePair)
            : pair
        );

        const changedPair = newPairs[index];
        if (changedPair[0]) {
          notifyChange(newPairs);
        }

        return newPairs;
      });
    },
    [notifyChange]
  );

  const handleDelete = useCallback(
    (index: number) => {
      setPairs((currentPairs) => {
        const newPairs = currentPairs.filter((_, i) => i !== index);
        const finalPairs = newPairs.length
          ? newPairs
          : ([['', '']] as KeyValuePair[]);
        notifyChange(finalPairs);
        return finalPairs;
      });
    },
    [notifyChange]
  );

  return (
    <div className={styles.keyValueInputContainer}>
      {Object.entries(readOnlyValues).map(([key, value], index) => (
        <div key={`readonly-${key}-${index}`} className={styles.keyValueInput}>
          <KeyComponent index={index} value={key} disabled />
          <div>=</div>
          <ValueComponent index={index} value={value} disabled />
          {readOnlyInfo && (
            <Tooltip content={readOnlyInfo}>
              <InfoIcon width={16} height={16} color='var(--grey-80)' />
            </Tooltip>
          )}
        </div>
      ))}

      {pairs.map(([key, value], index) => (
        <div
          key={`editable-${key}-${index}`}
          className={styles.keyValueInput}
          style={{
            gridTemplateColumns: hideDeleteButton
              ? '1fr 8px 1fr'
              : '1fr 8px 1fr 44px'
          }}
        >
          <KeyComponent
            value={key}
            index={index}
            disabled={!editableKeys}
            onComplete={(newKey) =>
              editableKeys && handleChange(index, 'key', newKey)
            }
          />
          <div>=</div>
          <ValueComponent
            keyValue={key}
            value={value}
            index={index}
            onComplete={(newValue) => handleChange(index, 'value', newValue)}
            disabled={!allowEmptyKeys && !key}
          />
          {!hideDeleteButton && (
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(index)}
            >
              <TrashIcon width={16} height={16} />
            </button>
          )}
        </div>
      ))}

      {!hideAddButton && (
        <div className={styles.keyValueInputActions}>
          <Button
            variant='text-primary'
            className='h-4'
            onClick={(e) => {
              e.preventDefault();
              handleAdd();
            }}
          >
            + {addButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};
