import { ComponentProps } from 'react';

export default function BackArrowIcon({
  width = 24,
  height = 24,
  ...rest
}: ComponentProps<'svg'>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='currentColor'
      {...rest}
    >
      <path
        d='M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28 16.0517L4.0001 16.0517'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
