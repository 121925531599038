import { APIConnectorTemplatePage } from './pages/APIConnectorTemplatePage';
import { ConfigureRequestPage } from './pages/ConfigureRequestPage';
import { MapResponseDataPage } from './pages/MapResponseDataPage';
import { MapResponseStructurePage } from './pages/MapResponseStructurePage';
import { SelectAPIConnectorPage } from './pages/SelectAPIConnectorPage';
import { TestRequestPage } from './pages/TestRequestPage';

export const SELECT_API_SERVICE_PAGE = 0;
export const API_CONNECTOR_TEMPLATE_PAGE = 1;
export const CONFIGURE_REQUEST_PAGE = 2;
export const MAP_RESPONSE_STRUCTURE_PAGE = 3;
export const TEST_REQUEST_PAGE = 4;
export const MAP_RESPONSE_DATA_PAGE = 5;

export const PAGE_COMPONENTS: any = {
  [SELECT_API_SERVICE_PAGE]: SelectAPIConnectorPage,
  [API_CONNECTOR_TEMPLATE_PAGE]: APIConnectorTemplatePage,
  [CONFIGURE_REQUEST_PAGE]: ConfigureRequestPage,
  [MAP_RESPONSE_STRUCTURE_PAGE]: MapResponseStructurePage,
  [TEST_REQUEST_PAGE]: TestRequestPage,
  [MAP_RESPONSE_DATA_PAGE]: MapResponseDataPage
};

export const GUIDE_TEXT = {
  salesforce: (
    <div>
      <p className='font-medium'>
        Pre-fill your form with Salesforce object data.
      </p>
      <p>
        Set a{' '}
        <a
          href='https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm'
          target='_blank'
          rel='noreferrer'
        >
          SOQL query
        </a>{' '}
        below to define what Salesforce records to pull. Add Feathery field
        values to the query by including the field ID wrapped in double curly
        braces and quotes within a WHERE clause, such as{' '}
        <pre
          style={{
            display: 'inline',
            padding: '2px 3px',
            background: '#ededed',
            borderRadius: 4
          }}
        >
          {"WHERE Email = '{{ExampleEmailField}}'"}
        </pre>
        .
      </p>
    </div>
  )
};
