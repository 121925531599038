import '../../style/dialog-form.css';

import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';

function PersonaSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.PERSONA,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [templateId, setTemplateId] = useState(
    integration?.data.metadata.template_id ?? ''
  );
  const [environmentId, setEnvironmentId] = useState(
    integration?.data.metadata.environment_id ?? ''
  );

  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !templateId || !environmentId;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      template_id: templateId,
      environment_id: environmentId
    };
    return { isUpdate: integration?.data, metadata };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.PERSONA]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Template ID' />
          <TextField
            className='dialog-form-input'
            value={templateId}
            onChange={setTemplateId}
            error={isPartial && !templateId}
          />
        </div>
        <div>
          <PropertyLabel label='Environment ID' />
          <TextField
            className='dialog-form-input'
            value={environmentId}
            onChange={setEnvironmentId}
            error={isPartial && !environmentId}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default PersonaSettingsSidebar;
