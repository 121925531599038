import { Fragment } from 'react';
import { DropdownField } from '../../../Core';
import Label from '../../../Dialog/Label';
import { CheckCircleIcon } from '../../../Icons';

type MapFieldsStepProps = {
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  servarKeysById: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  mapFieldData: Record<string, string>;
  promoteKey: string;
};

export function MapFieldsStep(props: MapFieldsStepProps) {
  const {
    thisFormServarIds,
    promoteToServarIds,
    servarKeysById,
    setMapFieldData,
    mapFieldData,
    promoteKey
  } = props;
  const selectedFields = Object.values(mapFieldData);
  const options = [
    { value: '', display: 'New field / no mapping' },
    ...promoteToServarIds.map((s) => {
      return {
        value: s,
        display: servarKeysById[s],
        disabled: selectedFields.includes(s)
      };
    })
  ];
  return (
    <div
      className='overflow-y-scroll max-h-[calc(100vh-32px-200px)]'
      style={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      {thisFormServarIds.length === 0 && (
        <div className='h-16 flex items-center font-lg gap-2'>
          <CheckCircleIcon height={18} width={18} />
          No fields to map
        </div>
      )}
      {thisFormServarIds.length > 0 && (
        <div className='grid grid-cols-2 gap-x-4 gap-y-2 items-center'>
          <Label className='-mb-1.5'>This Form</Label>
          <Label className='-mb-1.5'>{promoteKey}</Label>
          {thisFormServarIds.map((thisServar) => {
            return (
              <Fragment key={thisServar}>
                <div>{servarKeysById[thisServar]}</div>
                <DropdownField
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setMapFieldData((curr) => ({
                      ...curr,
                      [thisServar]: event.target.value
                    }))
                  }
                  selected={mapFieldData[thisServar] || ''}
                  options={options}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
}
