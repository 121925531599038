import { Switch } from '../../../Core';
import { useState } from 'react';
import Dialog from '../../../Dialog';
import { Neutral, Positive } from '../../../Core/Button';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

export default function MultiselectToggle({
  servar,
  onChange,
  disabled = false
}: any) {
  const [show, setShow] = useState(false);

  const multiple = servar.metadata.multiple;

  if (!['file_upload', 'button_group', 'matrix'].includes(servar.type))
    return null;

  const multiDisabled =
    servar.type === 'file_upload' && !multiple && servar.repeated;

  let switchComponent = (
    <label className='flex items-center gap-2'>
      <Switch
        id='multiple'
        checked={multiple}
        onCheckedChange={(checked) => {
          if (multiple && servar.has_data && !checked) {
            setShow(true);
          } else onChange(checked);
        }}
        disabled={multiDisabled || disabled}
      />
      Allow Multiple {servar.type === 'file_upload' ? 'Files' : 'Selections'}
    </label>
  );
  if (multiDisabled)
    switchComponent = (
      <Tooltip
        content='Repeating file upload fields do not support multiple files per
          field.'
      >
        <div>{switchComponent}</div>
      </Tooltip>
    );
  return (
    <>
      <div style={{ marginBottom: '5px' }}>{switchComponent}</div>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='Turn field to single select?'
        size='sm'
      >
        <p style={{ padding: '40px 0', fontSize: '16px' }}>
          You have existing submissions with multiple selections. They will be
          reduced to single values.
        </p>
        <div
          className='dialog-form-action'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Neutral onClick={() => setShow(false)}>No</Neutral>
          <Positive
            onClick={(event: any) => {
              event.preventDefault();
              onChange(false);
              setShow(false);
            }}
          >
            Yes
          </Positive>
        </div>
      </Dialog>
    </>
  );
}
