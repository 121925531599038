import { Cell } from '../components/RenderingEngine/GridInGrid/engine';
import useElementRefCleanup from '../utils/useElementRefCleanup';
import { getGigUndoRedoPayload } from '../utils/step';
import { useGig } from '../context/Gig';
import { useAppSelector } from '.';
import { AXIS } from '../utils/constants';
import useFeatheryRedux from '../redux';
import { refreshNode } from '../components/RenderingEngine/utils/gig';

export const useSelectedCell = () => {
  const { gig, node: selectedCell, workingSteps, activeStepId } = useGig();
  const {
    formBuilder: { setPanelDataWithUndoRedo, wipeFocus, gigSetPosition },
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const { cleanupAllElementReferences, deleteElements } =
    useElementRefCleanup();
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );

  const remove = (_node?: Cell, returnPayload = false) => {
    let node: Cell | null = _node ?? selectedCell;
    node = refreshNode(node); // make sure action is performed on the latest node
    if (!node || node.isRoot()) return;

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));
    const containedElementsMap = node.getDescendantElements();
    const nodeType = node.isElement ? 'element' : 'container';

    if (node.remove()) {
      wipeFocus();
      addInfoToast(`Removed ${nodeType}.`);

      // now properly remove each element which could affect any step (fixes up hide_if, nav rules)
      gig?.removeRedundantCells();
      nextWorkingSteps[activeStepId] = gig?.toStep();
      const changedStepKeys = new Set<string>([activeStepId]);
      const changedLogicRuleKeys = new Set<string>();
      // now properly remove each element which could affect any step (fixes up hide_if, nav rules)
      let { steps: newSteps, logicRules: newLogicRules } = deleteElements(
        nextWorkingSteps,
        activeStepId,
        containedElementsMap,
        changedStepKeys,
        workingLogicRules,
        changedLogicRuleKeys
      );

      // Also clean references to the deleted container and descendant containers from the logic rules
      Object.values(node.getDescendantCells()).forEach((cell) => {
        const { steps, logicRules } = cleanupAllElementReferences(
          newSteps,
          cell,
          changedStepKeys,
          newLogicRules,
          changedLogicRuleKeys
        );
        newSteps = steps;
        newLogicRules = logicRules;
      });

      if (returnPayload) {
        return [newSteps, changedStepKeys, newLogicRules, changedLogicRuleKeys];
      }

      const undoRedoPayload = getGigUndoRedoPayload({
        stepId: activeStepId,
        oldSteps: workingSteps,
        newSteps,
        changedStepKeys: Array.from(changedStepKeys),
        oldLogicRules: workingLogicRules,
        newLogicRules,
        changedLogicRuleKeys: Array.from(changedLogicRuleKeys)
      });

      setPanelDataWithUndoRedo(undoRedoPayload);
    } else {
      return false;
    }
  };

  const rotateAxis = (_node?: Cell) => {
    let node: Cell | null = _node ?? selectedCell;
    node = refreshNode(node); // make sure action is performed on the latest node
    if (!node || !node?.hasChildren()) return;

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));

    node.rotateAxis();
    nextWorkingSteps[activeStepId] = gig?.toStep();

    const undoRedoPayload = getGigUndoRedoPayload({
      stepId: activeStepId,
      oldSteps: workingSteps,
      newSteps: nextWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
    addInfoToast(
      `Rotated axis to ${node.axis === AXIS.COL ? 'columns' : 'rows'}.`
    );
  };

  const splitCols = (_node?: Cell) => {
    let node: Cell | null = _node ?? selectedCell;
    node = refreshNode(node); // make sure action is performed on the latest node
    if (!node) return;

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));

    const newNode = node.splitCols();

    wipeFocus();
    nextWorkingSteps[activeStepId] = gig?.toStep();

    const undoRedoPayload = getGigUndoRedoPayload({
      stepId: activeStepId,
      oldSteps: workingSteps,
      newSteps: nextWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
    gigSetPosition(newNode?.parent?.position);
    addInfoToast('Split container into columns.');
  };

  const splitRows = (_node?: Cell) => {
    let node: Cell | null = _node ?? selectedCell;
    node = refreshNode(node); // make sure action is performed on the latest node
    if (!node) return;

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));

    const newNode = node.splitRows();

    wipeFocus();
    nextWorkingSteps[activeStepId] = gig?.toStep();

    const undoRedoPayload = getGigUndoRedoPayload({
      stepId: activeStepId,
      oldSteps: workingSteps,
      newSteps: nextWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
    gigSetPosition(newNode?.parent?.position);
    addInfoToast('Split container into rows.');
  };

  const releaseChildren = (_node?: Cell) => {
    let node: Cell | null = _node ?? selectedCell;
    node = refreshNode(node); // make sure action is performed on the latest node
    if (!node) return;

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));

    node.releaseChildren();

    wipeFocus();
    nextWorkingSteps[activeStepId] = gig?.toStep();

    const undoRedoPayload = getGigUndoRedoPayload({
      stepId: activeStepId,
      oldSteps: workingSteps,
      newSteps: nextWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
    addInfoToast('Released children from container');
  };

  return { remove, rotateAxis, splitCols, splitRows, releaseChildren };
};
