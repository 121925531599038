import { Button } from '../../../../../../../components/Core/Button/button';
import { HTTPMethodBadge } from './HTTPMethodBadge';

type APIConnectorInputProps = {
  method?: string;
  url?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const APIConnectorInput = ({
  method = '',
  url = '',
  onClick = () => {},
  disabled = false
}: APIConnectorInputProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Button
      variant='outline'
      className='px-2 disabled:text-neutral-text'
      onClick={handleClick}
      disabled={disabled}
    >
      <HTTPMethodBadge method={method} />
      {url || 'Click to Configure'}
    </Button>
  );
};
