import { AIExtractionEntryValue } from '../types';
import { AIStarIcon } from '../../../components/Icons';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  entry: AIExtractionEntryValue;
};

const EntryValueDisplay = ({ entry }: Props) => {
  const entryValue = entry.value;
  const isAIGenerated = entry.source
    ? entry.source === 'email' || entry.source === 'document'
    : entry.xCoordinate >= 0;
  const hasBoundingBox = entry.xCoordinate >= 0;
  const isEmpty = entryValue === '';
  const isAIWithBoundingBox = isAIGenerated && hasBoundingBox && !isEmpty;

  return (
    <div className={styles.entryValueDisplay}>
      {isAIGenerated && (
        <div
          className={classNames(styles.icon, {
            [styles.red]: isAIWithBoundingBox,
            [styles.grey]: !isAIWithBoundingBox
          })}
        >
          <AIStarIcon height={12} />
        </div>
      )}
      <div className={classNames(styles.text, { [styles.tag]: isEmpty })}>
        {isEmpty ? 'NO DATA' : entryValue}
      </div>
    </div>
  );
};

export default EntryValueDisplay;
