import { forwardRef, memo } from 'react';
import { DynamicTextTooltip, PropertyLabel } from '../Core';
import { CopyIcon, TrashIcon } from '../Icons';
import { TYPE_CONTAINER } from '../../utils/elements';
import styles from './formFieldEditor.module.scss';
import useViewport from '../../hooks/useViewport';
import elementEntries from '../SelectionPanel/elementEntries';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';
import { Button } from '../Core/Button/button';
import Row from '../Core/Layout/Row';
import Col from '../Core/Layout/Col';

const ELEMENT_ID_MAX_LENGTH = 20;

/**
 * Generic component for creating/editing elements in a Feathery form.
 * Pass in a React form child which will be used to actually perform the creating/editing.
 */
function FormFieldEditor(
  {
    mode,
    children,
    elementType,
    onDelete = () => {},
    deleteLabel = 'Delete',
    element,
    assetName,
    customHeaderRows = [],
    fieldIdToggle,
    cellNode
  }: any,
  ref: any
) {
  const { isMobile } = useViewport();
  const nodeType = cellNode?.isContainer ? TYPE_CONTAINER : elementType;
  const detailError = useAppSelector((state) => state.formBuilder.detailError);
  const {
    toasts: { addToast }
  } = useFeatheryRedux();

  const headerRows = [...customHeaderRows];

  if (mode === 'builder') {
    if (fieldIdToggle) headerRows.push(fieldIdToggle);
    else if (element) {
      let elementId = element?.id ?? '';
      if (elementId.length > ELEMENT_ID_MAX_LENGTH) {
        elementId = elementId.slice(0, ELEMENT_ID_MAX_LENGTH) + '...';
      }
      headerRows.push(
        <Row key='id'>
          <Col sm='3'>
            <PropertyLabel label='ID' />
          </Col>
          <Col>
            <DynamicTextTooltip
              text={element?.id}
              maxLength={ELEMENT_ID_MAX_LENGTH}
            >
              <div className={styles.idContainer}>
                <span className={styles.id}>{elementId}</span>
                <CopyIcon
                  height={14}
                  width={14}
                  onClick={() => {
                    navigator.clipboard.writeText(element?.id ?? '');
                    addToast({
                      text: 'ID copied to clipboard.'
                    });
                  }}
                />
              </div>
            </DynamicTextTooltip>
          </Col>
        </Row>
      );
    }
  }

  if (assetName || element?.id) {
    headerRows.push(
      <Row key='asset-element'>
        <Col sm='3'>
          <PropertyLabel label={assetName ? 'Asset' : 'Element'} />
        </Col>
        <Col>
          <div className={styles.labelType}>
            {elementEntries[elementType].icon}
            {assetName || 'Primary'}
          </div>
        </Col>
      </Row>
    );
  } else if (nodeType in elementEntries) {
    headerRows.push(
      <Row key='type'>
        <Col sm='3'>
          <PropertyLabel label='Type' />
        </Col>
        <Col>
          <div className={styles.labelType}>
            {elementEntries[nodeType].label}
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <div className={styles.formFieldEditorDialog}>
      <div className={styles.editorBody} ref={ref}>
        {(element?.id || assetName || customHeaderRows.length > 0) && (
          <div className={styles.fixedPanel}>{headerRows}</div>
        )}
        {children}
        {(mode === 'builder' || assetName) && !cellNode?.isRoot() && (
          <Row className={styles.deleteFooter}>
            <Col className='text-right'>
              {!isMobile && (
                <Button variant='destructive' onClick={onDelete}>
                  <TrashIcon className='w-5 h-5 stroke-current' />
                  <span>{deleteLabel}</span>
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>
      {detailError && <div className={styles.error}>{detailError}</div>}
    </div>
  );
}

export default memo(forwardRef(FormFieldEditor));
