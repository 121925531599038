import '../../style/dialog-form.css';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { Fragment, useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownField, InlineTooltip, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Label from '../Dialog/Label';
import useIntegrations from './useIntegrations';
import { PlusIcon, TrashIcon } from '../Icons';
import { Link, useParams } from 'react-router-dom';

const DEFAULT_HEADERS = [{ name: '', value: '' }];

const DEFAULT_SETTINGS = {
  webhook: '',
  test_webhook: '',
  trigger: 'form_completion',
  headers: DEFAULT_HEADERS
};

function WebhookSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.WEBHOOK,
    panelId: formId,
    includeInactive: true
  });

  const [webhookConfig, setWebhookConfig] = useState(
    integration?.data.metadata ?? DEFAULT_SETTINGS
  );

  const setField = (key: string, value: any) =>
    setWebhookConfig((webhookConfig: any) => ({
      ...webhookConfig,
      [key]: value
    }));

  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !webhookConfig.webhook;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      metadata: { ...webhookConfig }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.WEBHOOK]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <p className='text-muted'>
          Form data will be sent to the webhook URL when the specified event
          occurs. The format of the data is &#123;&#60;FIELD ID&gt;: &lt;FIELD
          VALUE&gt;&#125;
        </p>
        <br />
        <p className='text-muted'>
          For fully customizable payloads and triggers, use{' '}
          <Link to={`/forms/${formId}/logic/connectors`}>API connectors</Link>
        </p>
        <div>
          <Label>URL</Label>
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='https://webhook.com'
            value={webhookConfig.webhook || ''}
            onChange={(value: string) => setField('webhook', value)}
            error={isPartial && !webhookConfig.webhook}
          />
        </div>
        <div>
          <Label>
            <>
              Test URL (Optional)
              <InlineTooltip
                text='URL used in the Test Form environment. If not specified, the default URL will be used'
                style={{ display: 'inline' }}
              />
            </>
          </Label>
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='https://webhook.com'
            value={webhookConfig.test_webhook || ''}
            onChange={(value: string) => setField('test_webhook', value)}
          />
        </div>
        <div>
          <Label>Headers</Label>
          <div
            className={classNames(styles.twoColumnContainer, styles.rightWide)}
          >
            {webhookConfig.headers.map(
              ({ name, value }: any, index: number) => (
                <Fragment key={index}>
                  <TextField
                    placeholder='Name'
                    className='dialog-form-input'
                    value={name}
                    onComplete={(newName: any) => {
                      const newHeaders = [...webhookConfig.headers];
                      newHeaders[index] = { name: newName, value };
                      setField('headers', newHeaders);
                    }}
                    error={isPartial && !name}
                  />
                  <div className={styles.fieldSelectorContainer}>
                    <TextField
                      placeholder='Value'
                      className='dialog-form-input'
                      value={value}
                      onComplete={(newValue: any) => {
                        const newHeaders = [...webhookConfig.headers];
                        newHeaders[index] = { name, value: newValue };
                        setField('headers', newHeaders);
                      }}
                    />
                    <TrashIcon
                      height={16}
                      width={16}
                      className={classNames(
                        'tr-icon',
                        styles.customPropertyDelete
                      )}
                      style={{ marginLeft: '8px' }}
                      onClick={() => {
                        const newHeaders = [...webhookConfig.headers];
                        newHeaders.splice(index, 1);
                        setField('headers', newHeaders);
                      }}
                    />
                  </div>
                </Fragment>
              )
            )}
          </div>
          <PlusIcon
            className={styles.customPropertyAdd}
            style={{ marginBottom: 0 }}
            onClick={() => {
              const newHeaders = [...webhookConfig.headers];
              newHeaders.push({ name: '', value: '' });
              setField('headers', newHeaders);
            }}
          />
        </div>
        <div>
          <Label>Send data when</Label>
          <DropdownField
            selected={webhookConfig.trigger}
            onChange={(event: any) => setField('trigger', event.target.value)}
            options={[
              { value: 'form_completion', display: 'the form is completed' },
              {
                value: 'data_received',
                display: 'any user data is received (e.g. step submission)'
              }
            ]}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default WebhookSettingsSidebar;
