import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';
import {
  CheckboxField,
  CollapsibleSection,
  InfoCard,
  PropertyLabel,
  TextField
} from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import NumberInput from '../Core/NumberInput';
import useIntegrations from './useIntegrations';
import { FieldSelectorWithModal } from '../Modals';
import AuthorizedSteps from './shared/AuthorizedSteps';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';

const DEFAULT_SETTINGS = {
  login_expiration: 60,
  signup_expiration: 1440,
  session_duration: 60,
  user_field_mapping: {},
  auth_gate_steps: [],
  login_step: '',
  logout_step: '',
  no_merge: false
};

const USER_FIELDS = [
  { label: 'Email', val: 'email' },
  { label: 'Phone', val: 'phone' },
  { label: 'First Name', val: 'first_name' },
  { label: 'Last Name', val: 'last_name' }
];

const STYTCH_TEMPLATE_TEXT =
  'The provided Stytch test token can only be used on forms hosted by Feathery. The ' +
  'integration will turn off after 50 users have used the token to sign up.';

const STYTCH_TEMPLATE_ERROR_TEXT =
  'Your Stytch access has expired. Please replace the test token with your own.';

function StytchSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const panel = useAppSelector((state) => state.panels.panels[formId]);

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.STYTCH,
    panelId: formId,
    includeInactive: true
  });

  const [err, setErr] = useState('');

  // Local/draft integration settings
  const [isPartial, setIsPartial] = useState(false);
  const [stytchConfig, setStytchConfig] = useState(
    integration?.data.metadata ?? DEFAULT_SETTINGS
  );
  const userFieldMapping = { ...stytchConfig.user_field_mapping };
  const setFields = (fields: any) =>
    setStytchConfig((stytchConfig: any) => ({
      ...stytchConfig,
      ...fields
    }));

  function onSubmitCustom(newIsActive: boolean) {
    if (!panel.track_users && newIsActive) {
      setErr(
        '`Remember User` must be turned on in your form settings to use Stytch'
      );
      return;
    }
    setErr('');

    if (newIsActive) {
      const { live_token: liveToken, test_token: testToken } = stytchConfig;
      const partial = !liveToken && !testToken;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      apiKey: '',
      metadata: stytchConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.STYTCH]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <>
        {integration?.data.error === 'STYTCH_NO_QUOTA' && (
          <InfoCard text={STYTCH_TEMPLATE_ERROR_TEXT} />
        )}
        {integration?.data.is_stytch_template_key && (
          <InfoCard text={STYTCH_TEMPLATE_TEXT} />
        )}
        <form>
          <div>
            <PropertyLabel label='Test Token' />
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              type='password'
              placeholder='public-token-test-...'
              value={stytchConfig.test_token || ''}
              onChange={(value: any) => setFields({ test_token: value })}
              error={isPartial}
            />
            <PropertyLabel label='Live Token' />
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              type='password'
              placeholder='public-token-live-...'
              value={stytchConfig.live_token || ''}
              onChange={(value: any) => setFields({ live_token: value })}
              error={isPartial}
            />

            <AuthorizedSteps
              integrationConfig={stytchConfig}
              setFields={setFields}
            />

            <CheckboxField
              checked={stytchConfig.no_merge}
              text='Allow multiple submissions from the same user login'
              onChange={(flag) => setFields({ no_merge: flag })}
              style={{
                marginTop: '7px',
                marginBottom: '17px'
              }}
            />

            <CollapsibleSection
              title='Save User Attributes'
              collapsible
              expanded={false}
            >
              <p className={classNames('text-muted', styles.helperText)}>
                When the user signs up, you can save their info into Feathery
                fields.
              </p>
              <div className={styles.twoColumnContainer}>
                {USER_FIELDS.map(({ label, val }) => (
                  <Fragment key={val}>
                    <PropertyLabel label={label} />
                    <FieldSelectorWithModal
                      selectId={userFieldMapping[val]?.id}
                      selectType={userFieldMapping[val]?.type}
                      placeholder='Select'
                      onSelect={(data) => {
                        if (!data.selectId) delete userFieldMapping[val];
                        else
                          userFieldMapping[val] = {
                            id: data.selectId,
                            type: data.selectType
                          };
                        setStytchConfig({
                          ...stytchConfig,
                          user_field_mapping: userFieldMapping
                        });
                      }}
                      className={styles.marginBottom}
                    />
                  </Fragment>
                ))}
              </div>
            </CollapsibleSection>

            <CollapsibleSection
              title='Session Expiration & Duration'
              expanded={false}
            >
              <PropertyLabel label='Login Expiration Time (Minutes)' />
              <NumberInput
                value={stytchConfig.login_expiration}
                classNames={{
                  root: classNames('dialog-form-input', styles.marginBottom)
                }}
                onComplete={({ value: minutes }: any) => {
                  setFields({ login_expiration: minutes });
                }}
              />
              <PropertyLabel label='Signup Expiration Time (Minutes)' />
              <NumberInput
                value={stytchConfig.signup_expiration}
                classNames={{
                  root: classNames('dialog-form-input', styles.marginBottom)
                }}
                onComplete={({ value: minutes }: any) => {
                  setFields({ signup_expiration: minutes });
                }}
              />
              <PropertyLabel label='Session Duration (Minutes)' />
              <NumberInput
                value={stytchConfig.session_duration}
                classNames={{
                  root: classNames('dialog-form-input', styles.marginBottom)
                }}
                onComplete={({ value: minutes }: any) => {
                  setFields({ session_duration: minutes });
                }}
              />
            </CollapsibleSection>
          </div>
        </form>
      </>
    </IntegrationsSidebar>
  );
}

export default StytchSettingsSidebar;
