import { v4 as uuidv4 } from 'uuid';

export const getDefaultEntity = () => ({
  name: '',
  criteria: '',
  field_id: null,
  options: [],
  default_option: '',
  required: true,
  unique: false
});

export const defaultQuestionGroup = (type = 'image') => ({
  id: uuidv4(),
  question_type: type === 'image' ? 'one_value' : 'column_value',
  entities: [getDefaultEntity()],
  field_ids: [],
  criteria: '',
  run_email: false
});

export const getDefaultQuestionGroups = (extraction: any) => {
  return extraction?.question_groups?.length
    ? extraction.question_groups.map((group: any) => {
        return {
          ...group,
          entities: group.entities
            ? group.entities.map((entity: any, i: number) => ({
                ...entity,
                field_id: group.field_ids[i]
              }))
            : [getDefaultEntity()],
          toggle_page_question: !!group.page_question
        };
      })
    : [defaultQuestionGroup()];
};

export const questionGroupTemplates = {
  holdings: () => [
    {
      id: uuidv4(),
      question_type: 'one_value',
      field_ids: [],
      criteria: '',
      run_email: false,
      entities: [
        {
          name: 'Account Holder or Trustee First Name',
          criteria:
            'The first name of the person account holder or trustee of the company.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'one_value',
      field_ids: [],
      criteria: '',
      run_email: false,
      entities: [
        {
          name: 'Account Holder or Trustee Last Name',
          criteria:
            'The last name of the person account holder or trustee of the company.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'multiple_value',
      field_ids: [],
      criteria:
        'Current details of all active securities invested in by the account. Do not return transaction, activity, interest, dividend, loss/gain details or portfolio allocations/summaries. Ignore "Top Holdings" tables.',
      run_email: false,
      entities: [
        {
          name: 'Holding Name',
          criteria: 'Full name of the active holding. For example "Apple Inc".',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: true
        },
        {
          name: 'Holding Type',
          criteria: 'The type of the holding.',
          field_id: null,
          options: [
            { label: 'Stock', value: 'Stock' },
            { label: 'Mutual Fund', value: 'Mutual Fund' },
            { label: 'Bond', value: 'Bond' },
            { label: 'Treasury', value: 'Treasury' },
            { label: 'Cash', value: 'Cash' },
            { label: 'Option', value: 'Option' },
            { label: 'Other', value: 'Other' }
          ],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Holding Symbol',
          criteria:
            'Ticker symbol of the active security. For example "APPL". This value could be empty depending on the holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Quantity',
          criteria: 'The total quantity of the active holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Holding CUSIP Number',
          criteria:
            'The nine-digit CUSIP number of the active holding. This value could be empty depending on the holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Market Value',
          criteria:
            'The total market value of the active holding. This number can be negative or empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Cost Basis',
          criteria:
            'The cost basis of the holding. This is not the price or adjusted cost basis and could be empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: false
        },
        {
          name: 'Holding Adjusted Cost Basis',
          criteria:
            'The adjusted cost basis of the holding. This is not the price or cost basis and could be empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'multiple_value',
      field_ids: [],
      criteria: 'Transaction details where holding was bought or sold',
      run_email: false,
      entities: [
        {
          name: 'Security Name',
          criteria:
            'Full name of the security that was involved in the transaction. For example, "Apple Inc".',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Security Transaction Date',
          criteria: 'The date the transaction was settled.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Security Quantity',
          criteria:
            'The quantity of the security purchased in the transaction.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Transaction Type',
          criteria: 'Whether this transaction is a purchase or sale.',
          field_id: null,
          options: [
            { label: 'Purchase', value: 'Purchase' },
            { label: 'Sale', value: 'Sale' }
          ],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    }
  ]
};
