import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import classNames from 'classnames';
import { PlusIcon, TrashIcon } from '../Icons';

const CALENDLY_FIELDS = [
  { val: 'name', label: 'Name' },
  { val: 'first_name', label: 'First Name' },
  { val: 'last_name', label: 'Last Name' },
  { val: 'email', label: 'Email' },
  { val: 'location', label: 'Location' }
];

const DEFAULT_CUSTOM_INFO = [{ cKey: '', id: '', type: '' }];

const transformCustomInfo = (customInfo: Record<string, any>) => {
  return Object.entries(customInfo).map(([cKey, info]) => ({ ...info, cKey }));
};

function CalendlySettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.CALENDLY,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [calendlyUrl, setCalendlyUrl] = useState(
    integration?.data.metadata.api_key ?? ''
  );
  const [prefillInfo, setPrefillInfo] = useState(
    integration?.data.metadata.prefill_info ?? {}
  );
  const initialCustomInfo = transformCustomInfo(
    integration?.data.metadata.custom_questions ?? {}
  );
  const [customInfo, setCustomInfo] = useState(
    initialCustomInfo.length > 0 ? initialCustomInfo : DEFAULT_CUSTOM_INFO
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    // Filter out completely empty custom info rows
    const filtered = customInfo.filter((cf: any) => cf.cKey || cf.id);

    if (newIsActive) {
      const partial =
        !calendlyUrl || filtered.some(({ cKey, id }: any) => !cKey || !id);
      setIsPartial(partial);
      if (partial) return;
    }

    const customQuestions: Record<string, any> = {};
    filtered.forEach(
      ({ cKey, id, type }) => (customQuestions[cKey] = { id, type })
    );

    return {
      isUpdate: integration?.data,
      metadata: {
        api_key: calendlyUrl,
        prefill_info: prefillInfo,
        custom_questions: customQuestions
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.CALENDLY]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Calendar URL' />
          <TextField
            type='url'
            placeholder='https://calendly.com/my-calendar'
            value={calendlyUrl || ''}
            onChange={setCalendlyUrl}
            error={isPartial && !calendlyUrl}
          />
        </div>
        <div className={styles.twoColumnContainer}>
          <div className={styles.fieldHeaderText}>
            Prefill Calendly (Optional)
          </div>
          <div className={styles.fieldHeaderText}>Field</div>
          {CALENDLY_FIELDS.map(({ label, val }) => (
            <Fragment key={val}>
              <PropertyLabel label={label} />
              <FieldSelectorWithModal
                selectId={prefillInfo[val]?.id}
                selectType={prefillInfo[val]?.type}
                placeholder='Select'
                onSelect={(data) => {
                  setPrefillInfo({
                    ...prefillInfo,
                    [val]: { id: data.selectId, type: data.selectType }
                  });
                }}
                className={styles.marginBottom}
              />
            </Fragment>
          ))}
          {customInfo.map(({ cKey, ...field }: any, index: any) => (
            <Fragment key={index}>
              <TextField
                placeholder='Calendly Question Number'
                className='dialog-form-input'
                value={cKey}
                onComplete={(newKey: any) => {
                  const newInfo = [...customInfo];
                  newInfo[index] = { ...field, cKey: newKey };
                  setCustomInfo(newInfo);
                }}
                error={isPartial && !cKey}
              />
              <div className={styles.fieldSelectorContainer}>
                <FieldSelectorWithModal
                  selectId={field.id}
                  selectType={field.type}
                  placeholder='Select'
                  onSelect={(data) => {
                    const newInfo = [...customInfo];
                    newInfo[index] = {
                      ...field,
                      id: data.selectId,
                      type: data.selectType
                    };
                    setCustomInfo(newInfo);
                  }}
                  error={isPartial && !field.id}
                  className={classNames(
                    styles.marginBottom,
                    styles.fieldSelector
                  )}
                />
                <TrashIcon
                  height={16}
                  width={16}
                  className={classNames('tr-icon', styles.customPropertyDelete)}
                  onClick={() => {
                    const newInfo = [...customInfo];
                    newInfo.splice(index, 1);
                    setCustomInfo(newInfo);
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <PlusIcon
          className={styles.customPropertyAdd}
          onClick={() => {
            const newInfo = [...customInfo];
            newInfo.push({ ...DEFAULT_CUSTOM_INFO[0] });
            setCustomInfo(newInfo);
          }}
        />
      </form>
    </IntegrationsSidebar>
  );
}

export default CalendlySettingsSidebar;
