import { useMemo, useState } from 'react';
import { useFields } from '../../../../../hooks/useFields';
import { TextField } from '../../../../Core';
import { Button } from '../../../../Core/Button/button';
import { FieldReference } from '../../components/FieldReference';
import { NavBar } from '../../components/NavBar';
import { RolloutCredentialPicker } from '../../components/RolloutCredentialPicker';
import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  GUIDE_TEXT,
  MAP_RESPONSE_STRUCTURE_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../../constants';
import { TEMPLATES } from '../../templates';
import styles from '../styles.module.scss';
import { PageProps } from '../types';
import { getUsedAppTokens, INTEGRATION_TOKENS, validateState } from '../utils';

/**
 * Renders the API connector creation flow dynamically based on the
 * configuration of the selected API connector template.
 */
export const DynamicPage = (props: PageProps) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const {
    state,
    setState = () => {},
    onSubmit = () => {},
    goto = () => {},
    apiConnectors = [],
    options
  } = props;

  const template =
    TEMPLATES[state.template as Exclude<keyof typeof TEMPLATES, 'custom'>];

  const configFields = template.configuration;
  const [configState, setConfigState] = useState<any>(() =>
    template.loadConfig(state)
  );
  const handleConfigChange = (key: string, value: any) => {
    setConfigState({ ...configState, [key]: value });
  };

  const fields = useFields(true);
  const fieldKeys = useMemo(() => {
    const fieldMap = fields.reduce((acc, f) => ({ ...acc, [f.key]: true }), {});
    INTEGRATION_TOKENS.forEach((token) => (fieldMap[token] = true));
    fieldMap.feathery_user_id = true;
    fieldMap.feathery_auth_email = true;
    return fieldMap;
  }, [fields]);

  const validateConfig = (config: any, { setErrors }: any): boolean => {
    const errors: Record<string, string | Record<string, string>> = {};
    Object.keys(configFields).map((field: string) => {
      const value = config[field];
      if (!value) {
        errors[field] = `${configFields[field].label} is required`;
      }
    });
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateConfig(configState, { setErrors })) return;
    const newState = template.saveConfig(state, configState);
    if (!validateState(newState, { setErrors, apiConnectors, fieldKeys }))
      return;
    setState(newState);
    onSubmit(MAP_RESPONSE_STRUCTURE_PAGE);
  };

  const app_tokens = getUsedAppTokens(state).map((token) => token.slice(0, -6));

  const guideText: JSX.Element[] = app_tokens
    .map((token: any) => GUIDE_TEXT[token as keyof typeof GUIDE_TEXT] ?? null)
    .filter((element): element is JSX.Element => element !== null);

  return (
    <>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className='flex justify-end absolute top-[8px] right-[36px] left-0'>
            <FieldReference />
          </div>
          {guideText.length > 0 && (
            <div className={styles.field}>{guideText}</div>
          )}
          {Object.entries(configFields).map(([field, data]: any) => {
            switch (data.type) {
              case 'textfield':
                return (
                  <div key={field} className={styles.field}>
                    <div className={styles.label}>{data.label}</div>
                    <TextField
                      value={configState[field]}
                      onChange={(val: string) => {
                        handleConfigChange(field, val);
                      }}
                      error={errors[field]}
                      errorMessage={errors[field]}
                    />
                  </div>
                );
              case 'textarea':
                return (
                  <div key={field} className={styles.field}>
                    <div className={styles.label}>{data.label}</div>
                    <TextField
                      type='textarea'
                      value={configState[field]}
                      onChange={(val: string) => {
                        handleConfigChange(field, val);
                      }}
                      error={errors[field]}
                      errorMessage={errors[field]}
                    />
                  </div>
                );
              case 'rollout':
                return (
                  <div key={field} className={styles.field}>
                    <div className={styles.label}>{data.label}</div>
                    <RolloutCredentialPicker
                      app={data.rollout_app}
                      hideLabel
                      credentialKey={
                        configState[field]?.credentialKey ??
                        state.tokens?.[data.rollout_app] ??
                        null
                      }
                      onChange={(credential) => {
                        handleConfigChange(field, credential);
                      }}
                      error={!!errors[field]}
                      errorMessage={errors[field]}
                    />
                  </div>
                );
            }
          })}
          <Button
            onClick={() => {
              if (!validateConfig(configState, { setErrors })) return;
              const newState = template.saveConfig(state, configState);
              newState.template = '';
              setState(newState);
            }}
            variant='text-primary'
            className='float-right'
          >
            Switch to advanced configuration
          </Button>
        </div>
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < CONFIGURE_REQUEST_PAGE
        }
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') {
            goto(
              state.id ? SELECT_API_SERVICE_PAGE : API_CONNECTOR_TEMPLATE_PAGE
            );
          }
        }}
      />
    </>
  );
};
