import { useParams } from 'react-router-dom';
import { useGig } from '../../../context/Gig';
import CellContainer, { CellControlsType } from './components/CellContainer';
import CellElement from './components/CellElement';
import useViewport from '../../../hooks/useViewport';
import { Step, StepProps } from './components/Step';
import { Cell, Gig } from './engine';
import { useFontLoader } from '../../../hooks/useFontLoader';
import { useMemo } from 'react';

import { createContext, useContextSelector } from 'use-context-selector';
import useGigRenderController from './hooks/useRenderController';

const gigHashContext = createContext(null);

const GigHashProvider = (props: any) => <gigHashContext.Provider {...props} />;

const GridInGridContainer = ({ overrideStep, customControls }: any) => {
  const { gig } = useGig(overrideStep);

  const { viewport } = useViewport();

  // control rerenders by comparing cell hashes
  const { hashes, triggerRender } = useGigRenderController(gig);

  if ((!viewport && !overrideStep) || !gig) return null;

  const rootNode = gig.get('root') as Cell;
  const { formId } = useParams<{ formId: string }>();
  return (
    <GigHashProvider value={{ hashes, triggerRender }}>
      <GridInGrid
        controls={customControls}
        key={viewport}
        viewport={viewport}
        formId={formId}
        node={rootNode}
      />
    </GigHashProvider>
  );
};

type ContainerProps = {
  node: Cell;
  controls?: CellControlsType;
  style?: any;
};

const CellComponent = ({ node, controls, ...props }: ContainerProps) => {
  const hash = useContextSelector(
    gigHashContext,
    (value) => (value as any)?.hashes?.[node?.id]
  );

  // if renderTrigger is incremented, the cell is marked to re-render
  const renderTrigger =
    useContextSelector(
      gigHashContext,
      (value) => (value as any)?.triggerRender?.[node?.id]
    ) ?? 0;

  return useMemo(() => {
    const Component = node.isElement ? CellElement : CellContainer;

    return (
      <Component node={node} controls={controls} {...props}>
        {node.hasChildren() &&
          node.children.map((child: Cell) => (
            <CellComponent
              key={child.id}
              controls={controls}
              node={child}
              {...props}
            />
          ))}
      </Component>
    );
  }, [hash, renderTrigger, controls]);
};

type GridInGridProps = StepProps & {
  controls?: CellControlsType;
};

export const GridInGrid = ({
  node,
  controls,
  initialStyle,
  ...props
}: GridInGridProps) => {
  const isLoadingFonts = useFontLoader(node.getGig() as Gig);

  return (
    <Step
      key={isLoadingFonts ? 'loading' : 'root'} // Re-render when fonts are loaded (not doing an initial load causes flicker)
      node={node}
      initialStyle={initialStyle}
      {...props}
    >
      <CellComponent node={node} controls={controls} style={initialStyle} />
    </Step>
  );
};

export default GridInGridContainer;
