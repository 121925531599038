import React from 'react';
import { useExtractionState, useExtractionUpdater } from '../context';
import styles from './styles.module.scss';

type BackgroundControlsProps = React.PropsWithChildren;

const BackgroundControls = ({ children }: BackgroundControlsProps) => {
  const isEditing = useExtractionState((s) => s.editingValueId !== null);
  const setSelectedValueId = useExtractionUpdater((s) => s.setSelectedValueId);
  const setSelectedQuestionId = useExtractionUpdater(
    (s) => s.setSelectedQuestionId
  );

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    const classes = Array.from(e.target.classList);
    const isBackground =
      classes.includes('background-controls') ||
      classes.includes(styles.background);

    if (!isBackground) {
      return; // Do nothing
    }

    if (!isEditing) {
      e.preventDefault();
      e.stopPropagation();
      setSelectedValueId(null);
      setSelectedQuestionId(null);
    }
  };
  return (
    <div onClick={handleBackgroundClick} className={styles.background}>
      {children}
    </div>
  );
};

export default BackgroundControls;
