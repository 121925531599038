import { useAppSelector } from '../../../hooks';
import {
  DropdownField,
  FColorPicker,
  InlineTooltip,
  NumberInput,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { FONT_WEIGHT_OPTIONS } from '../../../utils/themes';
import FontPicker from '../../../components/Core/FontPicker';
import { Button } from '../../../components/Core/Button/button';

const PDFSubmissionSection = ({ title, formData, updateFormData }: any) => {
  const uploadedFonts = useAppSelector(
    (state) => state.integrations.uploadedFonts
  );

  const pdfSettings = formData.submission_pdf_settings ?? {};
  const onPDFChange = (key: string, val: any) => {
    if (pdfSettings[key] !== val) {
      updateFormData({
        submission_pdf_settings: { ...pdfSettings, [key]: val }
      });
    }
  };

  const pdfFontSettings = (label: string, key: string, defaultSize = 11) => (
    <>
      <div className='field-group'>
        <label>
          <p className='form-label'>{label} Font Family</p>
          <DropdownField
            selected={pdfSettings[`${key}_font`] ?? ''}
            onChange={(e: any) => onPDFChange(`${key}_font`, e.target.value)}
            options={[
              { value: '', display: 'Helvetica' },
              ...Object.keys(uploadedFonts).map((font) => ({
                value: font,
                display: font
              }))
            ]}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>{label} Font Size</p>
          <NumberInput
            classNames={{ root: 'form-control' }}
            min={6}
            max={64}
            value={pdfSettings[`${key}_size`] ?? defaultSize}
            onComplete={({ value }: any) => onPDFChange(`${key}_size`, value)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>{label} Font Weight</p>
          <DropdownField
            disabled={!pdfSettings[`${key}_font`]}
            selected={parseInt(pdfSettings[`${key}_weight`]).toString() ?? ''}
            onChange={(e: any) =>
              onPDFChange(`${key}_weight`, e.target.value.toString())
            }
            options={FONT_WEIGHT_OPTIONS}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>{label} Font Color</p>
          <FColorPicker
            value={pdfSettings[`${key}_color`]}
            onChange={(color: string) => onPDFChange(`${key}_color`, color)}
          />
        </label>
      </div>
    </>
  );

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Field Label Column Width
            <InlineTooltip
              text='The width (in points) of the column displaying field labels'
              inline
            />
          </p>
          <NumberInput
            classNames={{ root: 'form-control' }}
            min={10}
            max={256}
            value={pdfSettings.label_column_width ?? 150}
            onComplete={({ value }: any) =>
              onPDFChange('label_column_width', value)
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Separate Step Pages
            <InlineTooltip
              text='Each step of the form will be printed on separate PDF pages'
              inline
            />
          </p>
          <YesNoSwitch
            id='submission-pdf-separate-step-pages'
            checked={!pdfSettings.unify_steps}
            onCheckedChange={(value) => onPDFChange('unify_steps', !value)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Hide Empty Fields
            <InlineTooltip
              text='Hide empty fields on submission PDF exports'
              inline
            />
          </p>
          <YesNoSwitch
            id='submission-pdf-hide-empty-fields'
            checked={pdfSettings.hide_empty_fields ?? false}
            onCheckedChange={(value) => onPDFChange('hide_empty_fields', value)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Hide Default Footer
            <InlineTooltip
              text='Hide the default footer included on submission PDF exports'
              inline
            />
          </p>
          <YesNoSwitch
            id='submission-pdf-hide-footer'
            checked={pdfSettings.hide_footer ?? false}
            onCheckedChange={(value) => onPDFChange('hide_footer', value)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Hide Default Header
            <InlineTooltip
              text='Hide the default header included on submission PDF exports'
              inline
            />
          </p>
          <YesNoSwitch
            id='submission-pdf-hide-header'
            checked={pdfSettings.hide_header ?? false}
            onCheckedChange={(value) => onPDFChange('hide_header', value)}
          />
        </label>
      </div>
      {pdfSettings.hide_header && (
        <>
          <div className='field-group'>
            <label>
              <p className='form-label'>
                Custom Header
                <InlineTooltip
                  text='Custom text to display at the top of pages'
                  inline
                />
              </p>
              <TextField
                value={pdfSettings.custom_header ?? ''}
                onComplete={(val: string) => onPDFChange('custom_header', val)}
              />
            </label>
          </div>
          {pdfSettings.custom_header &&
            pdfFontSettings('Header', 'custom_header', 12)}
        </>
      )}
      {pdfFontSettings('Field Label', 'field_label')}
      {pdfFontSettings('Field Value', 'field_value')}
      <FontPicker CustomComponent={FontUploader} />
    </SettingsContainer>
  );
};

const FontUploader = ({ onClick }: any) => {
  return (
    <Button className='mt-2' onClick={onClick}>
      Add Custom Font
    </Button>
  );
};

export default PDFSubmissionSection;
