import { useState } from 'react';
import { OpenLinkIcon } from '../../../Icons';
import { DocumentTriggerModal, SignDocumentModal } from '../../../Modals';
import { useAppSelector } from '../../../../hooks';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '../../DropdownMenu';
import { useParams } from 'react-router-dom';
import { Tooltip } from '../../Tooltip/Tooltip';
import { cn } from '../../../../utils/cn';

export default function ShareDocumentOverlay({
  children
}: {
  unsavedDraftChanges?: boolean;
  deleteDraft?: () => void;
  children: any;
}) {
  const [showSignModal, setShowSignModal] = useState(false);
  const [showTriggerModal, setShowTriggerModal] = useState(false);

  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const isDocx = doc?.type === 'docx';

  const org = useAppSelector((state) => state.accounts.organization) ?? {};
  const { documents_over_limit: documentsOverLimit } = org;
  const documentsEnabled = org.enterprise_features.documents ?? false;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>{children}</DropdownMenuTrigger>
        <DropdownMenuContent
          unstyled
          align='end'
          className='share-modal share-document'
        >
          <div className='share-body first'>
            <div className='share-link-container'>
              {!isDocx && (
                <Tooltip
                  disabled={documentsEnabled || !documentsOverLimit}
                  content='You have exceeded the monthly document limit for your plan. Document
            signing is disabled until next month. Consider upgrading.'
                >
                  <div
                    className={cn(
                      'share-link',
                      !documentsEnabled && documentsOverLimit ? 'disabled' : ''
                    )}
                    style={{ pointerEvents: 'auto' }} // support hover
                    onClick={() => setShowSignModal(true)}
                  >
                    <OpenLinkIcon />
                    Sign Document
                  </div>
                </Tooltip>
              )}
            </div>
            <div className='share-link-container'>
              <div
                className='share-link'
                onClick={() => setShowTriggerModal(true)}
              >
                <OpenLinkIcon />
                Set Autofill Trigger
              </div>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <SignDocumentModal
        show={showSignModal}
        close={() => setShowSignModal(false)}
      />
      <DocumentTriggerModal
        show={showTriggerModal}
        close={() => setShowTriggerModal(false)}
      />
    </>
  );
}
