import { memo, useState } from 'react';

import { DynamicTextTooltip } from '../../Core';
import classNames from 'classnames';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import { useAppSelector } from '../../../hooks';
import { useNextStepDropdownOptions } from '../../../hooks/useNextStepDropdownOptions';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../Core/DropdownMenu';

const StepSelector = ({
  filterStepId = '',
  curStepId = '',
  onSelect = () => {},
  error,
  className,
  style
}: any) => {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const [selectedStepKey, setSelectedStepKey] = useState(
    workingSteps[curStepId]?.key ?? ''
  );
  let nextStepOptions = useNextStepDropdownOptions(filterStepId);
  if (nextStepOptions.length === 0)
    nextStepOptions = [{ display: 'No steps', value: '' }];

  return (
    <DropdownMenu>
      <DynamicTextTooltip text={selectedStepKey} maxLength={12}>
        <DropdownMenuTrigger
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            ruleStyles.long,
            selectedStepKey && ruleStyles.selected,
            error && ruleStyles.fieldTargetError,
            className
          )}
          style={style}
        >
          <span className={ruleStyles.fieldSpan}>
            {selectedStepKey ? `Step :: ${selectedStepKey}` : 'Step'}
          </span>
        </DropdownMenuTrigger>
      </DynamicTextTooltip>
      <DropdownMenuContent>
        {nextStepOptions.map((option) => (
          <DropdownMenuItem
            onClick={() => {
              if (option.value === '') return;
              setSelectedStepKey(workingSteps[option.value].key);
              onSelect(option.value);
            }}
            key={option.value}
          >
            {option.display}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(StepSelector);
