import { Neutral, Positive } from '../Core/Button';
import { memo, useState } from 'react';
import styles from './styles.module.scss';

import Dialog from '../Dialog';
import { CheckboxField } from '../Core';

export function BulkAddOptionsDisplay({
  initOptions = [],
  setOptions = (options: string[], labels: (string | null)[]) => {},
  allowLabelValues = true,
  setShow = (_: boolean) => {}
}) {
  const [optionStr, setOptionStr] = useState(initOptions.join('\n'));
  const [handleLabelValues, setHandleLabelValues] = useState(false);

  return (
    <>
      <div className={styles.bulkUploadSubtitle}>
        Separate options using commas or line breaks.{' '}
        {handleLabelValues &&
          'Separate labels and values with two colons (::).'}
      </div>
      {allowLabelValues && (
        <CheckboxField
          className={styles.bulkUploadValueToggle}
          checked={handleLabelValues}
          text='Add options with separate labels and values'
          onChange={(isChecked) => setHandleLabelValues(isChecked)}
        />
      )}
      <textarea
        rows={10}
        className={styles.bulkUploadInput}
        placeholder={
          handleLabelValues
            ? 'Label1:Value1\nLabel2:Value2\nLabel3:Value3'
            : 'Add\nOptions\nLike\nThis\nOr, Like, This'
        }
        value={optionStr}
        onChange={(e) => setOptionStr(e.target.value)}
      />
      <div className='dialog-form-action text-center'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={() => {
            let options = optionStr
              .split(/[\n,]/)
              .map((option) => option.trim())
              .filter((o) => o);
            const labels = options.map((opt) => {
              if (!handleLabelValues) return null;
              const parts = opt.split('::');
              return parts.length > 1 ? parts[0] : null;
            });
            if (handleLabelValues)
              options = options.map((opt) => {
                const parts = opt.split('::');
                return parts.length > 1 ? parts[1] : opt;
              });
            setOptions(options, labels);
            setShow(false);
          }}
        >
          Apply
        </Positive>
      </div>
    </>
  );
}

const BulkAddOptionsTrigger = ({
  initOptions = [],
  setOptions = (options: string[], labels: (string | null)[]) => {},
  allowLabelValues = true,
  disabled = false
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='Bulk Add Options'
      >
        <BulkAddOptionsDisplay
          initOptions={initOptions}
          setOptions={setOptions}
          allowLabelValues={allowLabelValues}
          setShow={setShow}
        />
      </Dialog>
      <div
        className={styles.bulkUploadTrigger}
        onClick={() => !disabled && setShow(true)}
      >
        Bulk Add
      </div>
    </>
  );
};

export default memo(BulkAddOptionsTrigger);
