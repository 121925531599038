import { ComponentProps } from 'react';
import { cn } from '../../../utils/cn';

export default function Row({
  children,
  className,
  ...rest
}: ComponentProps<'div'>) {
  return (
    <div className={cn('flex flex-wrap row', className)} {...rest}>
      {children}
    </div>
  );
}
