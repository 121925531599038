import { memo, useState } from 'react';

import Dialog from '../Dialog';
import { Neutral, Positive } from '../Core/Button';
import Label from '../Dialog/Label';
import { DropdownField, InlineTooltip, TextField } from '../Core';
import useElementRefCleanup from '../../utils/useElementRefCleanup';
import useFeatheryRedux from '../../redux';
import { RESERVED_FEATHERY_PREFIX } from '../../utils/constants';
import styles from './EditTableViewModal/styles.module.scss';
import { TrashIcon } from '../../components/Icons';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../hooks';
import { useParams } from 'react-router-dom';
import { Button } from '../Core/Button/button';

const IN_USE_ERROR = 'ID is already in use. Enter a unique ID.';
const MUST_ENTER_ERROR = 'You must enter a unique ID.';
const RESERVED_ERROR = `Fields prefixed with '${RESERVED_FEATHERY_PREFIX}' are reserved and may not be created/edited/deleted.`;

function EditHiddenFieldModal({ field, steps, allFields, close }: any) {
  const { isFieldReferenced } = useElementRefCleanup();
  const { formId } = useParams<{ formId: string }>();
  const isAutofillPluginEnabled = useAppSelector((state) => {
    return formId ? state.panels.panels[formId].plugin_autofill : false;
  });

  const org = useAppSelector((state) => state.accounts.organization);
  const isEnterprise = org?.tier >= 4;

  const [fieldName, setFieldName] = useState<string>(field.key ?? '');
  const [fieldSemanticEntity, setFieldSemanticEntity] = useState<string>(
    field.semantic_entity ?? ''
  );
  const [fieldSemanticAttribute, setFieldSemanticAttribute] = useState<string>(
    field.semantic_attribute ?? ''
  );
  const [fieldSemanticDescription, setFieldSemanticDescription] =
    useState<string>(field.semantic_description ?? '');

  const [fieldOptions, setFieldOptions] = useState<string[]>(
    field.options ?? []
  );

  const [loading, setLoading] = useState<'delete' | 'create' | 'save' | false>(
    false
  );

  const {
    createField,
    deleteField,
    editField,
    getPanels,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  function handleDelete() {
    if (!field) return;
    if (field.key.startsWith(RESERVED_FEATHERY_PREFIX)) {
      addErrorToast({ text: 'This field cannot be deleted.' });
    }
    // If hidden field referenced locally, block the delete attempt.
    else if (isFieldReferenced(field, steps)) {
      addErrorToast({ text: "You can't delete a field in use." });
    } else {
      if (loading) return;
      setLoading('delete');
      deleteField({ hidden: true, id: field.id })
        .then(() => getPanels())
        .then(() => addInfoToast('Field deleted'))
        .then(() => close())
        .catch(() => {
          addErrorToast({
            text: 'An error occured preventing this field from being deleted.'
          });
          setLoading(false);
        });
    }
  }

  function handleSave() {
    let method = 'create';
    if (field.id) {
      method = 'save';
    }

    if (!fieldName) {
      addErrorToast({ text: MUST_ENTER_ERROR });
      return;
    }
    if (fieldName.startsWith(RESERVED_FEATHERY_PREFIX)) {
      addErrorToast({ text: RESERVED_ERROR });
      return;
    }

    if (
      allFields.find(
        (f: any) =>
          f.key === fieldName && (method === 'create' || f.id !== field.id)
      )
    ) {
      addErrorToast({ text: IN_USE_ERROR });
      return;
    }

    if (loading) return;

    try {
      const fieldId = field.id ?? uuidv4();
      const filteredOptions = fieldOptions.filter(Boolean);
      if (method === 'create') {
        setLoading('create');
        createField({
          hidden: true,
          id: fieldId,
          key: fieldName,
          options: filteredOptions,
          semantic_entity: fieldSemanticEntity,
          semantic_attribute: fieldSemanticAttribute,
          semantic_description: fieldSemanticDescription
        }).then(() => {
          addInfoToast('Field created');
          close(fieldId);
        });
      } else {
        setLoading('save');
        editField({
          hidden: true,
          id: fieldId,
          key: fieldName,
          options: filteredOptions,
          semantic_entity: fieldSemanticEntity,
          semantic_attribute: fieldSemanticAttribute,
          semantic_description: fieldSemanticDescription
        }).then(() => {
          addInfoToast('Field saved');
          close(fieldId);
        });
      }
    } catch (e) {
      addErrorToast({ text: (e as any).message });
      setLoading(false);
    }
  }

  return (
    <Dialog
      isOpen
      onClose={() => close()}
      shouldShowCloseIcon
      title={field.id ? 'Edit Hidden Field' : 'Create Hidden Field'}
    >
      <div className='h-px bg-gray-300 -mx-[40px] mt-6' />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          paddingBlockStart: 16,
          paddingBlockEnd: 24
        }}
      >
        <label className='fieldGroup'>
          <Label className='text-grey-800'>Name</Label>
          <TextField
            style={{ height: 36 }}
            value={fieldName}
            placeholder='Enter field ID'
            onChange={setFieldName}
          />
        </label>

        <div className='fieldGroup'>
          <Label className='text-grey-800'>Options</Label>
          {fieldOptions.map((option, index) => {
            return (
              <label className={styles.columnRow} key={index}>
                <Label className={styles.columnLabel + ' text-base'}>
                  Option {index + 1}
                </Label>
                <TextField
                  className={styles.columnDropdown}
                  value={option}
                  onChange={(val: string) => {
                    const newOptions: string[] = JSON.parse(
                      JSON.stringify(fieldOptions)
                    );
                    newOptions[index] = val;
                    setFieldOptions(newOptions);
                  }}
                />
                <TrashIcon
                  className={styles.trashIcon}
                  height={16}
                  width={16}
                  onClick={() => {
                    const newOptions = produce(fieldOptions, (draft) => {
                      draft.splice(index, 1);
                    });
                    setFieldOptions(newOptions);
                  }}
                />
              </label>
            );
          })}
          <Neutral
            disabled={Boolean(loading)}
            onClick={() => {
              const newOptions = produce(fieldOptions, (draft) => {
                draft.push('');
              });
              setFieldOptions(newOptions);
            }}
            style={{
              margin: 0,
              height: 35,
              paddingBlock: 0,
              display: 'flex',
              alignItems: 'center',
              borderStyle: 'dashed !important',
              boxShadow: 'none',
              background: 'hsla(220, 17%, 96%, 1)'
            }}
          >
            +&nbsp;&nbsp;Add option
          </Neutral>
        </div>
        {isEnterprise && (
          <div className='fieldGroup space-y-3'>
            <Label className='text-grey-800'>
              Semantic Meaning
              <InlineTooltip
                style={{ display: 'inline', paddingLeft: 0, marginLeft: 5 }}
                text='Associate this field with meaning to tie user data together across systems'
              />
            </Label>
            <label className='grid grid-cols-[1fr_2fr]'>
              <Label className='text-base'>
                Entity
                <InlineTooltip
                  style={{ display: 'inline', paddingLeft: 0, marginLeft: 5 }}
                  text='The object that this field describes'
                />
              </Label>
              <DropdownField
                className={styles.columnDropdown}
                selected={fieldSemanticEntity}
                onChange={(e: any) => setFieldSemanticEntity(e.target.value)}
                options={[
                  { value: '', display: 'None' },
                  { value: 'applicant', display: 'Form Applicant' }
                ]}
              />
            </label>
            <label className='grid grid-cols-[1fr_2fr]'>
              <Label className='text-base'>
                Attribute
                <InlineTooltip
                  style={{ display: 'inline', paddingLeft: 0, marginLeft: 5 }}
                  text='The attribute that this field describes'
                />
              </Label>
              <DropdownField
                className={styles.columnDropdown}
                selected={fieldSemanticAttribute}
                onChange={(e: any) => setFieldSemanticAttribute(e.target.value)}
                options={[
                  { value: '', display: 'None' },
                  { value: 'first_name', display: 'First Name' },
                  { value: 'last_name', display: 'Last Name' },
                  { value: 'email', display: 'Email' },
                  { value: 'phone', display: 'Phone' }
                ]}
              />
            </label>
            {isAutofillPluginEnabled && (
              <label className='grid grid-cols-[1fr_2fr]'>
                <Label className='text-base'>
                  Description
                  <InlineTooltip
                    style={{ display: 'inline', paddingLeft: 0, marginLeft: 5 }}
                    text='The semantic meaning of this field. Required when using the Feathery form autofill plugin.'
                  />
                </Label>
                <TextField
                  className={styles.columnDropdown}
                  type='textarea'
                  rows={4}
                  value={fieldSemanticDescription}
                  onComplete={(val: string) => {
                    setFieldSemanticDescription(val);
                  }}
                  triggerCleanUp
                />
              </label>
            )}
          </div>
        )}
      </div>

      <div className='h-px bg-gray-300 -mx-[40px]' />
      <div className='flex justify-between items-center pt-[24px] flex-row-reverse'>
        <Positive disabled={Boolean(loading)} onClick={handleSave}>
          {loading === 'save'
            ? 'Saving...'
            : loading === 'create'
            ? 'Creating...'
            : field.id
            ? 'Save'
            : 'Create'}
        </Positive>
        {field.id && (
          <Button
            variant='destructive'
            disabled={Boolean(loading)}
            onClick={handleDelete}
          >
            {loading === 'delete' ? 'Deleting...' : 'Delete field'}
          </Button>
        )}
      </div>
    </Dialog>
  );
}

export default memo(EditHiddenFieldModal);
