import { memo } from 'react';
import {
  CollapsibleSection,
  FColorPicker,
  NumberInput,
  PropertyLabel
} from '../../../Core';
import Row from '../../../Core/Layout/Row';
import Col from '../../../Core/Layout/Col';

function ShadowStyleSection({
  labelData = () => {},
  styles,
  handleStyleChange
}: any) {
  return (
    <CollapsibleSection title='Shadow' collapsible>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='X Offset' {...labelData('shadow_x_offset')} />
        </Col>
        <Col>
          <NumberInput
            required
            min={0}
            value={styles.shadow_x_offset}
            onComplete={({ value }: any) =>
              handleStyleChange({ shadow_x_offset: value })
            }
            units={['px']}
            triggerCleanUp
          />
        </Col>
        <Col sm='3'>
          <PropertyLabel
            label='Y Offset'
            indented
            {...labelData('shadow_y_offset')}
          />
        </Col>
        <Col>
          <NumberInput
            required
            min={0}
            value={styles.shadow_y_offset}
            onComplete={({ value }: any) =>
              handleStyleChange({ shadow_y_offset: value })
            }
            units={['px']}
            triggerCleanUp
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Length' {...labelData('shadow_blur_radius')} />
        </Col>
        <Col>
          <NumberInput
            required
            min={0}
            value={styles.shadow_blur_radius}
            onComplete={({ value }: any) =>
              handleStyleChange({ shadow_blur_radius: value })
            }
            units={['px']}
            triggerCleanUp
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Shadow' {...labelData('shadow_color')} />
        </Col>
        <Col>
          <FColorPicker
            value={styles.shadow_color}
            onChange={(color: any) =>
              handleStyleChange({ shadow_color: color })
            }
          />
        </Col>
      </Row>
    </CollapsibleSection>
  );
}

export default memo(ShadowStyleSection);
