import { memo } from 'react';
import { ComputerIllustration } from '../../components/Assets/ComputerIllustration';
import styles from './styles.module.scss';
import { Button } from '../../components/Core/Button/button';
import { useAppSelector } from '../../hooks';

const MobileGatePage = () => {
  const org = useAppSelector((state) => state.accounts.organization);

  const handleClick = () => {
    window.location.href = org?.whitelabel?.brand_url || 'https://feathery.io';
  };

  return (
    <div className={styles.mobileGatePage}>
      <div className={styles.mobileGatePageContainer}>
        <div>
          <ComputerIllustration />
        </div>
        <div className={styles.mobileGatePageContent}>
          <h1>Please access from your desktop</h1>
          <p>
            Enjoy all the features Feathery has to offer when you use a larger
            screen.
          </p>
          <Button variant='light-primary' onClick={handleClick}>
            Return to Feathery.io
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(MobileGatePage);
