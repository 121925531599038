import '../../style/dialog-form.css';

import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { PropertyLabel } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { FieldSelectorWithModal } from '../Modals';
import styles from './styles.module.scss';

function TrustedFormSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.TRUSTEDFORM,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [certificateField, setCertificateField] = useState(
    integration?.data.metadata.certificate_field ?? ''
  );
  const [pingField, setPingField] = useState(
    integration?.data.metadata.ping_field ?? ''
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !certificateField || !pingField;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      certificate_field: certificateField,
      ping_field: pingField
    };
    return {
      isUpdate: integration?.data,
      metadata
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.TRUSTEDFORM]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Certificate Field' />
          <FieldSelectorWithModal
            selectId={certificateField}
            onlyHiddenFields
            placeholder='Select'
            onSelect={(data) => setCertificateField(data.selectId)}
            className={styles.marginBottom}
          />
        </div>
        <div>
          <PropertyLabel label='Ping Field' />
          <FieldSelectorWithModal
            selectId={pingField}
            onlyHiddenFields
            placeholder='Select'
            onSelect={(data) => setPingField(data.selectId)}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default TrustedFormSettingsSidebar;
