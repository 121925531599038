import '../../style/navigation.scss';

import { useState } from 'react';

import { DynamicTextTooltip } from '../Core';
import { Link, useParams } from 'react-router-dom';
import { PublishThemeModal } from '../Modals';
import { useAppSelector } from '../../hooks';
import { BackArrowIcon } from '../Icons';
import { PublishButton } from '../Core/Button';

const MAX_THEME_LABEL_LENGTH = 14;

export default function ThemeNavigation({
  activeItem,
  onPublish = () => {},
  style,
  ...publishProps
}: any) {
  const { themeId } = useParams<{ themeId: string }>();
  const theme = useAppSelector((state) => state.themes.themes[themeId]);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const panels = useAppSelector((state) => state.panels.panels);
  const listOfAssociatedForms = Object.values(panels)?.filter(
    (panel) => (panel as any).theme === themeId
  );

  let themeLabel = theme?.key;
  if (theme?.key.length > MAX_THEME_LABEL_LENGTH) {
    themeLabel = theme.key.slice(0, MAX_THEME_LABEL_LENGTH) + '...';
  }

  return (
    <nav className='main-nav' style={style}>
      <div className='left-nav'>
        <div className='left-nav-header'>
          <Link to='/themes/' className='back-container'>
            <BackArrowIcon />
          </Link>
          <DynamicTextTooltip
            text={theme?.key}
            maxLength={MAX_THEME_LABEL_LENGTH}
            placement='bottom'
          >
            <span>{themeLabel}</span>
          </DynamicTextTooltip>
        </div>
        <Link
          className={activeItem === 'builder' ? 'active' : undefined}
          to={`/themes/${themeId}/`}
        >
          Styles
        </Link>
        <Link
          className={activeItem === 'settings' ? 'active' : undefined}
          to={`/themes/${themeId}/settings/`}
        >
          Settings
        </Link>
      </div>
      <PublishThemeModal
        show={showCustomModal}
        setShow={(show: any) => setShowCustomModal(show)}
        onPublish={onPublish}
        themeId={themeId}
        panels={panels}
      />
      <div className='right-sub-nav ml-auto justify-right'>
        {activeItem === 'builder' && (
          <PublishButton
            {...publishProps}
            onPublish={() => {
              listOfAssociatedForms.length
                ? setShowCustomModal(true)
                : onPublish();
            }}
          />
        )}
        {activeItem === 'settings' && (
          <PublishButton {...publishProps} onPublish={onPublish} />
        )}
      </div>
    </nav>
  );
}
