import classNames from 'classnames';
import { memo, useMemo } from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './styles.module.scss';

const getClassNames = (overrides: any = {}) => {
  const cn = classNames;
  const o = overrides;

  return {
    className: cn(styles.multiFieldDropdown, o.className),
    control: (state: any) =>
      cn(
        styles.multiFieldControl,
        {
          [styles.multiFieldControlHovered]: state.isHovered,
          [styles.multiFieldControlFocused]: state.isFocused
        },
        typeof o.multiFieldControl === 'function'
          ? o.multiFieldControl(state)
          : o.multiFieldControl
      ),
    container: () => cn(styles.multiFieldContainer, o.multiFieldContainer),
    valueContainer: () =>
      cn(styles.multiFieldValueContainer, o.multiFieldValueContainer),
    multiValue: () => cn(styles.multiFieldMultiValue, o.multiFieldMultiValue),
    multiValueLabel: () =>
      cn(styles.multiFieldMultiValueLabel, o.multiFieldMultiValueLabel),
    multiValueRemove: (state: any) =>
      cn(
        styles.multiFieldMultiValueRemove,
        {
          [styles.multiFieldMultiValueRemoveHovered]: state.isHovered
        },
        o.multiFieldMultiValueRemove
      ),
    menu: () => cn(styles.multiFieldMenu, o.multiFieldMenu),
    menuList: () => cn(styles.multiFieldMenuList, o.multiFieldMenuList),
    clearIndicator: () =>
      cn(styles.multiFieldClearIndicator, o.multiFieldClearIndicator),
    indicatorSeparator: () =>
      cn(styles.multiFieldIndicatorSeparator, o.multiFieldIndicatorSeparator),
    indicatorsContainer: () =>
      cn(styles.multiFieldIndicatorsContainer, o.multiFieldIndicatorsContainer),
    dropdownIndicator: () =>
      cn(styles.multiFieldDropdownIndicator, o.multiFieldDropdownIndicator),
    inputContainer: () => cn(o.multiFieldInputContainer),
    input: () => cn(o.multiFieldInput),
    placeholder: () => cn(o.multiFieldPlaceholder)
  };
};

const DropdownMultiField = ({
  selected = [],
  options = [],
  placeholder,
  required = false,
  disabled = false,
  hideCaret = false,
  isMulti = true,
  onChange = () => {},
  creatable = false,
  classNames: _classNames = {},
  className: _className = '',
  ...props
}: any) => {
  const [flatOptions, groupedOptions] = useMemo(() => {
    const flatOptions = options.map((o: any) =>
      typeof o === 'object' ? o : { value: o, label: o }
    );
    let groupedOptions = flatOptions;
    if (flatOptions[0]?.group) {
      const groupOrder: string[] = [];
      const groups: Record<string, any> = {};
      flatOptions.forEach((option: any) => {
        if (!groups[option.group]) {
          groups[option.group] = [];
          groupOrder.push(option.group);
        }
        groups[option.group].push(option);
      });
      groupedOptions = groupOrder.map((group) => ({
        label: group,
        options: groups[group]
      }));
    }
    return [flatOptions, groupedOptions];
  }, [options]);

  // SVG component for the custom dropdown indicator
  const CustomDropdownIndicator = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 6'
      width='10px'
      height='6px'
      style={{ marginRight: '10px' }}
    >
      <path
        d='M0 0.776454L0.970744 0L5 4.2094L9.02926 0L10 0.776454L5 6L0 0.776454Z'
        fill='black'
      />
    </svg>
  );

  // Custom components for React Select
  const customComponents: Record<string, any> = {};
  if (!hideCaret) customComponents.DropdownIndicator = CustomDropdownIndicator;

  const { className = '', ...classNameOverrides } = getClassNames({
    ...(_classNames || {}),
    className: _className ?? ''
  });

  let value;
  if (flatOptions.length) {
    if (isMulti) {
      value = flatOptions.filter((o: any) => selected.includes(o.value));
    } else value = flatOptions.filter((o: any) => o.value === selected);
  } else if (isMulti) {
    value = selected.map((s: string) => ({ value: s, label: s }));
  } else value = [{ value: selected, label: selected }];

  const selectProps = {
    classNamePrefix: 'multi-field',
    className,
    classNames: classNameOverrides,
    styles: {
      dropdownIndicator: (baseStyles: any) => ({
        ...baseStyles,
        display: hideCaret ? 'none' : 'block'
      }),
      menuPortal: (baseStyles: any) => ({
        ...baseStyles,
        zIndex: 99999999
      })
    },
    value,
    required,
    isDisabled: disabled,
    onChange,
    options: groupedOptions,
    isMulti,
    placeholder,
    components: customComponents,
    menuPortalTarget: document.body,
    ...props
  };

  // Ensure the ordering of the selected values remains the same after filtering the options
  if (flatOptions.length && isMulti) {
    selectProps.value = selected.map((s: string) =>
      selectProps.value.find((v: any) => v.value === s)
    );
  }

  return creatable ? (
    <CreatableSelect {...selectProps} />
  ) : (
    <Select {...selectProps} />
  );
};

export default memo(DropdownMultiField);
