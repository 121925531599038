import type { ComponentProps } from 'react';
import { BackArrowIcon } from '../../Icons';
import { Button } from './button';

function Back({ ...rest }: Omit<ComponentProps<typeof Button>, 'children'>) {
  return (
    <Button
      {...rest}
      variant='outline'
      className='hover:bg-primary hover:text-white hover:border-primary'
    >
      <BackArrowIcon />
    </Button>
  );
}

export default Back;
