import { memo, useEffect, useRef } from 'react';
import {
  useExtractionState,
  useExtractionUpdater,
  usePDFState
} from '../../context';
import { AIExtractionEntryValue } from '../../types';
import { getValueId } from '../../utils';
import { X_SCALE } from './constants';
import AIFieldPopover from '../AIFieldPopover';
import classNames from 'classnames';
import styles from '../styles.module.scss';

type Props = React.PropsWithChildren<{
  entry: AIExtractionEntryValue;
  absolute?: boolean;
}>;

const DataLayerEntry = ({ entry, absolute = true, children }: Props) => {
  const valueId = getValueId(entry.id, entry.index);

  const zoom = usePDFState((s) => s.zoom);
  const setSelectedValueId = useExtractionUpdater((s) => s.setSelectedValueId);
  const setHoveredValueId = useExtractionUpdater((s) => s.setHoveredValueId);
  const entryRef = useRef<HTMLDivElement>(null);

  const isSelected = useExtractionState((s) => s.selectedValueId === valueId);
  const isSelectedFromPanel = useExtractionState(
    (s) => s.selectedValueId === valueId && s.activeComponent === 'panel'
  );

  const isAIGenerated = entry.source
    ? entry.source === 'email' || entry.source === 'document'
    : entry.xCoordinate >= 0;
  const hasBoundingBox = entry.xCoordinate >= 0;
  const isHovered = useExtractionState((s) => s.hoveredValueId === valueId);
  const isSecondaryHighlight = useExtractionState((s) => {
    return s.selectedValueId && entry.relatedEntries?.[s.selectedValueId];
  });

  const isHidden = useExtractionState((s) => {
    return (
      (s.selectedQuestionId &&
        s.selectedQuestionId !== entry.questionGroupId) ||
      (!!s.selectedValueId &&
        s.selectedValueId !== valueId &&
        !entry.relatedEntries?.[s.selectedValueId] &&
        (!s.hoveredValueId || s.hoveredValueId === s.selectedValueId))
    );
  });

  const isGrey = useExtractionState((s) => {
    return s.selectedValueId && s.hoveredValueId
      ? s.hoveredValueId !== valueId
      : s.hoveredValueId !== valueId && s.selectedValueId !== valueId;
  });

  useEffect(() => {
    if (isSelectedFromPanel) {
      entryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelectedFromPanel]);

  if (!entry.value || !isAIGenerated || !hasBoundingBox) {
    return null;
  }

  const positionStyles = absolute
    ? {
        top: entry.yCoordinate * X_SCALE * zoom,
        left: entry.xCoordinate * X_SCALE * zoom,
        width: entry.width * X_SCALE * zoom + 2,
        height: entry.height * X_SCALE * zoom + 2
      }
    : {
        display: 'inline-block',
        width: 'fit-content',
        height: 'fit-content'
      };

  return (
    <div
      key={`${entry.id}-${entry.index}`}
      ref={entryRef}
      className={classNames(styles.extractionEntry, {
        [styles.hovered]: isHovered,
        [styles.selected]: isSelected || isSecondaryHighlight,
        [styles.grey]: isGrey,
        [styles.hide]: isHidden
      })}
      style={positionStyles}
      onMouseEnter={() =>
        !isHidden &&
        !isSecondaryHighlight &&
        !isHovered &&
        setHoveredValueId(valueId, 'canvas')
      }
      onMouseLeave={() =>
        !isHidden && !isSecondaryHighlight && setHoveredValueId(null)
      }
      onClick={() =>
        !isHidden && setSelectedValueId(isSelected ? null : valueId, 'canvas')
      }
    >
      <AIFieldPopover
        key={`${entry.id}-popover`}
        entryValue={entry}
        isHidden={isHidden}
      >
        {children}
      </AIFieldPopover>
    </div>
  );
};

export default memo(DataLayerEntry);
