/* eslint-disable react-hooks/exhaustive-deps */

import { DropdownField, FColorPicker, NumberInput, PropertyLabel } from '..';
import { memo, useEffect, useMemo, useState } from 'react';

import { stopReactEventPropagation } from '../util';
import styles from './styles.module.scss';

const BORDERS = { T: 'top', R: 'right', B: 'bottom', L: 'left' };
const NONE_FOCUSED = {
  [BORDERS.T]: false,
  [BORDERS.R]: false,
  [BORDERS.B]: false,
  [BORDERS.L]: false
};

/**
 * Returns the value for an input based on which borders are focused.
 * If all values are the same, show that. If they are different, show empty string.
 */
function getCombinedValue(focused: any, values: any) {
  // If no corners are focused, we consider that to mean all corners are focused
  const relevant =
    JSON.stringify(NONE_FOCUSED) === JSON.stringify(focused)
      ? Object.values(values)
      : [
          ...(focused[BORDERS.T] ? [values[BORDERS.T]] : []),
          ...(focused[BORDERS.R] ? [values[BORDERS.R]] : []),
          ...(focused[BORDERS.B] ? [values[BORDERS.B]] : []),
          ...(focused[BORDERS.L] ? [values[BORDERS.L]] : [])
        ];
  const isMixed =
    relevant.length === 0 || relevant.some((c) => c !== relevant[0]);

  return isMixed ? '' : relevant[0];
}

function setCombinedValue(focused: any, values: any, value: any) {
  return JSON.stringify(NONE_FOCUSED) === JSON.stringify(focused)
    ? {
        [BORDERS.T]: value,
        [BORDERS.R]: value,
        [BORDERS.B]: value,
        [BORDERS.L]: value
      }
    : {
        [BORDERS.T]: focused[BORDERS.T] ? value : values[BORDERS.T],
        [BORDERS.R]: focused[BORDERS.R] ? value : values[BORDERS.R],
        [BORDERS.B]: focused[BORDERS.B] ? value : values[BORDERS.B],
        [BORDERS.L]: focused[BORDERS.L] ? value : values[BORDERS.L]
      };
}

function BorderStyleInput({
  elementStyles,
  prefix = '',
  onComplete: customOnComplete
}: any) {
  const addPre = (prop: string) => `${prefix}${prop}`;

  const initColors = {
    top: elementStyles[addPre('border_top_color')],
    right: elementStyles[addPre('border_right_color')],
    bottom: elementStyles[addPre('border_bottom_color')],
    left: elementStyles[addPre('border_left_color')]
  };
  const initWidths = {
    top: elementStyles[addPre('border_top_width')],
    right: elementStyles[addPre('border_right_width')],
    bottom: elementStyles[addPre('border_bottom_width')],
    left: elementStyles[addPre('border_left_width')]
  };
  const initPatterns = {
    top: elementStyles[addPre('border_top_pattern')],
    right: elementStyles[addPre('border_right_pattern')],
    bottom: elementStyles[addPre('border_bottom_pattern')],
    left: elementStyles[addPre('border_left_pattern')]
  };

  const [focused, setFocused] = useState(NONE_FOCUSED);
  const [colors, setColors] = useState<any>(initColors);
  const [widths, setWidths] = useState<any>(initWidths);
  const [patterns, setPatterns] = useState<any>(initPatterns);

  // Update combined color, width, or pattern when the focus or property changes
  const combinedColor = useMemo(() => {
    return getCombinedValue(focused, colors);
  }, [focused, colors]);
  const combinedWidth = useMemo(
    () => getCombinedValue(focused, widths),
    [focused, widths]
  );
  const combinedPattern = useMemo(
    () => getCombinedValue(focused, patterns),
    [focused, patterns]
  );

  const colorKey = useMemo(() => Math.random(), [focused]);
  const widthKey = useMemo(() => Math.random(), [focused]);
  const patternKey = useMemo(() => Math.random(), [focused]);

  useEffect(() => {
    setColors(initColors);
  }, [initColors.top, initColors.bottom, initColors.left, initColors.right]);
  useEffect(
    () => setWidths(initWidths),
    [initWidths.top, initWidths.bottom, initWidths.left, initWidths.right]
  );
  useEffect(
    () => setPatterns(initPatterns),
    [
      initPatterns.top,
      initPatterns.bottom,
      initPatterns.left,
      initPatterns.right
    ]
  );

  // On mousedown anywhere, unfocus all borders
  useEffect(() => {
    const unfocusAll = () => setFocused(NONE_FOCUSED);
    document.addEventListener('mousedown', unfocusAll);
    return () => document.removeEventListener('mousedown', unfocusAll);
  }, []);

  function onBorderMouseDown(position: any) {
    return (event: any) => {
      // Stop propagation so the useEffect that unfocuses all borders isn't triggered
      stopReactEventPropagation(event);
      setFocused({ ...focused, [position]: !focused[position] });
    };
  }

  function onComplete({
    newColors = colors,
    newWidths = widths,
    newPatterns = patterns
  } = {}) {
    customOnComplete({
      [addPre('border_top_color')]: newColors.top,
      [addPre('border_right_color')]: newColors.right,
      [addPre('border_bottom_color')]: newColors.bottom,
      [addPre('border_left_color')]: newColors.left,
      [addPre('border_top_width')]: newWidths.top,
      [addPre('border_right_width')]: newWidths.right,
      [addPre('border_bottom_width')]: newWidths.bottom,
      [addPre('border_left_width')]: newWidths.left,
      [addPre('border_top_pattern')]: newPatterns.top,
      [addPre('border_right_pattern')]: newPatterns.right,
      [addPre('border_bottom_pattern')]: newPatterns.bottom,
      [addPre('border_left_pattern')]: newPatterns.left
    });
  }

  return (
    <div className={styles.borderInputGrid}>
      <div className={styles.borderInputPreview}>
        {Object.values(BORDERS).map((b) => {
          const border = `${widths[b]}px ${patterns[b]} #${colors[b]}`;

          return (
            <div
              key={`${b}: ${border}`}
              className={`${styles.border} ${focused[b] ? styles.focused : ''}`}
              onMouseDown={onBorderMouseDown(b)}
            >
              <div
                style={{
                  height: [BORDERS.T, BORDERS.B].includes(b) ? '0px' : '100%',
                  width: [BORDERS.R, BORDERS.L].includes(b) ? '0px' : '100%',
                  borderTop: b === BORDERS.T ? border : 'none',
                  borderRight: b === BORDERS.R ? border : 'none',
                  borderBottom: b === BORDERS.B ? border : 'none',
                  borderLeft: b === BORDERS.L ? border : 'none'
                }}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.borderInputValueLabel}>
        <PropertyLabel label='Color' />
      </div>
      <div className={styles.borderInputValueLabel}>
        <PropertyLabel label='Width' />
      </div>
      <div className={styles.borderInputValueLabel}>
        <PropertyLabel label='Pattern' />
      </div>
      <div
        className={styles.borderInputValue}
        onMouseDown={stopReactEventPropagation}
      >
        <FColorPicker
          key={colorKey}
          value={combinedColor}
          onChange={(color: any) => {
            if (color !== '') {
              const newColors = setCombinedValue(focused, colors, color);
              setColors(newColors);
              onComplete({ newColors });
            }
          }}
          horizontal='right'
        />
      </div>
      <div
        className={styles.borderInputValue}
        onMouseDown={stopReactEventPropagation}
      >
        <NumberInput
          key={widthKey}
          min={0}
          value={combinedWidth}
          onChange={(newWidth: any, mounted: boolean) => {
            const newWidths = setCombinedValue(focused, widths, newWidth);
            onComplete({ newWidths });
            if (mounted) setWidths(newWidths);
          }}
          units={['px']}
          triggerCleanUp
        />
      </div>
      <div
        className={styles.borderInputValue}
        onMouseDown={stopReactEventPropagation}
      >
        <DropdownField
          key={patternKey}
          selected={combinedPattern as string}
          onChange={(event: any) => {
            const pattern = event.target.value;
            const newPatterns = setCombinedValue(focused, patterns, pattern);
            setPatterns(newPatterns);
            onComplete({ newPatterns });
          }}
          options={[
            { value: 'solid', display: 'Solid' },
            { value: 'dashed', display: 'Dashed' },
            { value: 'dotted', display: 'Dotted' }
          ]}
        />
      </div>
    </div>
  );
}

export default memo(BorderStyleInput);
