import { Feathery } from '../../Logos';
import { useAppSelector } from '../../../hooks';

export function Logo() {
  const org = useAppSelector((state) => state.accounts.organization);
  const name = org?.whitelabel?.brand_name || 'Feathery';
  const logo = org?.whitelabel?.logo ? (
    <img
      alt='logo'
      src={org.whitelabel.logo}
      style={{
        width: '24px',
        height: '36px',
        objectFit: 'contain',
        marginRight: '10px'
      }}
    />
  ) : (
    <Feathery width={24} height={36} />
  );

  return (
    <>
      {logo} {name}
    </>
  );
}
