import { APPS } from '../templates';
import { useAppSelector } from '../../../../hooks';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

export function TemplateButton(props: any) {
  const org = useAppSelector((state) => state.accounts.organization);

  const appConfig = APPS[props.app as keyof typeof APPS];
  if (!appConfig) return <></>;

  if (
    appConfig.premiumIntegration &&
    !org?.enterprise_features[appConfig.premiumIntegration]
  ) {
    return null;
  }

  if (appConfig.type === 'rollout') {
    return <RolloutTemplateButton {...props} />;
  }

  return <IntegrationTemplateButton {...props} />;
}

function IntegrationTemplateButton({
  app,
  onClick,
  selected,
  enabledIntegrations
}: any) {
  const appConfig = APPS[app as keyof typeof APPS];
  if (!appConfig) return <></>;
  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);
  // TODO: remove always-enabled temenos mock integration
  const enabled =
    app === 'custom' || enabledIntegrations.includes(app) || app === 'temenos';

  if (!enabled) {
    return (
      <div className='w-1/2 p-2 order-2'>
        <Tooltip
          content={`Set up a ${appDisplay} integration to use the ${appDisplay} API.`}
        >
          <button
            disabled
            onClick={onClick}
            className='w-full h-24 block p-6 bg-white !border !border-solid !border-gray-200 rounded-lg enabled:hover:bg-gray-100 flex items-center justify-center gap-6 cursor-pointer data-[selected]:!border-primary data-[selected]:!border-2 data-[selected]:!bg-primary-light/20 disabled:bg-gray-100/50 disabled:cursor-auto disabled:text-gray-600 disabled:opacity-70'
            data-selected={selected || undefined}
          >
            {appConfig.logo}
            {appConfig.header}
          </button>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className='w-1/2 p-2 order-1'>
      <button
        onClick={onClick}
        className='w-full h-24 block p-6 bg-white !border !border-solid !border-gray-200 rounded-lg enabled:hover:bg-gray-100 flex items-center justify-center gap-6 cursor-pointer data-[selected]:!border-primary data-[selected]:!border-2 data-[selected]:!bg-primary-light/20 disabled:bg-gray-100/50 disabled:cursor-auto disabled:text-gray-600 disabled:opacity-70'
        data-selected={selected || undefined}
      >
        {appConfig.logo}
        {appConfig.header}
      </button>
    </div>
  );
}

function RolloutTemplateButton({ app, onClick, selected }: any) {
  const appConfig = APPS[app as keyof typeof APPS];
  return (
    <div className='w-1/2 p-2 order-1'>
      <button
        onClick={onClick}
        className='w-full h-24 block p-6 bg-white !border !border-solid !border-gray-200 rounded-lg enabled:hover:bg-gray-100 flex items-center justify-center gap-6 cursor-pointer data-[selected]:!border-primary data-[selected]:!border-2 data-[selected]:!bg-primary-light/20 disabled:bg-gray-100/50 disabled:cursor-auto disabled:text-gray-600 disabled:opacity-70'
        data-selected={selected || undefined}
      >
        {appConfig.logo}
        {appConfig.header}
      </button>
    </div>
  );
}
