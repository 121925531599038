import { useMemo, useState } from 'react';
import { AIExtractionEntry, AIExtractionRun, AIQuestionGroup } from '../types';
import {
  AIIcon,
  CheckCircleIcon,
  CodeIcon,
  SettingsIcon,
  ShowIcon
} from '../../../components/Icons';
import { useHistory, useParams } from 'react-router-dom';
import { getAllEntries } from '../utils';
import { Button } from '../../../components/Core';
import { Button as ButtonComponent } from '../../../components/Core/Button/button';
import { useAppSelector } from '../../../hooks';
import { useExtractionState, useExtractionUpdater } from '../context';
import useFeatheryRedux from '../../../redux';
import useField from '../../../utils/useField';
import AIAddValueModal, { AddValuePayload } from './AIAddValueModal';
import classNames from 'classnames';
import AIEntry from './AIEntry';
import styles from './styles.module.scss';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';

type URLParams = {
  formId: string;
  extractionId: string;
  runId: string;
};

type Props = {
  run: AIExtractionRun;
};

const AIExtractionPanel = ({ run }: Props) => {
  const history = useHistory();
  const getField = useField();

  const { runId, extractionId } = useParams<URLParams>();
  const [showAddValueModal, setShowAddValueModal] = useState(false);
  const [targetEntries, setTargetEntries] = useState<AIExtractionEntry[]>([]);

  const selectedQuestionId = useExtractionState((s) => s.selectedQuestionId);
  const setSelectedQuestionId = useExtractionUpdater(
    (s) => s.setSelectedQuestionId
  );

  const {
    updateAIExtractionEntry,
    commitAIExtractionEntries,
    editAIExtractionRun
  } = useFeatheryRedux();

  const extraction = useAppSelector((state) =>
    state.ai.extractions.find((extraction) => extraction.id === extractionId)
  );
  const account = useAppSelector((state) => state.accounts.account);
  const canApprove = !run.approved && extraction.reviewers.includes(account.id);

  // Determine if the user can click "Update Field Values" (changes must be made)
  const canCommitValues = useMemo(() => {
    return run && !run.fetchingBoundingBoxes
      ? getAllEntries(run).some((entry) => !entry.committed)
      : false;
  }, [run]);

  // Handler to show the modal for adding a value
  const handleShowAddValueModal = (fieldIds: string[]) => {
    setTargetEntries(
      getAllEntries(run as AIExtractionRun).filter((e) =>
        fieldIds.includes(e.fieldId)
      )
    );
    setShowAddValueModal(true);
  };

  // Handler to close the modal for adding a value
  const handleCloseAddValueModal = () => {
    setShowAddValueModal(false);
    setTargetEntries([]);
  };

  // Handler for adding entry values (via the modal)
  const handleAddValue = (values: AddValuePayload) => {
    const payload = {
      action: 'add',
      values: values.map((val) => ({
        id: val.entryId,
        value: val.value
      }))
    };

    updateAIExtractionEntry({
      runId,
      data: payload
    }).then(() => setShowAddValueModal(false));
  };

  // Handler for editing a single entry value
  const handleEditValue = (
    entry: AIExtractionEntry,
    index: number,
    newValue: string
  ) => {
    const targetValue = entry.value[index];

    if (targetValue === newValue) {
      return; // Prevent unnecessary updates
    }

    const payload = {
      action: 'update',
      index,
      values: [
        {
          id: entry.id,
          value: newValue
        }
      ]
    };

    updateAIExtractionEntry({
      runId,
      data: payload
    });
  };

  // Handler for deleting entry values
  const handleDeleteValue = (questionGroup: AIQuestionGroup, index: number) => {
    const valuesToBeDeleted = questionGroup.entries.map((entry) => ({
      id: entry.id,
      value: entry.value[index]
    }));

    const payload = {
      action: 'delete',
      index,
      values: valuesToBeDeleted
    };

    updateAIExtractionEntry({
      runId,
      data: payload
    });
  };

  const approveSubmission = async () => {
    await editAIExtractionRun({
      runId,
      approved: true,
      approver: account.email
    });

    // See if redirect is necessary
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get('redirect');
    if (redirectUrl) window.location.href = redirectUrl;
  };

  // Handler for updating entry values
  const handleUpdateFieldValues = () => {
    const payload = {
      entry_ids: getAllEntries(run as AIExtractionRun)
        .filter((entry) => !entry.committed)
        .map((entry) => entry.id)
    };

    commitAIExtractionEntries({
      runId,
      data: payload
    });
  };

  return (
    <>
      <AIAddValueModal
        entries={targetEntries}
        show={showAddValueModal}
        onClose={() => handleCloseAddValueModal()}
        onComplete={(values) => handleAddValue(values)}
      />
      <div className={classNames('background-controls', styles.panelContainer)}>
        <div className={classNames('background-controls', styles.header)}>
          <Button.Back
            onClick={() => history.push(`/ai/${extractionId}/results`)}
          />
          <div className={styles.label}>
            {run.displayPDF?.name
              ? run.displayPDF.name
              : run.emailContent?.subject
              ? `Email - ${run.emailContent.subject}`
              : 'Extraction'}
          </div>
        </div>
        <div className={classNames('background-controls', styles.body)}>
          {run?.questionGroups.length === 0 && (
            <div className={classNames(styles.infoCard, styles.error)}>
              <div className={styles.title}>No Queries</div>
              There are no configured queries for this extraction. Please edit
              the extraction to add queries.
            </div>
          )}
          {run?.questionGroups.map((questionGroup, index) => {
            const { entries, entities } = questionGroup;

            const indexMap: Record<any, number> = {};
            entities.forEach((entity, index) => {
              indexMap[entity.fieldId] = index;
            });

            const sortedEntries = entries.sort(
              (a, b) => indexMap[a.fieldId] - indexMap[b.fieldId]
            );

            const canAddValue =
              questionGroup.questionType === 'one_value'
                ? !sortedEntries.length
                : true;

            return (
              <div
                key={questionGroup.id}
                className={classNames(
                  'background-controls',
                  styles.questionGroup
                )}
              >
                <div className={styles.section}>
                  <div className={styles.label}>
                    <div className={styles.icon}>
                      <AIIcon />
                    </div>
                    <div className={styles.text}>Query {index + 1}</div>
                  </div>
                  <div className={styles.content}>
                    {entities.map((entity: any, i: number) => (
                      <div key={`${entity.name}-${i}`} className={styles.card}>
                        <div className={classNames(styles.title, styles.red)}>
                          {entity.name}
                        </div>
                        <div className={styles.body}>
                          <div
                            className={styles.text}
                            style={{ fontSize: '0.9rem', marginTop: '-5px' }}
                          >
                            ({getField(entity.fieldId)?.key ?? 'Unknown Field'})
                          </div>
                          {entity.criteria && (
                            <div
                              className={styles.text}
                              style={{ marginTop: '10px' }}
                            >
                              {entity.criteria}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    {questionGroup?.criteria && (
                      <>
                        <div
                          className={styles.label}
                          style={{ marginTop: '15px' }}
                        >
                          <div className={styles.icon}>
                            <SettingsIcon
                              fill='#6C7589'
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className={styles.text}>Additional Options</div>
                        </div>
                        <div
                          className={styles.card}
                          style={{ marginBottom: '10px' }}
                        >
                          <div className={styles.title}>Query Details</div>
                          <div className={styles.body}>
                            {questionGroup.criteria}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.section}>
                  <div
                    className={classNames(styles.label, styles.clickable)}
                    onClick={() =>
                      setSelectedQuestionId(
                        selectedQuestionId === questionGroup.id
                          ? null
                          : questionGroup.id
                      )
                    }
                  >
                    <div className={styles.icon}>
                      <CodeIcon color={'#6C7589'} />
                    </div>
                    <div className={styles.text}>Data</div>
                    <div className={styles.actions}>
                      <button
                        className={styles.action}
                        onClick={() =>
                          setSelectedQuestionId(
                            selectedQuestionId === questionGroup.id
                              ? null
                              : questionGroup.id
                          )
                        }
                      >
                        <ShowIcon
                          width={20}
                          height={20}
                          color={
                            selectedQuestionId === questionGroup.id
                              ? '#e2626e'
                              : '#6C7589'
                          }
                        />
                      </button>
                    </div>
                  </div>
                  <div className={styles.content}>
                    {sortedEntries.length ? (
                      sortedEntries[0].value.map((_, index) => (
                        <AIEntry
                          key={index}
                          index={index}
                          entries={sortedEntries}
                          entities={entities}
                          onEdit={(entry, newValue) =>
                            handleEditValue(entry, index, newValue)
                          }
                          onDelete={(index) =>
                            handleDeleteValue(questionGroup, index)
                          }
                        />
                      ))
                    ) : (
                      <div className={styles.card}>
                        No entries found for this query
                      </div>
                    )}
                  </div>
                  <Tooltip
                    disabled={canAddValue}
                    content='This query can only have 1 value. Remove the value to add a new value.'
                    sideOffset={5}
                    delay={750}
                  >
                    <div>
                      <ButtonComponent
                        variant='dashed'
                        className='w-full'
                        disabled={!canAddValue}
                        onClick={() =>
                          handleShowAddValueModal(questionGroup?.fieldIds || [])
                        }
                      >
                        {questionGroup.questionType === 'multiple_value'
                          ? '+ Add Entry'
                          : '+ Add Value'}
                      </ButtonComponent>
                    </div>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={classNames(styles.footer, {
            [styles.show]: canApprove && !canCommitValues
          })}
        >
          <button onClick={approveSubmission}>
            <CheckCircleIcon
              width={20}
              height={20}
              color='var(--system-valid)'
              backgroundColor='white'
            />
            <span>Approve</span>
          </button>
        </div>
        <div
          className={classNames(styles.footer, styles.updateFieldVals, {
            [styles.show]: canCommitValues
          })}
        >
          <button onClick={handleUpdateFieldValues}>
            <CheckCircleIcon
              width={20}
              height={20}
              color='orange'
              backgroundColor='white'
            />
            <span>Update Form Fields</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AIExtractionPanel;
