import styles from '../styles.module.scss';
import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import FaviconUpload from '../components/FaviconUpload';
import useFeatheryRedux from '../../../redux';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { useAppSelector } from '../../../hooks';
import { useState } from 'react';
import { isValidUrl } from '../../../utils/validate';
import { openTab } from '../../../utils/domOperations';
import { REGION } from '../../../api/utils';
import Combobox from '../../../components/Core/Combobox';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';

export default function BrandingTab() {
  const {
    editOrganization,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization);
  const panels = useAppSelector((state) => state.panels.panels);

  const [showBrand, setShowBrand] = useState(org?.show_brand ?? true);
  const [customSubdomain, setCustomSubdomain] = useState(org?.slug ?? '');
  const [customDomains, setCustomDomains] = useState<string[]>(
    org?.custom_domains ?? []
  );
  const [apexForm, setApexForm] = useState(org?.apex_form ?? '');

  const enterprise = org?.tier === 4;
  const freeTier = onFreePlan(org);

  let brandingComponent = (
    <YesNoSwitch
      id='form-badge'
      checked={freeTier || showBrand}
      onCheckedChange={(value) => {
        setShowBrand(value);
        editOrganization({ show_brand: value }).then(() => {
          addInfoToast('Settings updated');
        });
      }}
      disabled={freeTier}
    />
  );
  let subdomainComponent = (
    <TextField
      disabled={freeTier || REGION}
      maxLength={32}
      value={customSubdomain ?? ''}
      onChange={(val: string) => setCustomSubdomain(val.toLowerCase())}
      className={styles.settingsField}
    />
  );
  let domainComponent = (
    <Combobox
      isDisabled={!(enterprise && !REGION)}
      value={customDomains}
      onChange={(items: string[]) => setCustomDomains(items)}
      className={styles.settingsMultiField}
      placeholder='https://company.com'
    />
  );
  if (freeTier) {
    subdomainComponent = (
      <Tooltip content='You must be on a paid plan to set a custom subdomain for your forms'>
        {subdomainComponent}
      </Tooltip>
    );
    brandingComponent = (
      <Tooltip content='You must be on a paid plan to turn off the badge'>
        <div>{brandingComponent}</div>
      </Tooltip>
    );
  }
  if (!enterprise) {
    domainComponent = (
      <Tooltip content="You don't have custom DNS domains enabled for your account">
        {domainComponent}
      </Tooltip>
    );
  }
  const apexEnabled =
    !freeTier && (org?.slug || (org?.custom_domains ?? []).length);
  let apexComponent = (
    <DropdownField
      className={styles.settingsField}
      disabled={!apexEnabled}
      onChange={(event: any) => setApexForm(event.target.value)}
      selected={apexForm}
      options={[
        { value: '', display: '' },
        ...Object.values(panels).map(({ id, key }) => ({
          value: id,
          display: key
        }))
      ]}
    />
  );
  if (!apexEnabled)
    apexComponent = (
      <Tooltip
        content='You must have a custom subdomain or domain configured in order to set
          an apex form'
      >
        {apexComponent}
      </Tooltip>
    );

  return (
    <>
      <div className={styles.sectionSeparator} />
      <div className={styles.lowerSection}>
        <label className={styles.settingsLabel}>
          Show&nbsp;<b>Forms by Feathery</b>&nbsp;Badge
        </label>
        <div className={styles.teamFieldSection}>{brandingComponent}</div>
        <label className={styles.settingsLabel}>
          Custom Subdomain
          <InlineTooltip
            text='Your forms will be accessible at <custom-subdomain>.feathery.io/to/<slug>'
            inline
          />
        </label>
        <div className={styles.teamFieldSection}>
          {subdomainComponent}
          <span
            className={styles.teamFieldSave}
            onClick={() => {
              if (!freeTier) {
                editOrganization({ slug: customSubdomain || null })
                  .then(() => {
                    addInfoToast('Custom subdomain updated');
                  })
                  .catch(() => addInfoToast('Invalid subdomain'));
              }
            }}
          >
            Save
          </span>
        </div>
        <label className={styles.settingsLabel}>
          Custom Domains
          <InlineTooltip
            inline
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openTab(
                'https://docs.feathery.io/platform/launch-forms/custom-urls-and-seo#custom-dns-domain'
              )
            }
          />
        </label>
        <div className={styles.teamFieldSection}>
          {domainComponent}
          <span
            className={styles.teamFieldSave}
            onClick={() => {
              if (enterprise) {
                const err = customDomains.some((domain) => !isValidUrl(domain));
                if (err) {
                  addErrorToast({ title: 'Can only add valid domains' });
                  return;
                }
                editOrganization({ custom_domains: customDomains })
                  .then(() => {
                    addInfoToast('Custom domains updated');
                  })
                  .catch(() => addInfoToast('Invalid domain'));
              }
            }}
          >
            Save
          </span>
        </div>
        <label className={styles.settingsLabel}>
          Apex Form
          <InlineTooltip
            text='This form will be accessible directly at <custom subdomain>.feathery.io'
            inline
          />
        </label>
        <div className={styles.teamFieldSection}>
          {apexComponent}
          <span
            className={styles.teamFieldSave}
            onClick={() => {
              if (!freeTier) {
                editOrganization({ apex_form: apexForm || null })
                  .then(() => {
                    addInfoToast('Apex form updated');
                  })
                  .catch((err: Error) => addInfoToast(err.message));
              }
            }}
          >
            Save
          </span>
        </div>
        <FaviconUpload />
      </div>
    </>
  );
}
