import { useEffect } from 'react';
import { useKeyboardListener } from '../../../hooks/useKeyboardListener';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import { ExtractionState, ExtractionUpdater } from './types';
import styles from '../components/styles.module.scss';

const KEYS: any = ['Escape'] as const;

const getEntryId = (valueId: string) => {
  return valueId.split('-').slice(0, -1).join('-');
};

const getEntryValueIndex = (valueId: string) => {
  return parseInt(valueId.split('-').pop() as string);
};

const isInView = (element: Element) => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= window.innerHeight;
};

type Props = {
  state: ExtractionState;
  updater: ExtractionUpdater;
};

export const ExtractionEngine = ({ state, updater }: Props) => {
  const { selectedValueId, hoveredValueId, activeComponent } = state;
  const { setSelectedValueId } = updater;
  const getActiveComponent = useSyncedRefState(activeComponent);

  const { onKeyup } = useKeyboardListener({
    keys: KEYS,
    preventDefault: true
  });

  useEffect(() => {
    if (onKeyup) {
      onKeyup(({ shortcut }: any) => {
        if (shortcut === 'Escape') {
          setSelectedValueId(null);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedValueId) {
      return;
    }

    const entryId = getEntryId(selectedValueId);
    const entryValueIndex = getEntryValueIndex(selectedValueId);
    const entryDiv = document.querySelector(
      `div#entry-${entryValueIndex}.entry-${entryId}`
    );

    if (!entryDiv) {
      return;
    }

    if (getActiveComponent() === 'canvas') {
      const isExpanded = entryDiv?.getAttribute('data-expanded') === 'true';
      if (isExpanded) {
        const entryValueDiv = entryDiv.querySelector(
          `div[data-id="${selectedValueId}"`
        );

        if (entryValueDiv && !isInView(entryValueDiv)) {
          entryValueDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (!isInView(entryDiv)) {
          entryDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else if (!isInView(entryDiv)) {
        entryDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedValueId]);

  useEffect(() => {
    if (!hoveredValueId) {
      const entryDivs = document.querySelectorAll(`div.${styles.aiEntryField}`);
      entryDivs.forEach((entryDiv) => {
        entryDiv.classList.remove(styles.highlight);
      });

      return;
    }

    if (hoveredValueId) {
      const entryId = getEntryId(hoveredValueId);
      const entryValueIndex = getEntryValueIndex(hoveredValueId);
      const entryDiv = document.querySelector(
        `div#entry-${entryValueIndex}.entry-${entryId}`
      );

      if (!entryDiv) {
        return;
      }

      const isExpanded = entryDiv?.getAttribute('data-expanded') === 'true';
      if (!isExpanded) {
        entryDiv.classList.add(styles.highlight);
      }
    }
  }, [hoveredValueId]);

  return null;
};
