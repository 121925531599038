/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from 'react';

import { DropdownField } from '../index';
import JSZip from 'jszip';

import styles from './styles.module.scss';
import { FileUploadIcon } from '../../Icons';
import { CustomFontUploadModal } from '../../Modals';
import useFeatheryRedux from '../../../redux';
import { loadedFonts } from '../../../utils/themes';
import { useAppSelector } from '../../../hooks';
import { Button } from '../Button/button';

export const ALLOWED_FONT_TYPES = ['.woff', '.woff2', '.ttf', '.otf'];

async function parseFontFiles(event: any) {
  const promiseFiles = Array.from(event.target.files).map((rawFile: any) => {
    if (rawFile.type === 'application/zip') {
      const zipper = new JSZip();
      return zipper.loadAsync(rawFile).then((zipFiles) =>
        Promise.all(
          Object.entries(zipFiles.files)
            .filter(([, file]) => {
              const fileName = file.name;
              return (
                ALLOWED_FONT_TYPES.some((type) => fileName.endsWith(type)) &&
                !fileName.startsWith('__MACOSX')
              );
            })
            .map(([fileName, file]) =>
              file
                .async('arraybuffer')
                .then((data) => new File([data], fileName))
            )
        )
      );
    } else return rawFile;
  });
  event.target.value = '';
  return (await Promise.all(promiseFiles)).flat();
}

export default function FontPicker({
  selected,
  onChange,
  CustomComponent
}: any) {
  const fileInput = useRef<any>(null);
  const { uploadFont } = useFeatheryRedux();
  const fontOptions = useAppSelector((state) => state.integrations.fontOptions);
  const [customFontFiles, setCustomFontFiles] = useState<any[]>([]);

  const onFileChange = (event: any) =>
    parseFontFiles(event).then((files) => setCustomFontFiles(files));
  const addFiles = (event: any) =>
    parseFontFiles(event).then((newFiles) =>
      setCustomFontFiles([...customFontFiles, ...newFiles])
    );

  return (
    <div className={styles.fontPickerContainer}>
      {CustomComponent ? (
        <CustomComponent onClick={() => fileInput.current.click()} />
      ) : (
        <>
          <DropdownField
            selected={selected}
            onChange={onChange}
            options={fontOptions}
            className={styles.fontDropdown}
          />
          <Button
            className='aspect-square rounded-l-none border-l-0'
            variant='outline'
            size='icon'
            onClick={() => fileInput.current.click()}
          >
            <FileUploadIcon width={18} height={18} />
          </Button>
        </>
      )}
      <input
        ref={fileInput}
        type='file'
        onChange={onFileChange}
        required={false}
        accept={`${ALLOWED_FONT_TYPES.join(',')},.zip`}
        className={styles.fontCustomUploadFileInput}
        multiple
      />
      <CustomFontUploadModal
        show={customFontFiles.length > 0}
        hideModal={() => setCustomFontFiles([])}
        fontFiles={customFontFiles}
        addFiles={addFiles}
        setData={(value: any, fileData: any) => {
          const weight: any = [];
          const style: any = [];
          const source: any = [];
          fileData.forEach((data: any) => {
            weight.push(data.weight);
            style.push(data.style);
            source.push(data.file);
          });
          uploadFont({ value, weight, style, source }).then(() => {
            // Need to reload font if updated
            if (loadedFonts.has(value)) loadedFonts.delete(value);
          });
        }}
      />
    </div>
  );
}
