import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import ABTestPage from './pages/ABTestPage';
import FormDesigner from './pages/FormDesigner';
import FormIntegrationsPage from './pages/FormIntegrationsPage';
import FormAnalyticsPage from './pages/FormAnalyticsPage';
import FormResultsDetailPage from './pages/FormResultsDetailPage';
import FormResultsPage from './pages/FormResultsPage';
import FormSettingsPage from './pages/FormSettingsPage';
import FormsPage from './pages/FormsPage';
import OAuthRedirectPage from './pages/OAuthRedirectPage';
import Page from './pages/Page';
import RootPage from './pages/RootPage';
import GlobalSettingsPage from './pages/GlobalSettingsPage';
import TaskListPage from './pages/TaskListPage';
import ThemeBuilderPage from './pages/ThemeBuilderPage';
import ThemeSettingsPage from './pages/ThemeSettingsPage';
import ThemesPage from './pages/ThemesPage';
import ToastContainer from './components/Toasts';
import ErrorDisplay from './components/ErrorDisplay';
import { ReactFlowProvider } from 'reactflow';
import { LogoLoader } from './components/Core';
import DocumentsPage from './pages/DocumentsPage';
import DocumentEditorPage from './pages/DocumentEditorPage';
import DocumentSettingsPage from './pages/DocumentSettingsPage';
import DocumentResultsPage from './pages/DocumentResultsPage';
import AIExtractionListPage from './pages/AIExtractionListPage';
import AIExtractionDetailPage from './pages/AIExtractionDetailPage';
import AIExtractionRunPage from './pages/AIExtractionRunPage';
import AIExtractionSettingsPage from './pages/AIExtractionSettingsPage';
import AIIntegrationsPage from './pages/AIIntegrationsPage';
import AILogicPage from './pages/AILogicPage';
import WorkspacesPage from './pages/WorkspacesPage';

const AppRouter = () => (
  <ErrorBoundary FallbackComponent={ErrorDisplay}>
    <Router>
      <ToastContainer />
      <Suspense fallback={<LogoLoader />}>
        <Switch key={window.location.href}>
          <Route exact path='/'>
            <Page active='Root'>
              <RootPage />
            </Page>
          </Route>
          <Route exact path='/sso' render={() => <Redirect to='/forms' />} />
          <Route exact path='/forms/:formId'>
            <Page active='Forms' padding={false} hideNav>
              <ReactFlowProvider>
                <FormDesigner />
              </ReactFlowProvider>
            </Page>
          </Route>
          <Route exact path='/ai/'>
            <Page active='AI' padding={false}>
              <AIExtractionListPage />
            </Page>
          </Route>
          <Route exact path='/ai/:extractionId/results'>
            <Page active='AI' padding={false} hideNav>
              <AIExtractionDetailPage />
            </Page>
          </Route>
          <Route exact path='/ai/:extractionId/integrations'>
            <Page active='AI' padding={false} hideNav>
              <AIIntegrationsPage />
            </Page>
          </Route>
          <Route exact path='/ai/:extractionId/logic/:ruleId?'>
            <Page active='AI' padding={false} hideNav hideIntercom>
              <AILogicPage />
            </Page>
          </Route>
          <Route exact path='/ai/:extractionId/settings'>
            <Page active='AI' padding={false} hideNav>
              <AIExtractionSettingsPage />
            </Page>
          </Route>
          <Route exact path='/ai/:extractionId/results/:runId'>
            <Page
              active='AI'
              padding={false}
              hideNav
              noVerticalScroll
              hideIntercom
            >
              <AIExtractionRunPage />
            </Page>
          </Route>
          <Route exact path='/forms/:formId/integrations'>
            <Page active='Forms' padding={false} hideNav>
              <FormIntegrationsPage />
            </Page>
          </Route>
          <Route exact path='/users'>
            <Page active='All Users' padding={false} alwaysShowScroll>
              <FormResultsPage global />
            </Page>
          </Route>
          <Route exact path='/users/:userId'>
            <Page active='Users' padding={false}>
              <FormResultsDetailPage global />
            </Page>
          </Route>
          <Route exact path='/tasks'>
            <Page active='Tasks' padding={true} alwaysShowScroll>
              <TaskListPage />
            </Page>
          </Route>
          <Route exact path='/forms/:formId/results'>
            <Page active='Forms' padding={false} hideNav alwaysShowScroll>
              <FormResultsPage />
            </Page>
          </Route>
          <Route exact path='/forms/:formId/results/:userId'>
            <Page active='Forms' padding={false} hideNav>
              <FormResultsDetailPage />
            </Page>
          </Route>
          <Route exact path='/forms/:formId/analytics'>
            <Page active='Forms' padding={false} hideNav>
              <ReactFlowProvider>
                <FormAnalyticsPage />
              </ReactFlowProvider>
            </Page>
          </Route>
          <Route exact path='/forms/:formId/settings'>
            <Page active='Forms' padding={false} hideNav>
              <FormSettingsPage />
            </Page>
          </Route>
          <Route exact path='/forms/:formId/:stepId'>
            <Page active='Forms' padding={false} hideNav hideIntercom>
              <ReactFlowProvider>
                <FormDesigner />
              </ReactFlowProvider>
            </Page>
          </Route>
          <Route exact path='/forms/:formId/logic/:ruleId/:tab?'>
            <Page active='Forms' padding={false} hideNav hideIntercom>
              <ReactFlowProvider>
                <FormDesigner />
              </ReactFlowProvider>
            </Page>
          </Route>
          <Route exact path='/forms'>
            <Page active='Forms' alwaysShowScroll>
              <FormsPage />
            </Page>
          </Route>
          <Route exact path='/themes/:themeId'>
            <Page active='Themes' padding={false} hideIntercom>
              <ThemeBuilderPage />
            </Page>
          </Route>
          <Route exact path='/themes/:themeId/settings'>
            <Page active='Themes' padding={false}>
              <ThemeSettingsPage />
            </Page>
          </Route>
          <Route path='/themes'>
            <Page active='Themes' alwaysShowScroll>
              <ThemesPage />
            </Page>
          </Route>
          <Route exact path='/documents/:documentId'>
            <Page active='Documents' padding={false} hideNav>
              <DocumentEditorPage />
            </Page>
          </Route>
          <Route exact path='/documents/:documentId/envelopes'>
            <Page active='Documents' padding={false} hideNav>
              <DocumentResultsPage />
            </Page>
          </Route>
          <Route exact path='/documents/:documentId/settings'>
            <Page active='Documents' padding={false} hideNav>
              <DocumentSettingsPage />
            </Page>
          </Route>
          <Route path='/documents'>
            <Page active='Documents' alwaysShowScroll>
              <DocumentsPage />
            </Page>
          </Route>
          <Route path='/ab-tests'>
            <Page active='A/B Tests' alwaysShowScroll>
              <ABTestPage />
            </Page>
          </Route>
          <Route path='/workspaces'>
            <Page active='Workspaces' alwaysShowScroll>
              <WorkspacesPage />
            </Page>
          </Route>
          <Route path='/settings/:tab?'>
            <Page active='Settings' alwaysShowScroll>
              <GlobalSettingsPage />
            </Page>
          </Route>
          <Route path='/oauth-redirect/'>
            <OAuthRedirectPage />
          </Route>
          <Route>
            <Page active='404 Page'>
              <div>404 Error</div>
            </Page>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  </ErrorBoundary>
);

export default AppRouter;
