import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const ORION_CREATE: Template = {
  id: 'orion_create',
  display: 'Create Portfolio',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    firstName: { type: 'textfield', label: 'First Name' },
    lastName: { type: 'textfield', label: 'Last Name' },
    representativeId: { type: 'textfield', label: 'Representative ID' },
    portfolioTypeId: { type: 'textfield', label: 'Portfolio Type ID' },
    feeScheduleId: { type: 'textfield', label: 'Fee Schedule ID' },
    masterPayoutScheduleId: {
      type: 'textfield',
      label: 'Master Payout Schedule ID'
    },
    payMethodId: { type: 'textfield', label: 'Pay Method ID' },
    fundFamilyId: { type: 'textfield', label: 'Fund Family ID' },
    custodianId: { type: 'textfield', label: 'Custodian ID' },
    accountStatusId: { type: 'textfield', label: 'Account Status ID' }
  },
  defaultState: {
    name: 'Orion Create Portfolio',
    template: 'orion_create',
    url: 'https://api.orionadvisor.com/api/v1/Portfolio/Accounts/NewPortfolio',
    method: 'POST',
    headers: {
      Authorization: 'Session {{orion_token}}',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: '',
      client: {
        name: '',
        portfolio: {
          firstName: '',
          lastName: '',
          representativeId: '',
          name: ''
        },
        billing: { statusType: 'Pending Review' }
      },
      registration: {
        portfolio: {
          name: '',
          firstName: '',
          lastName: '',
          typeId: ''
        }
      },
      account: {
        name: '',
        billing: {
          feeScheduleId: '',
          masterPayoutScheduleId: '',
          payMethodId: ''
        },
        portfolio: {
          fundFamilyId: '',
          custodianId: '',
          accountStatusId: ''
        }
      }
    }),
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    const body = JSON.parse(state.body);
    return {
      name: state.name,
      firstName: body.client.portfolio.firstName,
      lastName: body.client.portfolio.lastName,
      representativeId: body.client.portfolio.representativeId,
      portfolioTypeId: body.registration.portfolio.typeId,
      feeScheduleId: body.account.billing.feeScheduleId,
      masterPayoutScheduleId: body.account.billing.masterPayoutScheduleId,
      payMethodId: body.account.billing.payMethodId,
      fundFamilyId: body.account.portfolio.fundFamilyId,
      custodianId: body.account.portfolio.custodianId,
      accountStatusId: body.account.portfolio.accountStatusId
    };
  },
  saveConfig: (state, configValues) => {
    const {
      name,
      firstName,
      lastName,
      representativeId,
      portfolioTypeId,
      feeScheduleId,
      masterPayoutScheduleId,
      payMethodId,
      fundFamilyId,
      custodianId,
      accountStatusId
    } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name,
      body: JSON.stringify({
        client: {
          name: `${firstName} ${lastName}`,
          portfolio: {
            firstName,
            lastName,
            representativeId,
            name: `${firstName} ${lastName}`
          },
          billing: { statusType: 'Pending Review' }
        },
        registration: {
          portfolio: {
            name: `${firstName} ${lastName}`,
            firstName,
            lastName,
            typeId: portfolioTypeId
          }
        },
        account: {
          name: `${firstName} ${lastName}`,
          billing: {
            feeScheduleId,
            masterPayoutScheduleId,
            payMethodId
          },
          portfolio: {
            fundFamilyId,
            custodianId,
            accountStatusId
          }
        }
      })
    };

    return {
      ...state,
      ...newValues
    };
  }
};
