import { FC, memo } from 'react';

import dialogStyles from '../../Dialog/styles.module.scss';
import { CloseIcon, PlusIcon } from '../../Icons';
import { UnresolvedComparisonRule, UnresolvedDecisionLogic } from './';
import ComparisonRule, { FieldSelectorProps } from './ComparisonRule';
import { InlineTooltip, TextField } from '../../Core';
import FieldSelection from './FieldSelection';

import styles from './styles.module.scss';
import ruleStyles from './../../NavigationRules/styles.module.scss';
import classNames from 'classnames';

export const FieldSelector: FC<FieldSelectorProps> = ({
  fieldId,
  fieldType,
  fieldKey,
  locked = false,
  className = '',
  error,
  valueIndex = null,
  showFieldSelector
}) => {
  return (
    <FieldSelection
      fieldId={fieldId}
      fieldType={fieldType}
      fieldKey={fieldKey}
      error={error}
      locked={locked}
      className={classNames(styles.fieldSelector, className)}
      showFieldSelector={(
        selectedFieldId: string,
        selectedFieldType: 'servar' | 'hidden'
      ) => {
        showFieldSelector(selectedFieldId, selectedFieldType, valueIndex);
      }}
    />
  );
};

const DecisionLogicSection = ({
  element,
  decisionLogic,
  name,
  logicRulesType,
  showErrors = false,
  handleDelete,
  handleUpdates,
  showFieldSelector,
  showBulkAddValues,
  newRule
}: {
  decisionLogic: UnresolvedDecisionLogic;
  name: string;
  logicRulesType: 'validation' | 'visibility';
  showErrors?: boolean;
  handleDelete: () => void;
  handleUpdates: (newLogicItem: UnresolvedDecisionLogic) => void;
  showFieldSelector: (
    selectedFieldId: string,
    selectedFieldType: '' | 'servar' | 'hidden',
    selectedRuleItemIndex: number,
    selectedRuleValueIndex: number | null
  ) => void;
  showBulkAddValues: (index: number) => void;
  element: any;
  newRule: (element?: any) => UnresolvedComparisonRule;
}) => {
  return (
    <div className={classNames(styles.logicSection, ruleStyles.ruleContainer)}>
      <div className={styles.header}>
        <div className={styles.logicLabelContainer}>
          <div className={styles.logicRuleName}>{name}</div>
          <InlineTooltip
            text={
              logicRulesType === 'validation'
                ? 'If this logic evaluates true, the field will be invalid and an error message will show'
                : 'If this logic evaluates true, the field will be hidden'
            }
            inline
          />
        </div>
        <div
          className={styles.logicRuleDelete}
          tabIndex={0}
          onClick={handleDelete}
        >
          <CloseIcon height={16} width={16} />
        </div>
      </div>

      <div className={styles.ruleGroup}>
        {decisionLogic.rules.map(
          (rule: UnresolvedComparisonRule, ruleIndex: number) => {
            return (
              <div key={ruleIndex}>
                {ruleIndex > 0 && <div className={styles.and}>And</div>}
                <ComparisonRule
                  rule={rule}
                  locked={
                    /* Only lock the first rule for a field if the field is a servar and the left side is the field itself */
                    ruleIndex === 0 &&
                    element.servar &&
                    element.servar.id === rule.field_id &&
                    logicRulesType === 'validation'
                  }
                  showErrors={showErrors}
                  handleDelete={() => {
                    const rules = [...decisionLogic.rules];
                    rules.splice(ruleIndex, 1);
                    // If no more rules, re-initialize with a new rule because one is always required
                    if (!rules.length) rules.push(newRule(element));
                    handleUpdates({ ...decisionLogic, rules });
                  }}
                  handleUpdates={(rule) => {
                    const rules = [...decisionLogic.rules];
                    rules.splice(ruleIndex, 1, rule);
                    handleUpdates({ ...decisionLogic, rules });
                  }}
                  showBulkAddValues={() => showBulkAddValues(ruleIndex)}
                  showFieldSelector={(
                    selectedFieldId,
                    selectedFieldType,
                    selectedRuleValueIndex
                  ) => {
                    showFieldSelector(
                      selectedFieldId,
                      selectedFieldType,
                      ruleIndex,
                      selectedRuleValueIndex
                    );
                  }}
                  FieldSelector={FieldSelector}
                />
              </div>
            );
          }
        )}
      </div>

      <div
        className={classNames(
          ruleStyles.ruleRow,
          ruleStyles.addRow,
          styles.addCondition
        )}
        onClick={() =>
          handleUpdates({
            ...decisionLogic,
            rules: [
              ...decisionLogic.rules,
              { field_id: null, comparison: 'equal', values: [''] }
            ]
          })
        }
      >
        <PlusIcon width={15} height={15} className={ruleStyles.addNewIcon} />
        Condition
      </div>

      {logicRulesType === 'validation' && (
        <>
          <hr className={styles.hr} />
          <div className={styles.errMessageLabel}>Custom Error Message</div>
          <TextField
            value={decisionLogic.message || ''}
            placeholder='Example: "Your answer is invalid"'
            className={dialogStyles.input}
            onComplete={(changedValue: string) =>
              handleUpdates({
                ...decisionLogic,
                message: changedValue
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default memo(DecisionLogicSection);
