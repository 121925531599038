import React from 'react';
import { HTMLInputTypeAttribute } from 'react';
import TextField from '../TextField';
import styles from './styles.module.scss';
import { TrashIcon } from '../../Icons';
import InlineTooltip from '../Tooltip/InlineTooltip';
import { Button } from '../Button/button';

interface InputProps {
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onChange?: (value: string) => any;
  value?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  id?: string;
  name?: string;
}

type Props =
  | {
      labelProps: InputProps;
      inputProps?: InputProps;
      fieldOverride: React.ReactElement;
      onDelete?: () => void;
      showTooltip?: boolean;
    }
  | {
      labelProps: InputProps;
      inputProps: InputProps;
      fieldOverride?: React.ReactElement;
      onDelete?: () => void;
      showTooltip?: boolean;
    };

/**
 * React Component that renders a side-by-side form field and label
 * Defaults to text input or custom element that's passed
 */
export function HorizontalFormAttribute({
  labelProps,
  inputProps,
  fieldOverride,
  onDelete,
  showTooltip = false
}: Props) {
  const tooltipText = labelProps.value?.toLowerCase().replace(' ', '_');
  return (
    <div className={styles.attribute}>
      <div style={{ position: 'relative' }}>
        {/* Note: We extend a disabled text field to implement a label UI */}
        <TextField
          error={labelProps.error}
          errorMessage={labelProps.errorMessage}
          placeholder={labelProps.placeholder}
          value={labelProps.value}
          allowedRegex={/[^a-zA-Z0-9 _]/g}
          onChange={labelProps.onChange}
          type={labelProps.type ?? 'text'}
          className={styles.attributeLabel}
          disabled={labelProps.disabled}
          name={labelProps.name || ''}
          id={labelProps.id || ''}
        />
        {showTooltip && !!tooltipText && (
          <InlineTooltip
            text={`To access this attribute from a hidden field, use feathery.account.${tooltipText}`}
            style={{
              position: 'absolute',
              right: 12,
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          />
        )}
      </div>
      {inputProps && (
        <TextField
          placeholder={inputProps.placeholder}
          value={inputProps.value}
          onChange={inputProps.onChange}
          type={inputProps.type ?? 'text'}
          className={styles.attributeField}
          disabled={inputProps.disabled}
          name={inputProps.name || ''}
          id={inputProps.id || ''}
        />
      )}
      {fieldOverride && <div>{fieldOverride}</div>}
      {onDelete != null && (
        <Button
          variant='ghost'
          size='icon'
          onClick={onDelete}
          className='hover:text-destructive h-8'
        >
          <TrashIcon className='h-5 w-5 stroke-current' />
        </Button>
      )}
    </div>
  );
}
