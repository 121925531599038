import { CollapsibleSection } from '../../Core';
import ElementBlockSource from './ElementBlockSource';
import { memo } from 'react';

import styles from './styles.module.scss';

function ElementBlockPanel({
  elements = {},

  // Valid modes: 'drag' (step builder) or 'nav' (theme builder)
  mode = 'drag',

  onElementMouseDown = () => {},
  categories,
  theme,
  setTheme,
  handleUpdates,
  context = 'builder',
  deleteAssetFromTheme
}: any) {
  return (
    <div className={styles.elementPanel}>
      {categories.map(({ title, keys }: any) => (
        <CollapsibleSection
          key={title}
          title={title}
          expanded
          collapsible
          separator={false}
          customClasses={{
            content: 'hover:overflow-visible'
          }}
        >
          <div className={styles.blockPanel}>
            {keys
              .map((key: any) => [key, elements[key]])
              // @ts-expect-error TS(7031) FIXME: Binding element 'key' implicitly has an 'any' type... Remove this comment to see the full error message
              .map(([key, element]) => (
                <ElementBlockSource
                  key={element.label}
                  mode={mode}
                  elementType={key}
                  icon={element.icon}
                  label={element.label}
                  message={element.message}
                  onMouseDown={() => onElementMouseDown(key)}
                  theme={theme}
                  setTheme={setTheme}
                  handleUpdates={handleUpdates}
                  context={context}
                  deleteAssetFromTheme={deleteAssetFromTheme}
                />
              ))}
          </div>
        </CollapsibleSection>
      ))}
    </div>
  );
}

export default memo(ElementBlockPanel);
