import React from 'react';
import { AIExtractionEntryValue } from '../../types';
import { getValueId } from '../../utils';
import DataLayerEntry from '../PDFViewer/DataLayerEntry';

type EmailLineProps = {
  text: string;
};

const EmailLine = ({ text }: EmailLineProps) => {
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export const highlightWords = (
  text: string,
  entriesToHighlight: AIExtractionEntryValue[]
) => {
  if (!text || !entriesToHighlight || entriesToHighlight.length === 0) {
    return text.split('\n').map((line, index) => {
      return (
        <React.Fragment key={`line-${index}`}>
          {!line ? <br /> : <EmailLine text={line} />}
          {index < line.length - 1 && <br />}
        </React.Fragment>
      );
    });
  }

  const entryWordMap = entriesToHighlight.reduce<{
    [key: string]: string[];
  }>((acc: any, entry) => {
    const id = getValueId(entry.id, entry.index);

    if (!acc[entry.value]) {
      acc[entry.value] = [id];
    } else {
      acc[entry.value].push(id);
    }

    return acc;
  }, {});

  const words = Object.keys(entryWordMap);
  const regex = new RegExp(`\\b(${words.join('|')})\\b`, 'gi');
  const lines = text.split('\n');
  const seenEntries: { [key: string]: boolean } = {};

  return lines.map((line, lineIndex) => {
    const parts = line.split(regex);

    const lineComponent = parts.map((part, index) => {
      const shouldHighlight = words.includes(part);
      if (shouldHighlight && entryWordMap[part]) {
        const entryIds = entryWordMap[part];
        const targetEntryId = entryIds.find((entryId) => !seenEntries[entryId]);

        if (targetEntryId) {
          seenEntries[targetEntryId] = true;
          const entry = entriesToHighlight.find(
            (entry) => getValueId(entry.id, entry.index) === targetEntryId
          );

          if (entry) {
            return (
              <DataLayerEntry key={index} entry={entry} absolute={false}>
                <EmailLine text={part} />
              </DataLayerEntry>
            );
          }
        }
      }

      return <EmailLine key={`line-${index}`} text={part} />;
    });

    return (
      <React.Fragment key={lineIndex}>
        {lineComponent}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });
};
