import { ComponentProps, useState } from 'react';
import useMounted from '../../../utils/useMounted';
import { Spinner } from '../Spinner';
import { Button } from './button';

interface LockoutButtonProps
  // Omit title because it once was used as the button content
  extends Omit<ComponentProps<typeof Button>, 'title'> {
  icon?: React.ReactNode;

  lockoutOverride?: boolean;
  setLockoutOverride?: (lockout: boolean) => void;

  loading?: boolean;
  enableLockout?: boolean;
}

/**
 * Shows a loading state while awaiting the onClick function.
 *
 * @component
 * @param {React.ReactNode} [props.icon] - Optional icon to display in the button. Replaced by spinner when loading or locked out.
 * @param {boolean} [props.lockoutOverride=false] - External control for the lockout state.
 * @param {function} [props.setLockoutOverride] - Function to set the external lockout state.
 * @param {boolean} [props.loading=false] - Indicates if the button is in a loading state.
 * @param {boolean} [props.enableLockout=true] - Enables or disables the lockout functionality.
 * @returns {React.Component} The LockoutButton component.
 */

function LockoutButton({
  icon,
  children,
  disabled,
  onClick = () => {},
  lockoutOverride = false,
  setLockoutOverride,
  loading = false,
  enableLockout = true,
  ...rest
}: LockoutButtonProps) {
  const [lockedOut, setLockedOut] = useState(false);
  const mounted = useMounted();
  const [thisButtonClicked, setThisButtonClicked] = useState(false);

  const handleClick = async (e: any) => {
    setThisButtonClicked(true);
    if (enableLockout) {
      setLockedOut(true);
      setLockoutOverride?.(true);
    }
    await onClick(e);
    // Only reset if still mounted
    if (mounted.current) {
      setLockedOut(false);
      setLockoutOverride?.(false);
    }
  };

  const isLockedOut = lockoutOverride || lockedOut;

  return (
    <Button
      onClick={handleClick}
      disabled={disabled || loading || isLockedOut}
      {...rest}
    >
      {loading || (isLockedOut && thisButtonClicked) ? (
        <Spinner className='w-4 h-4' />
      ) : (
        !!icon && icon
      )}
      {/* Must wrap text in span so Google Translate doesn't replace spinner during translation. That breaks the app */}
      <span style={{ display: 'contents' }}>{children}</span>
    </Button>
  );
}

export default LockoutButton;
