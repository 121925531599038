import { memo, useEffect } from 'react';
import { CloseIcon } from '../Icons';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import classNames from 'classnames';
import { ignoreDrawControls } from '../RenderingEngine/Controls/utils';
import { useAppSelector } from '../../hooks';

type Toast = {
  id: string;
  icon: () => React.ReactNode | undefined;
  toastBody: () => React.ReactNode | undefined;
  actionText?: string;
  action?: () => void;
  decay?: number;
  autohide?: boolean;
  show?: boolean;
  dismissible?: boolean;
  showIcon?: boolean;
};

type ToastProps = {
  toast: Toast;
  onClose: () => void;
};

const Toast = ({ toast, onClose = () => {} }: ToastProps) => {
  const {
    id,
    icon,
    toastBody,
    actionText,
    action = () => {},
    decay,
    autohide = true,
    show,
    dismissible,
    showIcon = true
  } = toast;

  const iconComponent = icon();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (show && autohide && decay) {
      timer = setTimeout(onClose, decay);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, autohide, decay, onClose]);

  if (!show) return null;

  return (
    <div
      key={`toast-${id}`}
      className={classNames(styles.toast, !iconComponent && styles.miniToast)}
    >
      {showIcon && <div className={styles.toastIcon}>{iconComponent}</div>}
      <div className={styles.toastText}>
        <div>{toastBody()}</div>
      </div>
      {actionText && (
        <div className={styles.toastAction} onClick={() => action()}>
          <div>{actionText}</div>
        </div>
      )}
      {dismissible && iconComponent && (
        <div className={styles.toastClose} onClick={() => onClose()}>
          <CloseIcon width={18} height={18} />
        </div>
      )}
    </div>
  );
};

type ToastStackProps = {
  toasts: Toast[];
  onClose: (id: string) => () => void;
};

const ToastStack = ({
  toasts = [],
  onClose = () => () => {}
}: ToastStackProps) => {
  return (
    <>
      {toasts.map((toast: any) => (
        <Toast key={toast.id} toast={toast} onClose={onClose(toast.id)} />
      ))}
    </>
  );
};

const ToastContainer = () => {
  const toasts = useAppSelector((state) => state.toasts);
  const {
    toasts: { hideToast, removeToast }
  } = useFeatheryRedux();

  const _toasts = Object.entries(toasts)
    .reduce<Toast[]>(
      (allToasts: Toast[], [id, toast]: any) => [
        ...allToasts,
        { id, ...toast }
      ],
      []
    )
    .reverse();

  function onClose(id: string) {
    return () => {
      hideToast({ id });
      setTimeout(() => removeToast({ id }), 500);
    };
  }

  return (
    <div className={classNames(styles.toastContainer, ignoreDrawControls)}>
      <ToastStack toasts={_toasts} onClose={onClose} />
    </div>
  );
};

export default memo(ToastContainer);
