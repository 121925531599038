import {
  CheckboxField,
  CollapsibleSection,
  DropdownField,
  PropertyLabel,
  TextField
} from '../../Core';
import { memo } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';

import { HideIfsSection } from './components';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { TYPE_VIDEO } from '../../../utils/elements';
import LogicSection from '../../FormFieldEditors/DefaultEditor/LogicSection';
import Col from '../../Core/Layout/Col';
import Row from '../../Core/Layout/Row';

function VideoPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const asset = getAsset(theme, TYPE_VIDEO, element.source_asset);
  const props = element.properties;

  function handlePropChange(newProps: any) {
    handleUpdates([
      elementOperation({ viewport, propUpdate: { properties: newProps } })
    ]);
  }

  function labelData(...propList: string[]) {
    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([
              elementOperation({ viewport, propReset: propList })
            ]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_VIDEO,
                  asset,
                  newProps: objectPick(props, propList),
                  newElementProps: objectPick(element, propList)
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      {(mode === 'builder' || asset) && (
        <CollapsibleSection title='Video' collapsible={false}>
          <Row>
            <Col sm='4'>
              <PropertyLabel label='Type' {...labelData('source_type')} />
            </Col>
            <Col>
              <DropdownField
                selected={props.source_type}
                onChange={(e: any) =>
                  handlePropChange({ source_type: e.target.value })
                }
                options={[
                  { value: 'iframe', display: 'Third-Party (e.g. Youtube)' },
                  { value: 'custom', display: 'Custom' }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <PropertyLabel label='Video URL' {...labelData('source_url')} />
            </Col>
            <Col>
              <TextField
                placeholder={
                  props.source_type === 'iframe'
                    ? 'https://youtube.com/embed/dmg36v0zYbM'
                    : 'https://hosted.com/myvid.mp4'
                }
                value={props.source_url}
                onComplete={(newUrl: any) =>
                  handlePropChange({ source_url: newUrl })
                }
                triggerCleanUp
              />
            </Col>
          </Row>
          {props.source_type === 'custom' && (
            <>
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label='Type'
                    {...labelData('video_extension')}
                  />
                </Col>
                <Col>
                  <DropdownField
                    selected={props.video_extension}
                    onChange={(e: any) =>
                      handlePropChange({ video_extension: e.target.value })
                    }
                    options={[
                      { value: 'video/mp4', display: 'MP4' },
                      { value: 'video/webm', display: 'WebM' },
                      { value: 'video/ogg', display: 'Ogg' }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    checked={props.muted}
                    text='Start Video Muted'
                    onChange={(isChecked) =>
                      handlePropChange({ muted: isChecked })
                    }
                  />
                </Col>
                <Col>
                  <CheckboxField
                    checked={props.controls}
                    text='Show Video Controls'
                    onChange={(isChecked) =>
                      handlePropChange({ controls: isChecked })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    checked={props.autoplay}
                    text='Autoplay'
                    onChange={(isChecked) =>
                      handlePropChange({ autoplay: isChecked })
                    }
                  />
                </Col>
                <Col>
                  <CheckboxField
                    checked={props.loop}
                    text='Loop'
                    onChange={(isChecked) =>
                      handlePropChange({ loop: isChecked })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </CollapsibleSection>
      )}

      {mode === 'builder' && (
        <>
          <LogicSection elementId={element.id} />
          <HideIfsSection
            element={element}
            hideIfs={element.hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}

      <CollapsibleSection title='Accessibility' expanded={false}>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='Aria Label' {...labelData('aria_label')} />
          </Col>
          <Col>
            <TextField
              value={element.properties.aria_label ?? ''}
              onComplete={(label: any) =>
                handlePropChange({
                  aria_label: label
                })
              }
              triggerCleanUp
            />
          </Col>
        </Row>
      </CollapsibleSection>
    </>
  );
}

export default memo(VideoPropertiesPanel);
