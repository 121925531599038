import { useMemo } from 'react';
import { JSONPathInput } from './JSONPathInput';
import {
  KeyComponentProps,
  KeyValueInput,
  ValueComponentProps
} from './KeyValueInput';
import useField from '../../../../utils/useField';
import { Button } from '../../../Core/Button/button';
import { DynamicTextTooltip } from '../../../Core';
import { cn } from '../../../../utils/cn';

type DataMapInput = {
  value?: { [key: string]: string };
  structure?: any;
  onChange?: (value: { [key: string]: string }) => void;
  showFieldSelector?: (index: number, selectedField: any) => void;
};

export const DataMapInput = ({
  value = {},
  structure = {},
  onChange = () => {},
  showFieldSelector = () => {}
}: DataMapInput) => {
  const KeyComponent = useMemo(() => {
    const Component = (props: KeyComponentProps) => {
      const getField = useField(true);
      const selectedField = useMemo(() => {
        const field = getField(props.value);

        if (field) {
          return {
            selectId: field.id
          };
        }
      }, [getField, props.value]);

      const overflow = props.value.length >= 24;
      return (
        <DynamicTextTooltip
          allowShow={overflow}
          text={props.value}
          maxLength={0}
        >
          <Button
            className={cn(
              'overflow-hidden !border-solid !border-neutral-border h-[32px]',
              overflow && 'justify-start'
            )}
            variant='outline'
            onClick={() => showFieldSelector(props.index, selectedField)}
          >
            <span className='truncate'>{props.value || 'Select Field'}</span>
          </Button>
        </DynamicTextTooltip>
      );
    };

    return Component;
  }, []);

  const ValueComponent = useMemo(() => {
    const Component = (props: ValueComponentProps) => {
      return (
        <JSONPathInput
          json={structure}
          selected={props.value}
          onChange={props.onComplete}
          {...props}
        />
      );
    };

    return Component;
  }, [structure]);

  return (
    <KeyValueInput
      value={value}
      onChange={onChange}
      KeyComponent={KeyComponent}
      ValueComponent={ValueComponent}
      addButtonText='Add field mapping'
    />
  );
};
