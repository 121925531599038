import { Fragment, useEffect, useMemo, useState } from 'react';
import { AIExtractionEntry } from '../types';
import { CaretIcon } from '../../../components/Icons';
import { useExtractionState, useExtractionUpdater } from '../context';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import { getValueId } from '../utils';
import AIEntryFieldValue from './AIEntryFieldValue';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  entries: AIExtractionEntry[];
  entities: any[];
  index: number;
  highlight?: boolean;
  onEdit?: (entry: any, newValue: string) => void;
  onDelete?: (index: number) => void;
};

const AIEntry = ({
  entries,
  entities,
  index,
  highlight = false,
  onEdit = () => {},
  onDelete = () => {}
}: Props) => {
  const [expanded, setExpanded] = useState(true);

  const valueIds = useMemo(() => {
    return entries.map((entry) => getValueId(entry, index));
  }, [entries]);

  const setSelectedValueId = useExtractionUpdater((s) => s.setSelectedValueId);
  const selectedValueId = useExtractionState((s) => {
    if (s.selectedValueId && valueIds.includes(s.selectedValueId)) {
      return s.selectedValueId;
    }
  });

  const getExpanded = useSyncedRefState(expanded);
  const getSelectedValueId = useSyncedRefState(selectedValueId);

  // Unselect the value when collapsing the entry
  useEffect(() => {
    if (!expanded && getSelectedValueId()) {
      setSelectedValueId(null);
    }
  }, [expanded]);

  // Auto expand the values if the selected value is in the list
  useEffect(() => {
    if (selectedValueId && !getExpanded()) {
      setExpanded(true);
    }
  }, [selectedValueId]);

  return (
    <div
      id={`entry-${index}`}
      className={classNames(
        styles.aiEntryField,
        {
          [styles.forceHighlight]: highlight
        },
        ...entries.map((entry) => `entry-${entry.id}`)
      )}
      data-expanded={expanded}
    >
      {entries.length > 1 && (
        <div
          className={styles.header}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <div
            className={styles.text}
            style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
          >
            Entry {index + 1}
          </div>
          <div className={classNames(styles.icon, { [styles.flip]: expanded })}>
            <CaretIcon width={20} height={20} />
          </div>
        </div>
      )}
      {(expanded || entries.length === 1) && (
        <div
          className={classNames(
            styles.content,
            entries.length > 1 && styles.separator
          )}
        >
          {entries.map((entry, entryIndex) => (
            <Fragment key={entry.id}>
              <div className={styles.header}>{entities[entryIndex].name}</div>
              <div className={styles.values}>
                <AIEntryFieldValue
                  entry={entry}
                  index={index}
                  onEdit={(newValue) => onEdit(entry, newValue)}
                  onDelete={() => onDelete(index)}
                />
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default AIEntry;
