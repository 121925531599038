/**
 * This is a wrapper around `MarginPaddingInput` and `PaddingInput` to make it easier to use with an element's
 * margin and padding configuration.
 */

import { MarginPaddingInput, PaddingInput, PropertyLabel } from '../index';
import {
  getMarginName,
  getMarginPaddingConfig,
  getMarginThemeProps,
  getPaddingName,
  getPaddingThemeProps,
  MarginPaddingConfig
} from '../../../utils/boxSpacingHelper';
import PaddingDisplay from '../../RenderingEngine/Controls/PaddingDisplay';
import MarginDisplay from '../../RenderingEngine/Controls/MarginDisplay';
import { ControlReference } from '../../RenderingEngine/Controls/ControlLayer';
import Row from '../Layout/Row';
import Col from '../Layout/Col';

const BoxSpacingInput = ({
  elementType,
  elementStyle,
  labelData,
  handleStyleChange
}: {
  elementType: string;
  elementStyle: any;
  labelData: any;
  handleStyleChange: any;
}) => {
  const marginPaddingConfig = getMarginPaddingConfig(elementType);

  const { usesMargin, usesPadding } = marginPaddingConfig;

  const renderer =
    usesMargin && usesPadding ? renderMarginPaddingInput : renderSpacingInput;

  return (
    <>
      {renderer({
        elementStyle,
        labelData,
        handleStyleChange,
        marginPaddingConfig
      })}
    </>
  );
};

export function BoxSpacingDisplay({ control }: { control: ControlReference }) {
  const element = control?.canvasReference?.node?.unlinked()?.element?.state;

  const elementType = element?._type || 'container';

  const marginPaddingConfig = getMarginPaddingConfig(elementType);

  if (
    marginPaddingConfig.usesPaddingForMargin &&
    !marginPaddingConfig.usesMargin
  ) {
    return <MarginDisplay control={control} />;
  }

  return (
    <>
      {marginPaddingConfig.usesPadding && <PaddingDisplay control={control} />}
      {marginPaddingConfig.usesMargin && <MarginDisplay control={control} />}
    </>
  );
}

const renderMarginPaddingInput = ({
  elementStyle,
  labelData,
  handleStyleChange,
  marginPaddingConfig
}: {
  elementStyle: any;
  labelData: any;
  handleStyleChange: any;
  marginPaddingConfig: MarginPaddingConfig;
}) => {
  const marginName = getMarginName(marginPaddingConfig);
  const paddingName = getPaddingName(marginPaddingConfig);

  const marginLabelProps = getMarginThemeProps(marginPaddingConfig);
  const paddingLabelProps = getPaddingThemeProps(marginPaddingConfig);

  return (
    <Row>
      <Col sm='3'>
        <PropertyLabel
          label='Margin & Padding'
          centered={false}
          {...labelData(...marginLabelProps, ...paddingLabelProps)}
        />
      </Col>
      <Col className='grid justify-center'>
        <MarginPaddingInput
          value={{
            marginTop: elementStyle[`${marginName}_top`] ?? 0,
            marginRight: elementStyle[`${marginName}_right`] ?? 0,
            marginBottom: elementStyle[`${marginName}_bottom`] ?? 0,
            marginLeft: elementStyle[`${marginName}_left`] ?? 0,
            paddingTop: elementStyle[`${paddingName}_top`] ?? 0,
            paddingRight: elementStyle[`${paddingName}_right`] ?? 0,
            paddingBottom: elementStyle[`${paddingName}_bottom`] ?? 0,
            paddingLeft: elementStyle[`${paddingName}_left`] ?? 0
          }}
          onComplete={(newPadding: any) =>
            handleStyleChange({
              [`${marginName}_top`]: newPadding.marginTop,
              [`${marginName}_right`]: newPadding.marginRight,
              [`${marginName}_bottom`]: newPadding.marginBottom,
              [`${marginName}_left`]: newPadding.marginLeft,
              [`${paddingName}_top`]: newPadding.paddingTop,
              [`${paddingName}_right`]: newPadding.paddingRight,
              [`${paddingName}_bottom`]: newPadding.paddingBottom,
              [`${paddingName}_left`]: newPadding.paddingLeft
            })
          }
        />
      </Col>
    </Row>
  );
};

const renderSpacingInput = ({
  elementStyle,
  labelData,
  handleStyleChange,
  marginPaddingConfig
}: {
  elementStyle: any;
  labelData: any;
  handleStyleChange: any;
  marginPaddingConfig: MarginPaddingConfig;
}) => {
  const marginName = getMarginName(marginPaddingConfig);
  const paddingName = getPaddingName(marginPaddingConfig);
  const labelOverride = marginPaddingConfig.labelOverride;

  const label = marginPaddingConfig.usesMargin ? 'Margin' : 'Padding';
  const name = marginPaddingConfig.usesMargin ? marginName : paddingName;

  const labelProps = marginPaddingConfig.usesMargin
    ? getMarginThemeProps(marginPaddingConfig)
    : getPaddingThemeProps(marginPaddingConfig);

  return (
    <Row>
      <Col sm='3'>
        <PropertyLabel
          label={`${labelOverride || label}`}
          centered={false}
          {...labelData(...labelProps)}
        />
      </Col>
      <Col className='grid justify-center'>
        <PaddingInput
          value={{
            top: elementStyle[`${name}_top`] ?? 0,
            right: elementStyle[`${name}_right`] ?? 0,
            bottom: elementStyle[`${name}_bottom`] ?? 0,
            left: elementStyle[`${name}_left`] ?? 0
          }}
          onComplete={(newPadding: any) =>
            handleStyleChange({
              [`${name}_top`]: newPadding.top,
              [`${name}_right`]: newPadding.right,
              [`${name}_bottom`]: newPadding.bottom,
              [`${name}_left`]: newPadding.left
            })
          }
        />
      </Col>
    </Row>
  );
};

export default BoxSpacingInput;
