import '../../style/dialog-form.css';

import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

const DEFAULT_REDTAIL_CONFIG = {
  username: '',
  password: ''
};

function RedtailSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.REDTAIL,
    panelId: formId
  });

  const [redtailConfig, setRedtailConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_REDTAIL_CONFIG
  );

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const updateConfig = (key: string) => (val: string) => {
    setRedtailConfig({ ...redtailConfig, [key]: val });
  };

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = Object.values(redtailConfig).some((entry) => !entry);
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: redtailConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.REDTAIL]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Username' />
          <TextField
            value={redtailConfig.username}
            onChange={updateConfig('username')}
            className={styles.marginBottom}
            error={isPartial && !redtailConfig.username}
          />
        </div>
        <div>
          <PropertyLabel label='Password' />
          <TextField
            type='password'
            value={redtailConfig.password}
            onChange={updateConfig('password')}
            className={styles.marginBottom}
            error={isPartial && !redtailConfig.password}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default RedtailSettingsSidebar;
