import { memo, useEffect, useState } from 'react';

import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import { useHistory } from 'react-router-dom';
import {
  CreateWorkspaceModal,
  DeleteWorkspaceModal
} from '../components/Modals';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '../hooks';
import useFeatheryRedux from '../redux';

function WorkspacesPage() {
  const history = useHistory();

  const { getWorkspaces } = useFeatheryRedux();

  const [workspaceToDelete, setWorkspaceToDelete] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const workspaces = useAppSelector((state) => state.accounts.workspaces);

  useEffect(() => getWorkspaces(), []);

  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className={classNames('page-head', styles.pageHeadTitle)}>
          Workspaces
        </h1>
      </div>
      <HeaderFilterResultsTable
        data={Object.values(workspaces)}
        columns={[{ key: 'name', name: 'Name' }]}
        defaultSort={{ order: 1, key: 'name' }}
        useSearch
        name='Workspace'
        onCreate={() => setIsCreating(true)}
        onSelect={(workspace: any) =>
          history.push(`/workspaces/${workspace.id}`)
        }
        onDelete={(workspace: any, e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setWorkspaceToDelete(workspace.id);
        }}
      />
      <CreateWorkspaceModal
        show={isCreating}
        setShow={(show: any) => setIsCreating(show)}
      />
      <DeleteWorkspaceModal
        setShow={() => setWorkspaceToDelete('')}
        show={Boolean(workspaceToDelete)}
        workspaceId={workspaceToDelete}
      />
    </>
  );
}

export default memo(WorkspacesPage);
