/* eslint-disable react-hooks/exhaustive-deps */

import { PropertyLabel } from '../../../Core';
import { useEffect, useMemo, useState } from 'react';
import { objectFilter, objectMap, objectRemove } from '../../../../utils/core';

import Delta from 'quill-delta';
import FontStyleSection from './FontStyleSection';
import { calculateOverrideObjects } from '../../utils';
import { elementOperation, getAsset } from '../../../../utils/themes';
import {
  getRichTextMixedStyles,
  getRichTextSameStyles
} from '../../../../utils/step';
import useViewport from '../../../../hooks/useViewport';
import { useAppSelector } from '../../../../hooks';
import Col from '../../../Core/Layout/Col';
import Row from '../../../Core/Layout/Row';

function initialAttributes(field: any) {
  return (
    (field.text_formatted.length > 0 && field.text_formatted[0].attributes) ||
    {}
  );
}

export default function RichTextEditor({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  level2,
  elementType,
  isAsset,
  labelCustomData,
  handleCustomStyleChange,
  customValues
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps
  );

  const textSelection = useAppSelector(
    (state) => state.formBuilder.textSelection
  );

  const [attrs, setAttrs] = useState(initialAttributes(element.properties));

  const { isMobile } = useViewport();

  const asset = getAsset(theme, elementType, element.source_asset);
  const assetTF = asset?.properties?.text_formatted;
  // Form elements inheriting rich text from assets is an especially complex case since both text and text styles
  // can change
  const handleRichTextFlattening = (mode === 'builder' || isMobile) && asset;

  const textFormatted = useMemo(
    () => new Delta(element.properties.text_formatted),
    [element.properties.text_formatted]
  );
  const [mixedStyles, sameStyles] = useMemo(() => {
    if (!handleRichTextFlattening || assetTF.length === 0) return [[], {}];

    const sameStyles = getRichTextSameStyles(assetTF);
    const mixedStyles = getRichTextMixedStyles(assetTF);
    return [mixedStyles, sameStyles];
  }, [assetTF, handleRichTextFlattening]);

  useEffect(() => {
    const attrIndex = textSelection ? textSelection.range.start : 0;
    const slice = textFormatted.slice(attrIndex, attrIndex + 1);
    const op = slice.ops[0];
    if (op) setAttrs(op.attributes ?? {});
  }, [textSelection, textFormatted]);

  useEffect(() => {
    if (textSelection)
      setAttrs(
        element.properties.text_formatted[textSelection.startIndex]
          ?.attributes ?? {}
      );
  }, [textSelection?.startIndex]);

  const handleFontSubmit = (newAttrs: any) => {
    let start, end;
    // Apply style to entire text if nothing is selected or editing asset in theme builder
    const selectedText = textSelection?.text;
    if (!selectedText || (mode === 'theme' && isAsset)) {
      start = 0;
      end = element.properties.text.length;
    } else {
      start = textSelection.range.start;
      end = textSelection.range.end;
    }

    const newFormattedText = textFormatted.compose(
      new Delta().retain(start).retain(end - start, newAttrs)
    );

    return newFormattedText.ops;
  };

  function getBaseStyle() {
    if (!handleRichTextFlattening)
      return isMobile ? { ...baseStyle, ...attrs } : baseStyle;

    // In mobile view, respect mobile styles over desktop styles
    const mobileStyles = isMobile
      ? {
          ...objectMap(
            objectFilter(sameStyles, ({ key }) => key.startsWith('mobile_')),
            ([k, v]) => [k.slice(7), v]
          ),
          ...attrs
        }
      : {};
    return { ...baseStyle, ...sameStyles, ...mobileStyles };
  }

  function getOverrideStyle() {
    if (mode === 'theme' && !isAsset) {
      return overrideStyle;
    }

    return isMobile
      ? objectMap(
          objectFilter(attrs, ({ key }) => key.startsWith('mobile_')),
          ([k, v]) => [k.slice(7), v]
        )
      : attrs;
  }

  function handleRichTextUpdates(operations: any) {
    if (mode === 'theme' && !isAsset) {
      handleUpdates(operations);
    } else {
      const newOperations: any = [];
      operations.forEach((op: any) => {
        const { type, styleUpdate, styleReset } = op;
        if (type === 'element') {
          // If the user updated the styles properties
          // We need to update the internal state of the Rich Text Editor
          if (styleUpdate) {
            const viewportUpdate = isMobile
              ? objectMap(styleUpdate, ([key, value]) => [
                  `mobile_${key}`,
                  value
                ])
              : styleUpdate;
            const newTextFormatted = handleFontSubmit({
              ...attrs,
              ...viewportUpdate
            });
            const newPropUpdate = { text_formatted: newTextFormatted };
            newOperations.push(
              elementOperation({
                viewport,
                propUpdate: { properties: newPropUpdate }
              })
            );
            return;
          }
          if (styleReset) {
            const viewportReset = isMobile
              ? styleReset.map((s: any) => `mobile_${s}`)
              : styleReset;
            const newTextFormatted = (
              element as any
            ).properties.text_formatted.map((chunk: any) => ({
              ...chunk,
              attributes: objectRemove(chunk.attributes, viewportReset)
            }));
            const newPropUpdate = { text_formatted: newTextFormatted };
            newOperations.push(
              elementOperation({
                viewport,
                propUpdate: { properties: newPropUpdate }
              })
            );
            return;
          }
        }
        newOperations.push(op);
      });

      handleUpdates(newOperations);
    }
  }

  const selectedText = textSelection?.text;
  return (
    <>
      {(mode === 'builder' || isAsset) && selectedText && (
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Selected Text' />
          </Col>
          <Col style={{ paddingTop: '7px' }}>
            {selectedText.length > 60
              ? selectedText.substr(0, 60) + '...'
              : selectedText}
          </Col>
        </Row>
      )}
      <FontStyleSection
        mode={mode}
        elementType={elementType}
        level2={level2}
        viewport={viewport}
        baseProps={baseProps}
        overrideProps={overrideProps}
        baseStyle={getBaseStyle()}
        overrideStyle={getOverrideStyle()}
        handleUpdates={handleRichTextUpdates}
        theme={theme}
        richTextMixedStyles={mixedStyles}
        labelCustomData={labelCustomData}
        handleCustomStyleChange={handleCustomStyleChange}
        customValues={customValues}
      />
    </>
  );
}
