import { memo, useCallback, useState } from 'react';
import useFeatheryRedux from '../../redux';

import '../../style/dialog-form.css';
import { Positive } from '../Core/Button';
import Dialog from '../Dialog';
import Label from '../Dialog/Label';
import { TextField } from '../Core';
import styles from '../Dialog/styles.module.scss';
import useModalSubmissionLockout from '../../utils/useModalSubmissionLockout';

function CreateWorkspaceModal({ show, setShow }: any) {
  const { createWorkspace } = useFeatheryRedux();

  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');
  const [brandUrl, setBrandUrl] = useState('');
  const [brandName, setBrandName] = useState('');

  const [error, setError] = useState('');

  const resetClose = () => {
    setName('');
    setLogo('');
    setBrandUrl('');
    setBrandName('');
    setError('');
    setShow(false);
  };

  const submit = useCallback(() => {
    return createWorkspace({
      name,
      logo,
      brand_url: brandUrl,
      brand_name: brandName
    })
      .then(() => resetClose())
      .catch((e: any) => setError(e.message));
  }, [name, logo, brandUrl, brandName]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog
      isOpen={show}
      title='Create a Workspace'
      size='sm'
      onClose={() => resetClose()}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <Label>Workspace Name</Label>
        <TextField
          required
          value={name}
          placeholder='New Client'
          onComplete={setName}
          className={styles.input}
        />
        <Label>Brand Logo URL</Label>
        <TextField
          type='url'
          value={logo}
          placeholder='https://brand.com/logo'
          onComplete={setLogo}
          className={styles.input}
        />
        <Label>Brand Name (Parent Company)</Label>
        <TextField
          value={brandName}
          placeholder='Parent Company'
          onComplete={setBrandName}
          className={styles.input}
        />
        <Label>Brand URL</Label>
        <TextField
          type='url'
          value={brandUrl}
          placeholder='https://brand.com'
          onComplete={setBrandUrl}
          className={styles.input}
        />
        <div className='dialog-form-action'>
          <Positive lockoutOverride={lockOutFlag}>Create</Positive>
        </div>
      </form>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(CreateWorkspaceModal);
