import { memo, useEffect, useRef, useState } from 'react';
import { DynamicTextTooltip } from '../../Core';
import styles from './styles.module.scss';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import classNames from 'classnames';

const FieldSelection = ({
  fieldId,
  fieldType,
  fieldKey,
  error,
  className,
  locked = false,
  placeholder = 'Field',
  showFieldSelector,
  ...props
}: any) => {
  const target = useRef(null);
  const container = useRef(null);

  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    if (showWarning) {
      const timeout = setTimeout(() => {
        setShowWarning(false);
      }, 2000);

      return () => {
        setShowWarning(false);
        clearTimeout(timeout);
      };
    }
  }, [showWarning, setShowWarning]);
  return (
    <div ref={container}>
      <DynamicTextTooltip text={fieldKey} maxLength={12}>
        <div
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            fieldKey && ruleStyles.selected,
            error && ruleStyles.fieldTargetError,
            locked && styles.locked,
            className
          )}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            if (locked) {
              setShowWarning(true);
              return;
            }
            showFieldSelector(fieldId, fieldType);
          }}
          ref={target}
          {...props}
        >
          <span className={ruleStyles.fieldSpan}>
            {fieldKey || placeholder}
          </span>
        </div>
      </DynamicTextTooltip>
    </div>
  );
};

export default memo(FieldSelection);
