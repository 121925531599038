import { Negative, Neutral, Positive } from '../../Core/Button';
import { memo, useMemo, useRef, useState } from 'react';

import Table from '../../Table';
import useFeatheryRedux from '../../../redux';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import classNames from 'classnames';

import { TextField } from '../../Core';
import { getStepElements } from '../../RenderingEngine/utils/gig';
import { deepEquals } from '../../../utils/core';
import { BackArrowIcon, SearchIcon } from '../../Icons';
import { useAppSelector } from '../../../hooks';
import useIntegrations from '../../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../FormIntegrations';

import {
  FEATHERY_CART_FIELD,
  FEATHERY_COLLABORATOR_FIELD,
  RESERVED_FEATHERY_PREFIX
} from '../../../utils/constants';
import ServarSelectorTab from './components/ServarSelectorTab';
import EditHiddenFieldModal from '../EditHiddenFieldModal';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Core/Tabs';

const DEFAULT_ONSELECT = ({ selectId, selectType }: SelectedFieldData) => {};

export interface SelectedFieldData {
  selectId: string;
  selectType: '' | 'servar' | 'hidden';
  selectServarType?: string;
}

const FieldSelector = ({
  className = '',
  selectId = '',
  selectType = '',
  currentStepId = '',
  show,
  setShow,
  disableSelect = false,
  onSelect = DEFAULT_ONSELECT,
  // If true, only leverage field state from the current form in production
  inFormBuilder = false,
  onlyHiddenFields = false,
  otherFormFields = true,
  selectTitle = 'Select',
  showOtherButton = false,
  otherButtonLabel = () => (
    <>
      <BackArrowIcon width={16} height={16} /> Back
    </>
  ),
  handleOtherButtonClick = async () => {
    setShow(false);
  },
  onChange = () => {},
  excludeServarTypes = [],
  includeServarTypes = null,
  includeReserved = false,
  hideButtons = false,
  copyFieldVariable = false
}: any) => {
  const {
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const [servars, steps] = useAppSelector(
    (state) =>
      inFormBuilder
        ? [
            Object.values(state.formBuilder.servars),
            Object.values(state.formBuilder.workingSteps)
          ]
        : [state.fields.servars ?? [], []],
    deepEquals
  );
  const org = useAppSelector((state) => state.accounts.organization);

  const { formId } = useParams<{ formId: string }>();
  const stripeIntegration = useIntegrations({
    type: INTEGRATIONS.STRIPE,
    panelId: formId
  });

  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields || []
  );
  const [selectData, _setSelectData] = useState<SelectedFieldData>({
    selectId,
    selectType
  });
  const [searchFilter, setSearchFilter] = useState('');
  const recentCreatedFields = useRef<string[]>([]);
  const [activeHiddenField, setActiveHiddenField] = useState<any>(null);
  const setSelectData = (data: SelectedFieldData) => {
    _setSelectData(data);
    onChange(data);
  };

  // In default ordering, prioritize fields that are being used in the current form
  const fieldsInUse = useMemo(() => {
    const fields = new Set();
    steps.forEach((step: any) => {
      getStepElements(step).forEach(([el]) =>
        el.hide_ifs.forEach((hi: any) => fields.add(hi.field_id))
      );
      step.next_conditions.forEach((cond: any) =>
        cond.rules.forEach((r: any) => fields.add(r.field_id))
      );
    });
    return fields;
  }, [steps]);

  const allFields = useMemo(
    () => [...servars, ...hiddenFields],
    [servars, hiddenFields]
  );
  const hiddenFieldSelectId = selectData.selectId;

  const [selectTab, setSelectTab] = useState(
    selectType || (onlyHiddenFields ? 'hidden' : 'servar')
  );

  const renderHiddenFieldData = useMemo(() => {
    const hiddenFieldData = [...hiddenFields]
      .filter(
        // Include all non-reserved (feathery. prefix) hidden fields or
        // if including reserved fields, include feathery. fields with certain
        // constraints:
        // * feathery.cart.* fields are included if stripe integration is enabled.
        // * feathery.collaborator is included if enterprise_features.collaboration on.
        // * feathery.account.* is included if enterprise_features.collaboration on
        (field) => {
          return (
            !field.key.startsWith(RESERVED_FEATHERY_PREFIX) ||
            (includeReserved &&
              ((field.key.startsWith(FEATHERY_CART_FIELD) &&
                stripeIntegration) ||
                ((field.key === FEATHERY_COLLABORATOR_FIELD ||
                  field.key.startsWith('feathery.account')) &&
                  org?.enterprise_features.collaboration)))
          );
        }
      )
      .filter(
        (field: any) =>
          !searchFilter ||
          field.key.toLowerCase().includes(searchFilter.toLowerCase())
      )
      .sort((x, y) => {
        const xRank = recentCreatedFields.current.indexOf(x.id);
        const yRank = recentCreatedFields.current.indexOf(y.id);
        if (xRank === -1 && yRank === -1) {
          if (fieldsInUse.has(x.id)) return -1;
          if (fieldsInUse.has(y.id)) return 1;
          return 0;
        } else if (xRank === -1) return 1;
        else if (yRank === -1) return -1;
        else if (xRank < yRank) return -1;
        else return 1;
      })
      .map((field) => ({
        ...field,
        keyInput: (
          <div className={styles.createHiddenFieldInput}>
            <span className={styles.fieldSelectorIDInput}>{field.key}</span>
          </div>
        )
      }));
    return hiddenFieldData;
  }, [hiddenFields, allFields, fieldsInUse, searchFilter]);

  const hiddenFieldTable = (
    <Table
      name='Hidden Field'
      data={renderHiddenFieldData}
      columns={[{ key: 'keyInput', name: 'ID' }]}
      showSelected={!disableSelect}
      onSelect={
        disableSelect
          ? undefined
          : (field: any) => {
              const newData: SelectedFieldData =
                field.id === selectData.selectId ||
                field.id === 'create-hidden-field'
                  ? {
                      selectId: '',
                      selectType: ''
                    }
                  : {
                      selectId: field.id,
                      selectType: 'hidden'
                    };
              setSelectData(newData);
            }
      }
      onCopy={
        copyFieldVariable
          ? (data: any) => {
              navigator.clipboard.writeText(`{{${data.key}}}`);
              setShow(false);
              addInfoToast(`{{${data.key}}} copied to clipboard`);
            }
          : undefined
      }
      onEdit={(field: any) => {
        if (field.key.startsWith(RESERVED_FEATHERY_PREFIX)) {
          addErrorToast({
            text: `Fields prefixed with '${RESERVED_FEATHERY_PREFIX}' are reserved and may not be edited.`
          });
          return;
        }
        setActiveHiddenField(field);
      }}
      initSelectId={hiddenFieldSelectId}
      type='modal'
      sortable={false}
    />
  );

  return (
    <div className={classNames(styles.fieldSelectorContainer, className)}>
      <div
        className={classNames(
          'search-wrap',
          styles.fieldSelectorSearch,
          onlyHiddenFields && styles.fieldSearchHiddenFields
        )}
      >
        <SearchIcon className='search-icon' />
        <TextField
          className='inp list-view-search'
          value={searchFilter}
          placeholder='Search'
          onComplete={(val: string) => setSearchFilter(val)}
          style={{ height: '30px' }}
        />
      </div>
      {onlyHiddenFields ? (
        hiddenFieldTable
      ) : (
        <Tabs value={selectTab} onValueChange={(tab) => setSelectTab(tab)}>
          <TabsList className='legacyTabsList' unstyled>
            <TabsTrigger className='legacyTabsTrigger' value='servar' unstyled>
              Form
            </TabsTrigger>
            <TabsTrigger className='legacyTabsTrigger' value='hidden' unstyled>
              Hidden
            </TabsTrigger>
            {inFormBuilder && otherFormFields && (
              <TabsTrigger
                className='legacyTabsTrigger'
                value='servarOther'
                unstyled
              >
                Other Forms
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent unstyled className='legacyTabsContent' value='servar'>
            <ServarSelectorTab
              selectData={selectData}
              setSelectData={setSelectData}
              searchFilter={searchFilter}
              currentStepId={currentStepId}
              inFormBuilder={inFormBuilder}
              excludeServarTypes={excludeServarTypes}
              includeServarTypes={includeServarTypes}
              fieldsInUse={fieldsInUse}
              disableSelect={disableSelect}
              copyFieldVariable={copyFieldVariable}
              setShow={setShow}
            />
          </TabsContent>
          <TabsContent unstyled className='legacyTabsContent' value='hidden'>
            {hiddenFieldTable}
          </TabsContent>
          {inFormBuilder && otherFormFields && (
            <TabsContent
              unstyled
              className='legacyTabsContent'
              value='servarOther'
            >
              <ServarSelectorTab
                selectData={selectData}
                setSelectData={setSelectData}
                searchFilter={searchFilter}
                currentStepId={currentStepId}
                inFormBuilder={inFormBuilder}
                excludeServarTypes={excludeServarTypes}
                includeServarTypes={includeServarTypes}
                fieldsInUse={fieldsInUse}
                currentForm={false}
                disableSelect={disableSelect}
                copyFieldVariable={copyFieldVariable}
                setShow={setShow}
              />
            </TabsContent>
          )}
        </Tabs>
      )}
      {!hideButtons && (
        <div
          className={classNames('text-center', styles.buttonContainer, {
            [styles.back]: showOtherButton
          })}
        >
          {showOtherButton && (
            <Negative onClick={handleOtherButtonClick}>
              {otherButtonLabel()}
            </Negative>
          )}
          <div className='tw-flex tw-items-center tw-gap-3'>
            {selectTab === 'hidden' && (
              <Neutral onClick={() => setActiveHiddenField({})}>
                Create New
              </Neutral>
            )}
            {disableSelect ? (
              <Positive
                onClick={async () => {
                  setShow(false);
                }}
              >
                Close
              </Positive>
            ) : (
              <Positive
                onClick={async () => {
                  onSelect(selectData);
                  setShow(false);
                }}
              >
                {selectTitle}
              </Positive>
            )}
          </div>
        </div>
      )}
      {activeHiddenField && (
        <EditHiddenFieldModal
          close={(fieldId: string | undefined) => {
            setActiveHiddenField(null);
            if (fieldId) {
              setSelectData({ selectId: fieldId, selectType: 'hidden' });
            }
          }}
          field={activeHiddenField}
          steps={steps}
          allFields={allFields}
          key={activeHiddenField}
        />
      )}
    </div>
  );
};

export default memo(FieldSelector);
