import { AIExtractionRun } from '../../types';
import { useEffect, useRef, useState } from 'react';
import { usePDFState } from '../../context';
import Viewer from './Viewer';
import styles from '../styles.module.scss';

type Props = {
  run?: AIExtractionRun;
};

const EmailViewer = ({ run }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const zoom = usePDFState((s) => s.zoom);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.width = `${800 * zoom}px`;
      ref.current.style.fontSize = `${zoom}rem`;
    }
  }, [zoom, height]);

  if (!run || !run.emailContent) {
    return null;
  }

  return (
    <div ref={ref} className={styles.emailViewerContainer}>
      <Viewer run={run} email={run.emailContent} />
    </div>
  );
};

export default EmailViewer;
