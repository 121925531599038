import '../../style/dialog-form.css';

import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

function TelesignSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.TELESIGN,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);

  const secretMeta = integration?.data.secret_metadata ?? {};

  const [err, setErr] = useState('');
  const [customerId, setCustomerId] = useState(secretMeta.customer_id ?? '');
  const [apiKey, setApiKey] = useState(secretMeta.api_key ?? '');

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = !customerId || !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        customer_id: customerId,
        api_key: apiKey
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.TELESIGN]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Customer ID' />
          <TextField
            placeholder='Enter Customer ID'
            value={customerId || ''}
            onChange={setCustomerId}
            error={isPartial && (!customerId || customerId === '')}
          />
        </div>
        <div>
          <PropertyLabel label='API Key' />
          <TextField
            type='password'
            placeholder='Enter API Key'
            value={apiKey || ''}
            onChange={setApiKey}
            error={isPartial && (!apiKey || apiKey === '')}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default TelesignSettingsSidebar;
