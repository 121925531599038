import { ComponentProps, forwardRef, memo, Ref, useMemo } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface DropdownFieldProps extends ComponentProps<'select'> {
  selected?: ComponentProps<'select'>['value'];
  options?:
    | { display: string; value: string; hidden?: boolean; disabled?: boolean }[]
    | string[]
    | number[];
  className?: string;
  placeholder?: string;
  allowNoSelection?: boolean;
  hideCaret?: boolean;
  error?: boolean;
  errorMessage?: string;
}

const DropdownField = (
  {
    selected,
    options = [],
    className = '',
    placeholder,
    allowNoSelection = false,
    hideCaret = false,
    error = false,
    errorMessage = '',
    ...props
  }: DropdownFieldProps,
  ref: Ref<HTMLSelectElement>
) => {
  const optionObjects = useMemo(
    () =>
      options.map((o) =>
        typeof o === 'object'
          ? o
          : { value: o, display: o, hidden: false, disabled: false }
      ),
    [options]
  );
  const selectedInOptions = useMemo(
    () => optionObjects.some((o) => o.value === selected),
    [optionObjects, selected]
  );

  return (
    <>
      <select
        value={selected}
        className={classNames(
          styles.dropdown,
          !hideCaret && styles.dropdownCaret,
          className,
          { [styles.error]: error }
        )}
        {...props}
        ref={ref}
      >
        {placeholder && (
          <option key='placeholder' hidden value=''>
            {placeholder}
          </option>
        )}
        {/* If selected is not a valid option, select a hidden, blank option */}
        {!allowNoSelection && !selectedInOptions && (
          <option value={selected} hidden />
        )}
        {optionObjects.map((option) => (
          <option
            key={option.value ?? option.display}
            value={option.value}
            hidden={option.hidden}
            disabled={option.disabled}
          >
            {option.display}
          </option>
        ))}
      </select>
      {error && <div className={styles.errorText}>{errorMessage}</div>}
    </>
  );
};

export default memo(
  forwardRef<HTMLSelectElement, DropdownFieldProps>(DropdownField)
);
