import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { Negative, Neutral } from '../Core/Button';
import Dialog from '../Dialog';

function DeleteWorkspaceModal({ show, setShow, workspaceId }: any) {
  const { deleteWorkspace } = useFeatheryRedux();
  const history = useHistory();
  const [error, setError] = useState('');

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      alignCenter
      title='Delete Workspace'
      size='xsm'
    >
      <div style={{ padding: '16px 8px' }}>
        Are you sure you want to delete this workspace?
      </div>
      <div className='dialog-form-action'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Negative
          onClick={async (event: any) => {
            event.preventDefault();
            try {
              await deleteWorkspace({ workspaceId });
              setShow(false);
              history.push('/workspaces');
            } catch (error) {
              // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
              setError(error.message);
            }
          }}
        >
          Delete
        </Negative>
      </div>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(DeleteWorkspaceModal);
