import '../../style/dialog-form.css';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { InlineTooltip, PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

function SFTPSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.SFTP,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);

  const secretMetadata = integration?.data.secret_metadata ?? {};

  const [err, setErr] = useState('');
  const [hostname, setHostname] = useState(secretMetadata.hostname ?? '');
  const [username, setUsername] = useState(secretMetadata.username ?? '');
  const [password, setPassword] = useState(secretMetadata.password ?? '');
  const [folderPath, setFolderPath] = useState(
    secretMetadata.folder_path ?? ''
  );

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = !hostname || !username || !password || !folderPath;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        hostname,
        username,
        password,
        folder_path: folderPath
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SFTP]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Host Name' />
          <TextField
            value={hostname}
            onChange={setHostname}
            error={isPartial && !hostname}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div>
          <PropertyLabel label='Username' />
          <TextField
            value={username}
            onChange={setUsername}
            error={isPartial && !username}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div>
          <PropertyLabel label='Password' />
          <TextField
            value={password}
            onChange={setPassword}
            type='password'
            error={isPartial && !password}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <PropertyLabel label='Folder Path' />
            <InlineTooltip
              text='This is your SFTP folder path you wish to store all uploaded documents to.'
              inline
            />
          </div>
          <TextField
            placeholder='/Shared/Documents'
            value={folderPath || ''}
            onChange={setFolderPath}
            error={isPartial && !folderPath}
            style={{ marginBottom: '10px' }}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default SFTPSettingsSidebar;
