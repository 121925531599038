import '../../style/dialog-form.css';

import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { DropdownField, PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

export default function AlloySettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.ALLOY,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const secretMeta = integration?.data.secret_metadata ?? {};
  const [workflowToken, setWorkflowToken] = useState(
    secretMeta.workflow_token ?? ''
  );
  const [workflowSecret, setWorkflowSecret] = useState(
    secretMeta.workflow_secret ?? ''
  );

  const meta = integration?.data.metadata ?? {};
  const [environment, setEnvironment] = useState(
    meta.environment ?? 'production'
  );
  const [sdkKey, setSdkKey] = useState(meta.sdk_key ?? '');

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !workflowToken || !workflowSecret;
      if (partial) setErr('Required fields must be filled');

      setIsPartial(partial);
      if (partial) return;
    }

    setErr('');
    return {
      isUpdate: integration?.data,
      metadata: { environment, sdk_key: sdkKey },
      secretMetadata: {
        workflow_token: workflowToken,
        workflow_secret: workflowSecret
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.ALLOY]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Environment Type' />
          <DropdownField
            selected={environment}
            onChange={(event: any) => setEnvironment(event.target.value)}
            options={[
              { value: 'production', display: 'Production' },
              { value: 'sandbox', display: 'Sandbox' }
            ]}
          />
        </div>
        <div>
          <PropertyLabel label='SDK Key' />
          <TextField
            value={sdkKey}
            onChange={setSdkKey}
            error={isPartial && !sdkKey}
          />
        </div>
        <div>
          <PropertyLabel label='Workflow Token' />
          <TextField
            value={workflowToken}
            onChange={setWorkflowToken}
            error={isPartial && !workflowToken}
          />
        </div>
        <div>
          <PropertyLabel label='Workflow Secret' />
          <TextField
            value={workflowSecret}
            onChange={setWorkflowSecret}
            error={isPartial && !workflowSecret}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}
