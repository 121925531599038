import '../../../style/dialog-form.css';
import {
  CheckboxField,
  DropdownField,
  DropdownMultiField,
  TextField
} from '../../../components/Core';
import styles from '../styles.module.scss';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import { WarningIcon } from '../../../components/Icons';
import { produce } from 'immer';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { linearizeFlow } from '../../../components/Panels/FlowPanel/linearFlow';
import TriggerLabel from './TriggerLabel';
import Row from '../../../components/Core/Layout/Row';
import Col from '../../../components/Core/Layout/Col';

// Event types
const formTriggerEvents = {
  submit: 'Form Step is Submitted',
  load: 'Form Step is Loaded',
  change: 'Field Value is Changed',
  form_complete: 'Form is Completed',
  error: 'Form Runs into Error',
  view: 'Form Element is Viewed',
  action: 'Form Element is Clicked'
};
export const stepEvents = ['submit', 'load'];
export const elementEvents = ['view', 'change', 'action'];
const titleMap: Record<string, string> = {
  change: 'Fields',
  view: 'Elements',
  action: 'Elements'
};

const aiTriggerEvents = {
  post_extraction: 'Extraction is Completed'
} as const;
export type LogicTriggerEvent =
  | keyof typeof formTriggerEvents
  | keyof typeof aiTriggerEvents;

export function getTriggerEvents(type: string) {
  return type === 'form' ? formTriggerEvents : aiTriggerEvents;
}

export const elementLabelMap = {
  subgrids: 'Container',
  texts: 'Text',
  buttons: 'Button',
  images: 'Image',
  videos: 'Video',
  progress_bars: 'Progress Bar',
  servar_fields: 'Field'
};
type ElementLabelAttr = keyof typeof elementLabelMap;

export function getElementLogicId(element: any, attr: string): string {
  return attr === 'servar_fields' ? element.servar.id : element.id;
}

function getStepElements(steps: any[], attrs: ElementLabelAttr[]) {
  const options: any = [];
  steps.forEach((step) => {
    attrs.forEach((attr) => {
      const label = elementLabelMap[attr];
      [...step[attr]]
        .sort((a: any, b: any) => {
          a = a.position;
          b = b.position;
          return a > b ? 1 : b > a ? -1 : 0;
        })
        .forEach((el: any) => {
          let labelVal = '';
          const value = getElementLogicId(el, attr);
          if (attr === 'subgrids') {
            // TODO: Unpublished containers won't have an ID yet. We should allow them to
            if (!value) return;
            labelVal = el.key;
          } else if (attr === 'servar_fields') labelVal = el.servar.key;
          else labelVal = el.id;
          options.push({
            value,
            label: `${label}: ${labelVal}`,
            group: step.key
          });
        });
    });
  });
  return options;
}

export default function RuleFieldsPanel({
  rule,
  setRule,
  steps,
  fieldsOnly = false,
  validation = false
}: any) {
  const { formId } = useParams<{ formId?: string }>();

  const type = formId ? 'form' : 'ai';
  const event = rule.trigger_event as string;
  const colProps = {
    md: fieldsOnly ? '12' : '6',
    sm: '12'
  } as const;

  const [stepList] = useMemo(() => linearizeFlow(steps), [steps]);

  let condition = null;
  if (elementEvents.includes(event)) {
    let attrs: ElementLabelAttr[] = [];
    if (event === 'change') attrs = ['servar_fields'];
    else if (event === 'action') attrs = ['buttons', 'texts', 'subgrids'];
    else if (event === 'view')
      attrs = [
        'servar_fields',
        'buttons',
        'texts',
        'images',
        'videos',
        'progress_bars'
      ];

    condition = (
      <>
        <TriggerLabel entity={titleMap[event]} rule={rule} steps={steps} />
        <DropdownMultiField
          required
          options={getStepElements(stepList, attrs)}
          selected={rule.elements}
          placeholder={event === 'change' ? 'Select fields' : 'Select elements'}
          onChange={(items: { value: string }[]) => {
            setRule({ ...rule, elements: items.map((item) => item.value) });
          }}
        />
        {event === 'action' && (
          <CheckboxField
            checked={rule.metadata?.after_click}
            text='Run after element click actions'
            onChange={(isChecked) => {
              const newRule = produce(rule, (draft: any) => {
                draft.metadata.after_click = isChecked;
              });
              setRule(newRule);
            }}
            style={{ marginTop: '10px' }}
          />
        )}
        {validation && (!rule.elements || rule.elements.length === 0) && (
          <div className={styles.error}>
            <WarningIcon width={24} height={24} color='#d6504a' />
            <span>
              {`At least one ${
                event === 'change' ? 'field' : 'element'
              } is required.`}
            </span>
          </div>
        )}
      </>
    );
  } else if (stepEvents.includes(event)) {
    condition = (
      <>
        <TriggerLabel entity='Form steps' rule={rule} steps={steps} />
        <DropdownMultiField
          options={Object.values(steps)
            .sort((a: any, b: any) =>
              a.key > b.key ? 1 : b.key > a.key ? -1 : 0
            )
            .map((workingStep: any) => ({
              value: workingStep.id,
              label: workingStep.key
            }))}
          selected={rule.steps}
          onChange={(items: { value: string }[]) => {
            setRule({ ...rule, steps: items.map((item) => item.value) });
          }}
        />
        {event === 'submit' && (
          <CheckboxField
            checked={rule.metadata?.after_click}
            text='Run after form data is submitted'
            onChange={(isChecked) => {
              const newRule = produce(rule, (draft: any) => {
                draft.metadata.after_click = isChecked;
              });
              setRule(newRule);
            }}
            style={{ marginTop: '10px' }}
          />
        )}
      </>
    );
  }

  return (
    <Row
      className={classNames(styles.ruleFieldPanel, {
        [styles.card]: !fieldsOnly
      })}
    >
      <Col {...colProps} className={styles.ruleFieldPanelCol}>
        <div className={styles.ruleField}>
          <Label className={styles.fieldLabel}>Event that triggers rule</Label>
          <DropdownField
            required
            selected={rule.trigger_event}
            options={Object.entries(getTriggerEvents(type)).map(
              ([value, display]) => ({
                value,
                display
              })
            )}
            onChange={(event: any) => {
              const triggerEvent = event.target.value;
              const newRule = { ...rule, trigger_event: triggerEvent };
              // clear out additional trigger configs contextually
              if (!stepEvents.includes(triggerEvent)) newRule.steps = [];
              if (!elementEvents.includes(triggerEvent)) newRule.elements = [];
              setRule(newRule);
            }}
          />
        </div>
        {condition && <div className={styles.ruleField}>{condition}</div>}
      </Col>
      <Col {...colProps} className={classNames(styles.ruleFieldPanelCol)}>
        <div className={styles.ruleField}>
          <Label className={styles.fieldLabel}>Description (optional)</Label>
          <TextField
            placeholder='Describe your rule'
            value={rule.description}
            onComplete={(description: string) =>
              setRule({ ...rule, description: description.trim() })
            }
          />
        </div>
      </Col>
    </Row>
  );
}
