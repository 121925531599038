import { useState } from 'react';

import { ChevronDownIcon } from '../../Icons';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '../Collapsible';
import { cn } from '../../../utils/cn';

export default function CollapsibleSection({
  title = '',
  expanded = true,
  collapsible = true,
  separator = true,
  resetHeaderStyle = false,
  showLabel = false,
  highlighted = false,
  hasAssets = false,
  isAssetSelected = false,
  customClasses = {},
  mouseDownTheme = () => {},
  style = {},
  children,
  isReversed = false,
  iconOnLeft = false,
  iconSize = 16
}: any) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const onMouseDown = (valid: boolean) => (event: any) => {
    if (!valid) return;

    event.stopPropagation();
    mouseDownTheme();
  };

  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={setIsExpanded}
      className={cn('w-full', separator && 'border-b', customClasses.root)}
      style={style}
    >
      {!isReversed && (
        <CollapsibleLabel
          title={title}
          showLabel={showLabel}
          isExpanded={isExpanded}
          collapsible={collapsible}
          iconOnLeft={iconOnLeft}
          resetHeaderStyle={resetHeaderStyle}
          highlighted={highlighted}
          hasAssets={hasAssets}
          isAssetSelected={isAssetSelected}
          customClasses={customClasses}
          handleMouseDown={onMouseDown}
          iconSize={iconSize}
        />
      )}
      <CollapsibleContent
        className={cn(
          'p-4',
          isReversed && 'pb-0',
          !isReversed && 'pt-0',
          resetHeaderStyle && 'p-0 m-0',
          'overflow-hidden',
          'data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down',
          customClasses.content
        )}
      >
        {children}
      </CollapsibleContent>
      {isReversed && (
        <CollapsibleLabel
          title={title}
          showLabel={showLabel}
          isExpanded={isExpanded}
          collapsible={collapsible}
          iconOnLeft={iconOnLeft}
          resetHeaderStyle={resetHeaderStyle}
          highlighted={highlighted}
          hasAssets={hasAssets}
          isAssetSelected={isAssetSelected}
          customClasses={customClasses}
          handleMouseDown={onMouseDown}
          iconSize={iconSize}
        />
      )}
    </Collapsible>
  );
}
const CollapsibleIcon = ({
  isExpanded,
  iconSize,
  customClasses,
  onMouseDown
}: {
  isExpanded: boolean;
  iconSize: number;
  customClasses?: string;
  onMouseDown: (event: React.MouseEvent) => void;
}) => (
  <div
    className={cn(
      'transition-transform duration-200',
      isExpanded ? 'rotate-180' : 'rotate-0',
      customClasses
    )}
    onMouseDown={onMouseDown}
  >
    <ChevronDownIcon
      className='stroke-current'
      width={iconSize}
      height={iconSize}
    />
  </div>
);

const CollapsibleLabel = ({
  title,
  showLabel,
  isExpanded,
  collapsible,
  iconOnLeft,
  resetHeaderStyle,
  highlighted,
  hasAssets,
  isAssetSelected,
  customClasses,
  handleMouseDown,
  iconSize
}: {
  title: string;
  showLabel?: boolean;
  isExpanded: boolean;
  collapsible?: boolean;
  iconOnLeft?: boolean;
  resetHeaderStyle?: boolean;
  highlighted?: boolean;
  hasAssets?: boolean;
  isAssetSelected?: boolean;
  customClasses?: any;
  handleMouseDown: (valid: boolean) => (event: any) => void;
  iconSize: number;
}) => {
  if (!title) return null;

  const icon = (
    <CollapsibleIcon
      isExpanded={isExpanded}
      iconSize={iconSize}
      customClasses={customClasses?.expandIndicator}
      onMouseDown={handleMouseDown(isExpanded)}
    />
  );

  return (
    <div
      className={cn(
        resetHeaderStyle && 'p-0 m-0',
        highlighted && 'bg-muted',
        isExpanded && hasAssets && 'border-b',
        isAssetSelected && 'bg-accent',
        customClasses?.header
      )}
    >
      <CollapsibleTrigger
        className={cn(
          'flex items-center w-full px-4 py-4 text-left',
          !isExpanded && collapsible && 'cursor-pointer hover:bg-accent/50',
          'm-0 font-semibold',
          customClasses?.header
        )}
        onMouseDown={handleMouseDown(!isExpanded)}
      >
        {collapsible && iconOnLeft && icon}
        <div
          onMouseDown={handleMouseDown(isExpanded)}
          className={cn('flex-1', collapsible && 'cursor-pointer')}
        >
          <div
            className={cn(
              'text-md font-bold flex items-center',
              customClasses?.title
            )}
          >
            {title}
          </div>
          {showLabel && (
            <div className='text-xs text-muted-foreground'>Primary</div>
          )}
        </div>
        {collapsible && !iconOnLeft && icon}
      </CollapsibleTrigger>
    </div>
  );
};
