import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '../../../utils/cn';
import { ReactNode } from 'react';

const TooltipProvider = TooltipPrimitive.Provider;

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  asChild?: boolean;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'center' | 'start' | 'end';
  className?: string;
  disabled?: boolean;
  delay?: number;
  sideOffset?: number;
}

const Tooltip = ({
  children,
  content,
  asChild = true,
  side = 'top',
  align = 'center',
  className = '',
  disabled = false,
  sideOffset,
  delay = 0
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Root delayDuration={delay}>
      <TooltipPrimitive.Trigger asChild={asChild}>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        {!disabled && (
          <TooltipPrimitive.Content
            side={side}
            align={align}
            sideOffset={sideOffset}
            className={cn(
              'z-[999999999] overflow-hidden',
              'max-w-[28ch] mx-2 text-center',
              'rounded-md bg-black/95 px-3 py-1.5 text-sm text-white break-words',
              'animate-in fade-in-0 zoom-in-95',
              'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
              'data-[side=bottom]:slide-in-from-top-2',
              'data-[side=left]:slide-in-from-right-2',
              'data-[side=right]:slide-in-from-left-2',
              'data-[side=top]:slide-in-from-bottom-2',
              className
            )}
          >
            {content}
          </TooltipPrimitive.Content>
        )}
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

export { Tooltip, TooltipProvider };
