import {
  InlineTooltip,
  NumberInput,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { FieldSelectorWithModal } from '../../../components/Modals';
import styles from '../../FormSettingsPage/styles.module.scss';

const SignaturePropertiesSection = ({ title, formData, updateData }: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Enable Email Verification
            <InlineTooltip
              text='Email verification is often used as proof of identity for e-signatures. Turning this off may impact the verifiability of the e-signature.'
              inline
              warning={true}
            />
          </p>
          <YesNoSwitch
            id='verify-email'
            checked={formData.verify_email}
            onCheckedChange={(value) => updateData('verify_email', value)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Save Signed Document URL
            <InlineTooltip
              text="After the document is signed, save a link to the file in a hidden field if it's associated with a form submission."
              inline
            />
          </p>
          <FieldSelectorWithModal
            onlyHiddenFields
            selectId={formData.signature_save_field}
            selectType='hidden'
            placeholder='Select'
            onSelect={(data) =>
              updateData('signature_save_field', data.selectId ?? '')
            }
            className={styles.noMarginLeft}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Enable Envelope Expiration
            <InlineTooltip
              text='The envelope must be signed within a set number of days before expiration.'
              inline
            />
          </p>
          <YesNoSwitch
            id='envelope-expires'
            checked={formData.expire_days != null}
            onCheckedChange={(value) =>
              updateData('expire_days', value ? 5 : null)
            }
          />
        </label>
      </div>
      {formData.expire_days != null && (
        <div className='field-group'>
          <label>
            <p className='form-label'>Days Until Envelope Expires</p>
            <NumberInput
              classNames={{ root: 'form-control' }}
              min={1}
              max={365}
              value={formData.expire_days}
              onComplete={({ value }: any) => updateData('expire_days', value)}
            />
          </label>
        </div>
      )}
    </SettingsContainer>
  );
};

export default SignaturePropertiesSection;
