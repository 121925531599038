import { useEffect, useRef, useState } from 'react';
import { AIExtractionEntry } from '../types';
import { getValueId } from '../utils';
import { useExtractionState, useExtractionUpdater } from '../context';
import {
  AIStarIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  TrashIcon
} from '../../../components/Icons';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';

type Props = {
  entry: AIExtractionEntry;
  index: number;
  onEdit?: (newValue: string) => void;
  onDelete?: () => void;
};

const AIEntryFieldValue = ({
  entry,
  index,
  onEdit = () => {},
  onDelete = () => {}
}: Props) => {
  const [editedText, setEditedText] = useState('');
  const valueRef = useRef<HTMLDivElement>(null);
  const valueId = getValueId(entry, index);

  let entryValue = entry.value[index];
  if (typeof entryValue === 'boolean') entryValue = entryValue ? 'Yes' : 'No';
  if (Array.isArray(entryValue)) entryValue = entryValue.join(', ');

  const setEditingValueId = useExtractionUpdater((s) => s.setEditingValueId);
  const setSelectedValueId = useExtractionUpdater((s) => s.setSelectedValueId);
  const setHoveredValueId = useExtractionUpdater((s) => s.setHoveredValueId);

  const isHovered = useExtractionState((s) => s.hoveredValueId === valueId);
  const isHoveredFromPanel = useExtractionState((s) => {
    return s.hoveredValueId === valueId && s.activeComponent === 'panel';
  });

  const isSelected = useExtractionState((s) => s.selectedValueId === valueId);
  const isEditing = useExtractionState((s) => s.editingValueId === valueId);
  const isEditingAnotherEntry = useExtractionState(
    (s) => s.editingValueId && s.editingValueId !== valueId
  );

  useEffect(() => {
    if (isEditing) {
      valueRef.current?.focus();
    } else {
      setEditedText('');
    }
  }, [isEditing]);

  // Helper for starting the edit of the value
  const handleStartEdit = () => {
    if (entry.xCoordinates[index] >= 0) {
      setSelectedValueId(valueId);
    }

    setEditingValueId(valueId);
  };

  // Handler for cancelling editing the value
  const handleCancelEdit = () => {
    if (valueRef.current) {
      if (entryValue) {
        valueRef.current.textContent = entryValue;
      } else {
        valueRef.current.textContent = 'NO DATA';
      }
    }

    setEditingValueId(null);
  };

  // Handler for finalizing the edit made to the value being edited
  const handleEditValue = (newValue?: string) => {
    onEdit(newValue ? newValue : editedText);
    setEditingValueId(null);
  };

  const isAIGenerated =
    entry.sources?.[index] !== undefined
      ? entry.sources[index] === 'email' || entry.sources[index] === 'document'
      : entry.xCoordinates[index] >= 0;
  const hasBoundingBox = entry.xCoordinates[index] >= 0;
  const isDisabled = !entryValue || !hasBoundingBox;

  return (
    <div
      key={`${entry.id}-${index}`}
      data-id={`${entry.id}-${index}`}
      className={classNames(styles.value, {
        [styles.selected]: !isDisabled && isSelected,
        [styles.hovered]: !isDisabled && isHovered,
        [styles.editing]: isEditing
      })}
      onDoubleClick={() => handleStartEdit()}
      onMouseEnter={() =>
        !isEditingAnotherEntry && setHoveredValueId(valueId, 'panel')
      }
      onMouseLeave={() => !isEditingAnotherEntry && setHoveredValueId(null)}
      onClick={() => {
        if (!isDisabled && !isEditingAnotherEntry) {
          setSelectedValueId(isSelected ? null : valueId);
        }
      }}
    >
      {isAIGenerated && (
        <div
          className={classNames(styles.icon, {
            [styles.red]: !isDisabled,
            [styles.grey]: isDisabled
          })}
        >
          <Tooltip
            disabled={!entryValue || hasBoundingBox}
            content='Location of the value on the document is undetermined.'
            sideOffset={5}
            delay={750}
          >
            <div>
              <AIStarIcon height={14} />
            </div>
          </Tooltip>
        </div>
      )}
      <div
        ref={valueRef}
        className={classNames(styles.text, {
          [styles.tag]: !entryValue && !isEditing
        })}
        contentEditable={isEditing}
        suppressContentEditableWarning
        onInput={(e) => setEditedText(e.currentTarget.textContent ?? '')}
        onBlur={(e) => {
          if (isEditing) {
            handleEditValue(e.currentTarget.textContent ?? '');
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleEditValue(e.currentTarget.textContent ?? '');
          }
        }}
      >
        {entryValue ? entryValue : isEditing ? '' : 'NO DATA'}
      </div>
      {(isHoveredFromPanel || isEditing) && (
        <div
          className={classNames(
            styles.actions,
            !entryValue && !isEditing && styles.end
          )}
        >
          {isEditing ? (
            <>
              <div
                onMouseDown={(e) => {
                  // onMouseDown over onClick due to the onBlur on the `valueRef` div.
                  // This needs to fire first for proper behaviour.
                  e.preventDefault();
                  e.stopPropagation();
                  handleCancelEdit();
                }}
              >
                <CloseIcon
                  className={styles.fill}
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-1px' }}
                />
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditValue();
                }}
              >
                <CheckIcon
                  className={styles.innerStroke}
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  stroke='1'
                  style={{ marginTop: '1px' }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleStartEdit();
                }}
              >
                <EditIcon
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-2px' }}
                />
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <TrashIcon
                  height={18}
                  width={18}
                  color='var(--grey-70)'
                  style={{ marginTop: '-2px' }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AIEntryFieldValue;
