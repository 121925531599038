import { useCallback } from 'react';
import { getCookie } from './browserUtils';
import useLogout, { getWorkspaceJwt } from './useLogout';
import { cognitoPoolConfig, isAmplifyConfigured } from './sso';

export const getCognitoJwt = () => {
  const lastUserKey = `CognitoIdentityServiceProvider.${cognitoPoolConfig.userPoolWebClientId}.LastAuthUser`;
  const lastSsoUser = window.localStorage.getItem(lastUserKey);
  // There is an amplify fn to get the session, but it is async and requires
  // changing all code that consumes this function to use
  return (
    window.localStorage.getItem(
      `CognitoIdentityServiceProvider.${cognitoPoolConfig.userPoolWebClientId}.${lastSsoUser}.idToken`
    ) ?? ''
  );
};

export default function useGetJwt(noCookieLogout = true) {
  const logout = useLogout();

  return useCallback(() => {
    let jwt;
    if (isAmplifyConfigured()) jwt = getCognitoJwt();
    else if (getWorkspaceJwt()) jwt = getWorkspaceJwt();
    else jwt = getCookie('stytch_session_jwt');
    if (!jwt) jwt = '';

    if (!jwt && noCookieLogout) logout();
    return jwt;
  }, [logout, noCookieLogout]);
}

export const isSSOPath = location.pathname === '/sso';
export const isSSO = isSSOPath || getCognitoJwt();
