import '../../style/dialog-form.css';

import { CheckboxField, DropdownField, TextField } from '../Core';
import { memo, useEffect, useMemo, useState } from 'react';
import { copyStep, getFirstStep, useNewStep } from '../../utils/step';
import { useParams } from 'react-router-dom';
import { Positive } from '../Core/Button';
import { SIZE_UNITS, UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import Label from '../Dialog/Label';
import Dialog from '../Dialog';
import useDialogFocus from '../../utils/useDialogFocus';
import useGetJwt from '../../utils/useGetJwt';
import useFeatheryRedux from '../../redux';
import { PUBLISH_STATUS } from '../../redux/utils';
import { PlusIcon } from '../Icons';
import { useAppSelector } from '../../hooks';
import useElementRefCleanup from '../../utils/useElementRefCleanup';
import { Button } from '../Core/Button/button';
import { cn } from '../../utils/cn';

function StepCreateWithModal({
  createStep,
  buttonStyle,
  buttonClassName
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const getJwt = useGetJwt();
  const initData = { key: '', copy: '', formId };
  const [data, setData] = useState(initData);
  const [formCache, setFormCache] = useState({});
  const [error, setError] = useState('');
  const [steps, setSteps] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Determines if we should copy an existing step's data for this new step
  const [copyFromStep, setCopyFromStep] = useState(false);

  const {
    formBuilder: { setPanelDataWithUndoRedo, setFlowPublishStatus }
  } = useFeatheryRedux();
  const servars = useAppSelector((s) => s.formBuilder.servars);
  const servarUsage = useAppSelector((s) => s.formBuilder.servarUsage);
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields ?? []
  );
  const panels = useAppSelector((state) => Object.values(state.panels.panels));
  const focusElement = useDialogFocus(isOpen);

  const handleNewSteps = (newSteps: any) => {
    setSteps(newSteps);
    setData({ ...data, copy: newSteps[0]?.id });
  };

  // Every time the user changes the form they've selected
  // Fetch the step information so we can pull the ID and key
  useEffect(() => {
    let steps;
    if (data.formId === formId) steps = Object.values(workingSteps);
    // If form data has already been pulled, avoid unnecessary additional requests
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    else if (data.formId in formCache) steps = formCache[data.formId];
    else {
      const token = getJwt();
      FeatheryAPI.getPanelStep(token, { panelId: data.formId }).then(
        async (response: any) => {
          if (response?.status === 200) {
            const steps = await response.json();
            handleNewSteps(steps);
            setFormCache((formCache) => ({
              ...formCache,
              [data.formId]: steps
            }));
          }
        }
      );
    }

    // Only set steps if they've been pulled synchronously
    if (steps) handleNewSteps(steps);
  }, [data.formId, workingSteps]);

  const defaultKey = `Step ${Object.keys(workingSteps).length + 1}`;

  const firstStep: any = useMemo(
    () => getFirstStep(workingSteps),
    [workingSteps]
  );
  const newStep = useNewStep();
  const { cleanupAllElementReferences } = useElementRefCleanup();
  const formatData = () => {
    const { copy, key, formId: copyFormId } = data;

    if (
      Object.values(workingSteps)
        .map((step) => (step as any).key)
        .includes(key)
    )
      return { error: 'Another step already has this name' };

    const formattedKey = key.length < 1 ? defaultKey : key;
    if (!copyFromStep) {
      return {
        data: newStep({
          key: formattedKey,
          width: firstStep?.width ?? SIZE_UNITS.FILL,
          height: firstStep?.height ?? SIZE_UNITS.FILL,
          origin: Object.keys(workingSteps).length === 0
        })
      };
    } else {
      // Copy the existing step but replace all the unique IDs
      const step = steps.find((step) => (step as any).id === copy);
      return {
        data: copyStep(
          step,
          formattedKey,
          formId,
          copyFormId,
          servars,
          hiddenFields,
          panels,
          servarUsage,
          steps,
          cleanupAllElementReferences
        )
      };
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { error, data } = formatData();
    if (error) {
      setError(error);
    } else if (data) {
      setError('');
      setPanelDataWithUndoRedo({
        id: data.id,
        oldValue: {},
        newValue: { [data.id]: data },
        title: UNDO_TITLES.NEW_STEP,
        type: UNDO_TYPES.CONDITION,
        workingSteps: { ...workingSteps, [data.id]: data }
      });
      setFlowPublishStatus(PUBLISH_STATUS.ACTIVE);
      createStep(data);
      setData(initData);
      setIsOpen(false);
    }
  };

  return (
    <>
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} title='Add Step'>
        <form onSubmit={handleSubmit}>
          <Label>Step Name</Label>
          <TextField
            ref={focusElement}
            placeholder={defaultKey}
            value={data.key}
            onComplete={(key: string) => setData({ ...data, key: key.trim() })}
            maxLength={128}
          />
          <div style={{ width: '100%', marginTop: '24px' }}>
            <CheckboxField
              checked={copyFromStep}
              text='Copy an existing step?'
              onChange={(checked) => setCopyFromStep(checked)}
            />
          </div>
          {copyFromStep && (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '12px',
                width: '100%'
              }}
            >
              <div>
                <Label style={{ display: 'inline-block' }}>Form</Label>
                <DropdownField
                  required={copyFromStep}
                  selected={data.formId}
                  options={panels.map((p) => ({
                    value: (p as any).id,
                    display: (p as any).key
                  }))}
                  onChange={(event: any) => {
                    const selected = event.target.value;
                    setData({ ...data, formId: selected });
                  }}
                />
              </div>
              <div>
                <Label style={{ display: 'inline-block' }}>Step</Label>
                <DropdownField
                  required={copyFromStep}
                  selected={data.copy}
                  options={steps.map((step) => ({
                    value: (step as any).id,
                    display: (step as any).key
                  }))}
                  onChange={(event: any) => {
                    const selected = event.target.value;
                    setData({ ...data, copy: selected });
                  }}
                />
              </div>
            </div>
          )}
          <div className='dialog-form-action'>
            <Positive>Create</Positive>
          </div>
        </form>
        {error && <div>{error}</div>}
      </Dialog>
      <Button
        className={cn('!rounded-full', buttonClassName)}
        style={buttonStyle}
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon className='w-5 h-5' color='white' />
        <span>Step</span>
      </Button>
    </>
  );
}

export default memo(StepCreateWithModal);
