import { InlineTooltip, YesNoSwitch } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { FieldSelectorWithModal } from '../../../components/Modals';
import styles from '../styles.module.scss';
import { useAppSelector } from '../../../hooks';

const DataTrackingSection = ({ title, formData, handleValueChange }: any) => {
  const org = useAppSelector((state) => state.accounts.organization);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>Edit Hidden Fields</p>
          <FieldSelectorWithModal
            onlyHiddenFields
            placeholder='Edit'
            disableSelect
            className={styles.noMarginLeft}
            labelOverride='Edit Hidden Fields'
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Submit Conditionally Hidden Fields
            <InlineTooltip
              text='If a form field is conditionally hidden, its value will be saved on submit.'
              inline
            />
          </p>
          <YesNoSwitch
            id='save-hide-if-fields'
            checked={formData.save_hide_if_fields ?? false}
            onCheckedChange={(value) =>
              handleValueChange('save_hide_if_fields')(value)
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Save URL Parameters for Embedded Forms
            <InlineTooltip
              text='If your form has been embedded into a custom site, automatically save its URL parameters to hidden fields. This feature is always on for Feathery-hosted forms.'
              inline
            />
          </p>
          <YesNoSwitch
            id='save-url-params'
            checked={formData.save_url_params ?? false}
            onCheckedChange={(value) =>
              handleValueChange('save_url_params')(value)
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Save User IP Address
            <InlineTooltip
              text="Save your form user's IP address into a hidden field."
              inline
            />
          </p>
          <FieldSelectorWithModal
            onlyHiddenFields
            selectId={formData.ip_hidden_field}
            selectType='hidden'
            placeholder='Select'
            onSelect={(data) =>
              handleValueChange('ip_hidden_field')(data.selectId ?? '')
            }
            className={styles.noMarginLeft}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Track Step with URL Hash
            <InlineTooltip
              text="URL hashes allow your browser to 'remember' your navigation so you can use the browser back and forward buttons."
              inline
            />
          </p>
          <YesNoSwitch
            id='track-hashes'
            checked={formData.track_hashes ?? true}
            onCheckedChange={(value) =>
              handleValueChange('track_hashes')(value)
            }
          />
        </label>
      </div>
      {!org?.whitelabel && (
        <div className='field-group'>
          <label>
            <p className='form-label'>
              Disable Feathery Server Storage
              <InlineTooltip
                text='Prevent submitted form data from routing to Feathery servers. This requires you have client-side logic that routes the data to your own database. Only available on the Business Plan.'
                inline
              />
            </p>
            <YesNoSwitch
              id='track-hashes'
              checked={
                formData.no_save_data && org?.enterprise_features.no_save_data
              }
              onCheckedChange={(value) =>
                handleValueChange('no_save_data')(value)
              }
              disabled={!org?.enterprise_features.no_save_data}
            />
          </label>
        </div>
      )}
    </SettingsContainer>
  );
};

export default DataTrackingSection;
