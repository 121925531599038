import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { DropdownField, TextField } from '../../../../../Core';
import styles from '../../styles.module.scss';
import useFeatheryRedux from '../../../../../../redux';
import { Spinner } from '../../../../../Core/Spinner';

const useSalesforceObjects = (credential: any) => {
  const [objects, setObjects] = useState<
    { label: string; name: string; updateable: boolean; createable: boolean }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fetchSalesforceObjects } = useFeatheryRedux();
  useEffect(() => {
    const fetchObjects = async () => {
      setError(null);
      setLoading(true);
      setObjects([]);
      try {
        const data = await fetchSalesforceObjects({
          salesforce_instance_url: credential.data.userCustomDomainUrl,
          salesforce_access_token: credential.data.accessToken
        });
        if (!data?.sobjects) {
          throw new Error('No sobjects returned');
        }

        const updateable_objects = data.sobjects.filter(
          (obj: any) => obj.createable || obj.updateable
        );
        setObjects(updateable_objects);
        setLoading(false);
      } catch (err: any) {
        console.error(err.message);
        setError('Failed to load Salesforce objects');
        setLoading(false);
      }
    };

    if (credential) {
      fetchObjects();
    }
  }, [credential]);

  return { objects, loading, error };
};

export const SalesforceObjectPicker = ({
  credential,
  onChange,
  selected,
  error: fieldError,
  errorMessage
}: any) => {
  const { objects, loading, error } = useSalesforceObjects(credential);
  const dropdownOptions = useMemo(() => {
    return (
      objects
        // map to dropdown option structure
        .map((obj: any) => ({
          display: obj.label,
          value: obj.name
        }))
        // sort alphabetically by label
        .sort((objectA, objectB) => {
          return objectA.display.localeCompare(objectB.display);
        })
    );
  }, [objects]);

  if (loading) return <Spinner role='status' className='block text-primary' />;
  if (error)
    return <div className='text-[var(--red-border)]'>Error: {error}</div>;

  return (
    <DropdownField
      options={dropdownOptions}
      selected={selected}
      onChange={onChange}
      placeholder='Select Salesforce Object'
      error={fieldError}
      errorMessage={errorMessage}
    />
  );
};

const useSalesforceFields = (credential: any, object_name: any) => {
  const [fields, setFields] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fetchSalesforceObjectFields } = useFeatheryRedux();

  useEffect(() => {
    const fetchFields = async () => {
      setError(null);
      setLoading(true);
      setFields([]);
      try {
        const data = await fetchSalesforceObjectFields({
          salesforce_instance_url: credential.data.userCustomDomainUrl,
          salesforce_access_token: credential.data.accessToken,
          object_name: object_name
        });
        if (!data?.fields) {
          throw new Error('No fields returned');
        }

        const createable_fields = data.fields.filter(
          (obj: any) => obj.createable
        );
        setFields(createable_fields);
        setLoading(false);
      } catch (err: any) {
        console.error(err.message);
        setError('Failed to load Salesforce fields');
        setLoading(false);
      }
    };

    if (credential && object_name) {
      fetchFields();
    }
  }, [credential, object_name]);

  return { fields, loading, error };
};

const isFieldRequired = (field: any) => {
  return !field.nillable && !field.defaultedOnCreate;
};

export const SalesforceFieldList = forwardRef(function MyInput(
  {
    credential,
    object_name,
    value: fieldValues = {},
    onChange,
    error: fieldError,
    errorMessage,
    create = false
  }: any,
  ref
) {
  const { fields, loading, error } = useSalesforceFields(
    credential,
    object_name
  );

  useImperativeHandle(ref, () => {
    return {
      getInvalidFields(field_values: Record<string, string>) {
        if (!fields || !fields.length) {
          return false;
        }
        if (!create) {
          // if updating, dont need to validate that all fields are provided
          return [];
        }
        return fields
          .filter(
            (field) => isFieldRequired(field) && !field_values[field.name]
          )
          .map((field) => field.name);
      }
    };
  });

  const handleChange = (name: string, value: string) => {
    onChange({
      ...fieldValues,
      [name]: value
    });
  };

  if (loading) return <Spinner role='status' className='block text-primary' />;
  if (error)
    return <div className='text-[var(--red-border)]'>Error: {error}</div>;

  return (
    <div>
      {fields.map((field) => (
        <SalesforceField
          key={field.name}
          field={field}
          value={fieldValues[field.name] ?? ''}
          onChange={handleChange}
          error={fieldError && errorMessage[field.name]}
          errorMessage={
            errorMessage && typeof errorMessage === 'object'
              ? errorMessage[field.name]
              : undefined
          }
          showRequired={create}
        />
      ))}
      {errorMessage && typeof errorMessage === 'string' && (
        <div className='text-red-500'>{errorMessage}</div>
      )}
    </div>
  );
});

const SalesforceField = ({
  field,
  value,
  onChange,
  error,
  errorMessage,
  showRequired = false
}: any) => {
  const required = showRequired && isFieldRequired(field);
  const isReference = field.referenceTo.length;
  return (
    <div className={styles.field}>
      <div className={styles.label}>
        {isReference ? `${field.referenceTo[0]} Reference` : field.label}
        {required && <span className='text-red-600'> *</span>}
      </div>
      <TextField
        value={value}
        onChange={(val: string) => {
          onChange(field.name, val);
        }}
        error={error}
        errorMessage={errorMessage}
      />
    </div>
  );
};
