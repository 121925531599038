import { useHistory, useParams } from 'react-router-dom';
import LogicRuleList, { LogicRule } from './LogicRuleList';
import LogicRuleDetail from '../LogicRuleDetail';
import APIConnectorPage from '../APIConnectorPage';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../components/Core/Tabs';
import { useAppSelector } from '../../hooks';
import useFeatheryRedux from '../../redux';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import { objectPick, objectRemove } from '../../utils/core';
import { LogoLoader } from '../../components/Core';

export default function FormLogicPage() {
  const { formId, ruleId, tab } =
    useParams<{ ruleId: string; formId: string; tab: string }>();
  const history = useHistory();

  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );
  const workingLogicRulesInitialized = useAppSelector(
    (s) => s.formBuilder.workingLogicRulesInitialized
  );

  const {
    formBuilder: { setPanelDataWithUndoRedo, filterUndoRedoStacksByType }
  } = useFeatheryRedux();

  const onRuleUpdate = (rule: LogicRule) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: { [rule.id]: rule },
      workingLogicRules: { ...workingLogicRules, [rule.id]: rule },
      title: UNDO_TITLES.LOGIC_RULES,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };

  const onRuleCreate = (newRule: any, redirect = true) => {
    setPanelDataWithUndoRedo({
      id: newRule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: {},
      newRulesValue: { [newRule.id]: newRule },
      workingLogicRules: { ...workingLogicRules, [newRule.id]: newRule },
      title: UNDO_TITLES.NEW_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
    // send them to the rule edit screen
    if (redirect) history.push(`/forms/${formId}/logic/${newRule.id}?edit`);
  };

  const onRuleDelete = (rule: LogicRule, redirect = false) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: {},
      workingLogicRules: objectRemove(workingLogicRules, [rule.id]),
      title: UNDO_TITLES.DELETE_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
    if (redirect) history.push(`/forms/${formId}/logic`);
  };

  const onRuleRuntimeOrderChange = (newRules: any[], ruleId: string) => {
    const newRulesObj = newRules.reduce((acc: any, r: any) => {
      acc[r.id] = r;
      return acc;
    }, {});

    setPanelDataWithUndoRedo({
      id: ruleId,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: { ...workingLogicRules },
      newRulesValue: newRulesObj,
      workingLogicRules: { ...workingLogicRules, ...newRulesObj },
      title: UNDO_TITLES.LOGIC_RULES,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };

  const onRuleSelect = (rule: any) =>
    history.push(`/forms/${formId}/logic/${rule.id}`);

  const onCodeEditorSwitch = () =>
    filterUndoRedoStacksByType(UNDO_TYPES.RULE_BUILDER);

  const isConnectorsTab = ruleId === 'connectors' || tab === 'connectors';

  const setTab = (eventKey: string | null) => {
    if (eventKey === 'connectors') {
      if (ruleId) {
        history.push(`/forms/${formId}/logic/${ruleId}/connectors`);
      } else {
        history.push(`/forms/${formId}/logic/connectors`);
      }
    } else {
      if (ruleId && ruleId !== 'connectors') {
        history.push(`/forms/${formId}/logic/${ruleId}`);
      } else {
        history.push(`/forms/${formId}/logic`);
      }
    }
  };

  let component;
  if (!workingLogicRulesInitialized) component = <LogoLoader />;
  else if (ruleId)
    component = (
      <LogicRuleDetail
        steps={workingSteps}
        rules={workingLogicRules}
        onRuleUpdate={onRuleUpdate}
        onRuleDelete={onRuleDelete}
        onBack={() => history.push(`/forms/${formId}/logic`)}
        onCodeEditorSwitch={onCodeEditorSwitch}
      />
    );
  else {
    component = (
      <LogicRuleList
        onRuleUpdate={onRuleUpdate}
        onRuleDelete={onRuleDelete}
        onRuleRuntimeOrderChange={onRuleRuntimeOrderChange}
        onRuleCreate={onRuleCreate}
        onRuleSelect={onRuleSelect}
        steps={workingSteps}
        rules={workingLogicRules}
      />
    );
  }

  return (
    <div
      style={{
        overflowY: 'scroll',
        height: 'calc(100% - 55px)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Tabs
        value={isConnectorsTab ? 'connectors' : 'rules'}
        onValueChange={setTab}
        className='relative flex-1 flex flex-col p-[16px]'
      >
        <TabsList unstyled className='legacyRulesTabsList'>
          <TabsTrigger
            value='rules'
            unstyled
            className='legacyRulesTabsTrigger'
          >
            Rules
          </TabsTrigger>
          <TabsTrigger
            value='connectors'
            unstyled
            className='legacyRulesTabsTrigger'
          >
            API Connectors
          </TabsTrigger>
        </TabsList>
        <TabsContent
          value='rules'
          unstyled
          className='legacyRulesTabsContent flex flex-col'
        >
          {component}
        </TabsContent>
        <TabsContent
          value='connectors'
          unstyled
          className='legacyRulesTabsContent'
        >
          <APIConnectorPage formId={formId} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
